const BED_TYPE = {
  SINGLE: 1,
  DOUBLE: 2,
};

const CLUB_RATE_STORAGE_VIEWED = 'MIRAI:RATES:CLUB_RATE:viewed';

const DATE_FORMAT = { weekday: 'short', month: 'short', day: 'numeric' };

const DISCOUNT_TYPE = {
  CLUB: 0,
  DEAL: 1,
  PROMOTION: 2,
};

const FEATURES = {
  COLUMN_MODE: 'columnMode',
  HIDE_RADIO: 'hideRadio',
  HIDE_STANDALONE_BOARD: 'hideStandaloneBoard',
  HIGHLIGHT_STANDALONE_OPTION: 'highlightStandaloneOption',
  PESTANA: 'pestana',
  ROW_MODE: 'rowMode',
  SHOW_ALL_BOARDS: 'showAllBoards',
  SHOW_ALL_OFFERS: 'showAllOffers',
  SHOW_MAX_OCCUPATION: 'showMaxOccupation',
  SHOW_NAME_IN_HEADER: 'showNameInHeader',
  SHOW_OFFERS_FIRST: 'showOffersFirst',
  SHOW_PREVIEW_PRICE: 'showPreviewPrice',
  SHOW_PRICE_PER_NIGHT: 'showPricePerNight',
  SHOW_RATE_INCLUDED: 'showRateIncluded',
  TWIN: 'twin',
};

const FREE_CANCELLATION = 'free';

const NOTIFICATION = {
  PROMOCODE_NOT_FOUND: 1,
  OFFER_NOT_FOUND: 2,
  ROOM_NOT_FOUNT: 3,
};

const UTM_SOURCE_NAME = {
  hotelfinder: 'Google Hotels',
};

export {
  BED_TYPE,
  CLUB_RATE_STORAGE_VIEWED,
  DATE_FORMAT,
  DISCOUNT_TYPE,
  FEATURES,
  FREE_CANCELLATION,
  NOTIFICATION,
  UTM_SOURCE_NAME,
};
