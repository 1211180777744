.container {
  backdrop-filter: blur(8px);
  background-color: var(--mirai-chat-base-opacity);
  bottom: 0;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  transition-delay: var(--mirai-ui-motion-expand);
  width: 100%;
  z-index: 0;
}

.container.visible {
  opacity: 1;
  pointer-events: all;
  transition-delay: var(--mirai-ui-motion-expand);
}

.content {
  transform: translateY(100%);
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  transition-delay: 0;
}

.content.visible {
  transform: translateY(0%);
  transition-delay: var(--mirai-ui-motion-expand);
}

.content .header {
  gap: calc(var(--mirai-chat-space) / 2);
  padding: var(--mirai-chat-space);
}

.content .component {
  width: 100%;
}

.content .component.inputText {
  margin: 0 var(--mirai-chat-space);
  width: auto;
}

.content .footer {
  padding: var(--mirai-chat-space);
}

/* -- InputRich.List.jsx */
.list {
  flex: 1;
  max-height: 75svh;
}

.list .item {
  padding: var(--mirai-ui-space-XS) var(--mirai-chat-space);
  width: 100%;
  min-height: var(--mirai-ui-space-L);
}

.list .item.selected,
.list .item:hover {
  background-color: var(--mirai-chat-accent);
}

.list .item.selected *,
.list .item:hover * {
  color: var(--mirai-chat-text-accent-color);
}

/* S */
@media only screen and (max-width: 480px) {
}

/* M & L */
@media only screen and (min-width: 481px) {
}
