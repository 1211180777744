import { dateDiff, dateFormat, DEFAULT_CURRENCY, DEFAULT_LOCALE, parseDate } from '@mirai/locale';

import { TYPE } from '../../../Core.constants';
import { parseToParties, toUrlParams } from '../../helpers';

export const getForwarderUrl = ({
  calendar: [checkIn, checkOut] = [],
  occupation = [],
  place: { id: ids = [], isHotel, value } = {},
  promocode: clientCode = '',
  locale = DEFAULT_LOCALE,
  currency = DEFAULT_CURRENCY,
  forwarder: { rates: url } = {},
  store: {
    hotel: { id } = {},
    id: idchain,
    origin: { commissionCode: hsri = '' } = {},
    urlParams: { miraiId, uid } = {},
  } = {},
  type,
} = {}) => {
  const dateCheckIn = checkIn instanceof Date ? checkIn : parseDate(checkIn);
  const dateCheckOut = checkOut instanceof Date ? checkOut : parseDate(checkOut);
  const hotelId = ids.length === 1 ? ids[0] : id;
  const params = {
    chainSelectorValue: value,
    checkin: dateFormat(dateCheckIn, { format: 'DD/MM/YYYY' }),
    ...(value && !isHotel ? { hotelIds: ids.join(',') } : { idtokenprovider: hotelId }),
    ...(type === TYPE.CHAIN ? { idchain } : undefined),
    clientCode,
    currency,
    hsri,
    lang: locale.split('-')[0],
    miraiId,
    nights: dateDiff(dateCheckIn, dateCheckOut).days,
    parties: parseToParties(occupation),
    type,
    uid,
    // ? TODO: Is this mandatory?
    // clientCodeStrictSearch: true
    // deviceType: "DESKTOP_TABLET"
    // env: "mario"
    // home: "https://www.hotel-moderno.com/"
  };

  return `${url}${toUrlParams(params)}`;
};
