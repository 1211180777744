import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Input, Notification, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { formatValues, getStyle } from './helpers';
import { CARDS, ERROR, PLACEHOLDER_CARD, PLACEHOLDER_CVV, SCRIPT_ID } from './PCI.constants';
import { L10N } from './PCI.l10n';
import * as style from './PCI.module.css';
import { EVENT } from '../../../ButtonPayment/ButtonPayment.constants';
import { InputField } from '../../../ButtonPayment/components/InputField';
import { testExpire } from '../Card/helpers';

let secureFields;

const PCI = ({
  cards = [],
  publicKey = process.env.METHOD_PCIPROXY_PUBLICKEY,
  showCVV = true,
  showErrors = false,
  onChange = () => {},
  onError = () => {},
  ...others
}) => {
  const { translate } = useLocale();
  const { value: { payment = {} } = {}, value, set } = useStore();

  const [focus, setFocus] = useState();
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({
    card: {},
    name: {},
    expire: {},
    ...(showCVV ? { cvv: {} } : undefined),
  });
  const [ready, setReady] = useState(false);
  const [responseError, setResponseError] = useState(false);

  useEffect(() => {
    const exists = document.getElementById(SCRIPT_ID);
    if (!exists) {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src = process.env.METHOD_PCIPROXY_SCRIPT;
      script.onload = handleLoad;

      document.head.appendChild(script);
    }

    return () => {
      const el = document.getElementById(SCRIPT_ID);
      el.parentNode.removeChild(el);
      secureFields?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ready) return;

    onError(formError);
    // eslint-disable-next-line no-undef
    secureFields = new SecureFields();
    secureFields.initTokenize(
      publicKey,
      { cardNumber: 'card', ...(showCVV ? { cvv: 'cvv' } : undefined) },
      {
        ...getStyle(),
        paymentMethods: Object.entries(CARDS)
          .filter(([, value] = []) => cards.includes(value))
          .map(([key] = []) => key),
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey, ready]);

  useEffect(() => {
    secureFields?.on('change', handleChangeSecure);
    secureFields?.on('error', handleTokenError);
    secureFields?.on('ready', handleReady);
    secureFields?.on('success', handleSuccess);

    document.addEventListener(EVENT.CTA_PRESS, handleButtonPress);

    if (Object.keys(formError).length === 0) onError({});

    return () => document.removeEventListener(EVENT.CTA_PRESS, handleButtonPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, form, formError, ready, responseError, value]);

  useEffect(() => {
    onChange({ expire: form.expire, name: form.name, type: form.type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    onError(Object.fromEntries(Object.entries(formError).filter(([, value]) => value !== undefined)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError, responseError]);

  const handleButtonPress = () => {
    if (Object.entries(formError).filter(([, value]) => value !== undefined).length > 0) return;

    setResponseError();
    const [month, year] = form.expire.split('/');
    secureFields.submit({ expm: month, expy: year });
  };

  const handleChange = (field, value = '') => {
    setResponseError();

    const { expire, name } = formatValues({ ...{ expire: form.expire, name: form.name }, [field]: value }, form);
    setForm({ ...form, expire, name });
  };

  const handleChangeSecure = ({ fields: { cardNumber = {}, cvv = {} } = {}, event: { type, field } = {} }) => {
    const fieldName = field === 'cardNumber' ? 'card' : field;
    if (type === 'focus') return handleEnter(fieldName);
    if (type === 'blur') return handleLeave(fieldName);

    const isKeyUp = type === 'keyUp';
    const isCardEmpty = fieldName === 'cardNumber' && cardNumber.length === 0 && !form.card;
    const isCvvEmpty = fieldName === 'cvv' && cvv.length === 0 && !form.cvv;

    if (!isKeyUp || (isKeyUp && (isCardEmpty || isCvvEmpty))) return;

    setResponseError();

    const error = { ...formError };
    if (fieldName === 'card') {
      setForm({ ...form, card: cardNumber, type: cardNumber.valid ? CARDS[cardNumber.paymentMethod] : undefined });
      error.card =
        !cardNumber.valid && cardNumber.length > 0
          ? { format: true }
          : cardNumber.length === 0
          ? { required: true }
          : undefined;
    } else if (fieldName === 'cvv') {
      setForm({ ...form, cvv });
      error.cvv = !cvv.valid && cvv.length > 0 ? { format: true } : cvv.length === 0 ? { required: true } : undefined;
    }

    setFormError(error);
  };

  const handleEnter = (field) => setFocus((focus) => ({ ...focus, [field]: true }));

  const handleLeave = (field) => setFocus((focus) => ({ ...focus, [field]: false }));

  const handleError = (field, { required, test } = {}) => {
    if (!ready) return;

    setFormError((formError) => ({
      ...formError,
      ...{ [field]: required ? { required: true } : test ? { format: true } : undefined },
    }));
  };

  const handleLoad = () => {
    setReady(true);
  };

  const handleReady = () => {
    secureFields.setPlaceholder('cardNumber', PLACEHOLDER_CARD);
    showCVV && secureFields.setPlaceholder('cvv', PLACEHOLDER_CVV);
  };

  const handleSuccess = ({ transactionId }) => {
    set({ payment: { ...payment, transactionId } });
  };

  const handleTokenError = () => {
    setResponseError(ERROR);
    set({ payment: { ...payment, error: ERROR } });
  };

  const { testId } = others;

  return (
    <View {...others}>
      <Text action>{translate(L10N.LABEL_SECURE_PAGE)}</Text>

      <InputField
        id="checkout-card"
        error={(showErrors && (!form.card || form.card?.length === 0)) || Object.keys(formError.card || {}).length > 0}
        focus={focus?.card}
        label={translate(L10N.LABEL_CARD_NUMBER)}
        showRequired
        success={!formError.card}
        value={form.card}
        testId={testId ? `${testId}-PCI-card` : undefined}
      >
        <div id="card" className={style.input} />
      </InputField>
      <InputField
        error={(showErrors && (!form.name || form.name?.length === 0)) || Object.keys(formError.name || {}).length > 0}
        focus={focus?.name}
        label={translate(L10N.LABEL_CARD_NAME)}
        showRequired
        success={form.name?.length > 0 && !formError.name}
        value={form.name}
        testId={testId ? `${testId}-PCI-name` : undefined}
      >
        <Input
          id="checkout-name"
          name="name"
          required
          value={form.name}
          onChange={(value) => handleChange('name', value)}
          onEnter={() => handleEnter('name')}
          onError={(error) => handleError('name', error)}
          onLeave={() => handleLeave('name')}
        />
      </InputField>
      <View row>
        <InputField
          error={
            (showErrors && (!form.expire || form.expire?.length === 0)) ||
            Object.keys(formError.expire || {}).length > 0
          }
          focus={focus?.expire}
          label={translate(L10N.LABEL_EXPIRATION)}
          showRequired
          success={form.expire?.length > 0 && !formError.expire}
          value={form.expire}
          className={style.expire}
          testId={testId ? `${testId}-PCI-expire` : undefined}
        >
          <Input
            id="checkout-expire"
            maxLength={5}
            name="expire"
            placeholder={`${translate(L10N.LABEL_MONTH_FORMAT)}/${translate(L10N.LABEL_YEAR_FORMAT)}`}
            required
            test={(expire) => expire.length === 0 || (expire?.length > 0 && testExpire(expire))}
            value={form.expire}
            onChange={(value) => handleChange('expire', value)}
            onEnter={() => handleEnter('expire')}
            onError={(error) => handleError('expire', error)}
            onLeave={() => handleLeave('expire')}
          />
        </InputField>
        {showCVV && (
          <InputField
            id="checkout-cvv"
            error={(showErrors && (!form.cvv || form.cvv?.length === 0)) || Object.keys(formError.cvv || {}).length > 0}
            focus={focus?.cvv}
            label={translate(L10N.LABEL_CVV)}
            showRequired
            success={!formError.cvv}
            value={form.cvv}
            className={style.cvv}
            testId={testId ? `${testId}-PCI-CVV` : undefined}
          >
            <div id="cvv" className={style.input} />
          </InputField>
        )}
      </View>

      {responseError && (
        <Notification error small wide>
          {translate(L10N.NOTIFICATION_ERROR_PCI)}
        </Notification>
      )}
    </View>
  );
};

PCI.displayName = 'Mirai:Payments:PCI';

PCI.propTypes = {
  cards: PropTypes.array,
  publicKey: PropTypes.string,
  showCVV: PropTypes.bool,
  showErrors: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export { PCI };
