import { request } from '@mirai/data-sources';

const MOCK_AUTHORIZATION = 'dXNlcjE6dXNlcjFQYXNz';

// ! TODO: Should use a new service
export const info = async (id) => {
  if (!id || typeof id !== 'string') return;

  const response = await request({
    hostname: 'https://api.mirai.com/MiraiWebService',
    endpoint: `/hotelInfo/${id}`,
    headers: { authorization: `Basic ${MOCK_AUTHORIZATION}` },
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.error('ServiceHotel.info', error);
  });
  if (!response) return;

  const {
    checkIn = '14:00',
    checkOut = '11:30',
    email = reservationEmail,
    hotelName: name,
    hotelType: type,
    hotelAddress: address,
    hotelLocation: location,
    hotelPostalCode: postalCode,
    hotelProvince: province,
    hotelCountry: country,
    hotelLat: latitude,
    hotelLong: longitude,
    phone,
    reservationEmail,
    url,
    urlConditions = 'https://mirai.com',
    urlPrivacyPolicy = 'https://mirai.com',
  } = response;

  return {
    address: { address, location, postalCode, province, country },
    checkIn,
    checkOut,
    email,
    location: { latitude, longitude },
    name,
    phone,
    type,
    url,
    urlConditions,
    urlPrivacyPolicy,
  };
};
