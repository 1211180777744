import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceRates } from '@mirai/services';
import { useDevice } from '@mirai/ui';
import { useEffect, useRef, useState } from 'react';

import { debounce, fetchUrlParams } from '../../../helpers';
import { EVENT, parseToParties, replaceUrlParams } from '../../helpers';
import { getBoard, getFinderParams, signout } from '../helpers';
import { NOTIFICATION, UTM_SOURCE_NAME } from '../Rates.constants';
import { L10N } from '../Rates.l10n';

let fetchCall = 0;

export const useRates = () => {
  const { isMobile, mobile } = useDevice();
  const { translate } = useLocale();
  const {
    set,
    value: {
      club = {},
      currency,
      finder = {},
      hotel = {},
      id,
      language,
      locale,
      rates: { cart: storeCart = [], meta } = {},
      occupation: { maxRooms } = {},
      urlParams,
      session,
      skeleton = false,
    },
  } = useStore();
  const refRoomSelector = useRef();

  const [cart, setCart] = useState([]);
  const [clubDiscount, setClubDiscount] = useState(club.discount);
  const [clubRate, setClubRate] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [fetching, setFetching] = useState(false);
  const [metaBoard, setMetaBoard] = useState();
  const [responseError, setResponseError] = useState();
  const [room, setRoom] = useState();
  const [strictSearch, setStrictSearch] = useState();

  const { calendar = [], occupation = [], place } = finder;
  const device = mobile ? 'MOBILE' : 'DESKTOP_TABLET';
  const multiRoom = occupation?.length > 1;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => skeleton && setFetching(true), [skeleton]);

  useEffect(() => {
    if (multiRoom) {
      if (room > 0) {
        const { current: { offsetHeight = 0, offsetTop = 0 } = {} } = refRoomSelector || {};
        window.scrollTo({ top: offsetTop + (isMobile ? offsetHeight : 0), behavior: 'smooth' });
      }
      setDataSource();
    }

    room !== undefined && debounceFetch({ nextRoom: room });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    const callback = async (next) => {
      setClubDiscount(typeof next !== 'object' ? next : undefined);
      clubRate && setClubRate(false);
    };
    Event.subscribe(EVENT.RATES_FILTER_CLUB, callback);

    return () => Event.unsubscribe(EVENT.RATES_FILTER_CLUB, callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRate]);

  useEffect(() => {
    if ((!dataSource && (!responseError || !responseError.tracking)) || room) return;

    Event.publish(EVENT.RATES_RESPONSE, {
      error: !!responseError,
      event: EVENT.RATES_RESPONSE,
      response: dataSource || responseError,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, responseError]);

  useEffect(() => {
    if (!dataSource || !storeCart.length) return;

    getBoard({ cart, dataSource, storeCart })
      .then((board) => {
        if (!board) return Event.publish(EVENT.METRICS, { id: 'RATES:META:ERROR' });

        if (meta) {
          Event.publish(EVENT.NOTIFICATION, {
            contrast: true,
            defaultMessage: translate(L10N.NOTIFICATION_META_RATE_FOUND, {
              utm_source: UTM_SOURCE_NAME[urlParams.utm_source] || urlParams.utm_source,
            }),
          });
          Event.publish(EVENT.METRICS, { id: 'RATES:META:SUCCESS' });
        }

        setMetaBoard(board);
        set({ rates: undefined });
      })
      .catch(() => set({ rates: undefined }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, storeCart]);

  useEffect(() => {
    if (!clubDiscount && !currency && !session && strictSearch === undefined) return;

    !clubRate && setCart([]);
    setRoom(undefined);
    debounceFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubDiscount, currency, session, strictSearch]);

  useEffect(() => {
    if (!locale) return;

    debounceFetch({ nextRoom: room });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const checkNotifications = (nextDataSource, nextPromocode) => {
    const { notifications = [] } = nextDataSource;

    if (notifications.includes(NOTIFICATION.PROMOCODE_NOT_FOUND) && nextPromocode) {
      Event.publish(EVENT.NOTIFICATION, {
        contrast: true,
        defaultMessage: translate(L10N.NOTIFICATION_PROMOCODE_NOT_FOUND, { promocode: nextPromocode }),
      });
      set({ finder: { calendar, occupation, place, promocode: '' } });
      Event.publish(EVENT.RATES_FILTER_PROMOCODE, { promocode: undefined });
    } else if (nextPromocode !== finder.promocode) {
      Event.publish(EVENT.RATES_FILTER_PROMOCODE, { promocode: nextPromocode });
    }
    if (notifications.includes(NOTIFICATION.ROOM_NOT_FOUNT) && urlParams.roomTypeId) {
      Event.publish(EVENT.NOTIFICATION, {
        contrast: true,
        defaultMessage: translate(L10N.NOTIFICATION_ROOM_NOT_FOUND),
      });
    }
    if (notifications.includes(NOTIFICATION.OFFER_NOT_FOUND) && urlParams.idoffers) {
      Event.publish(EVENT.NOTIFICATION, {
        contrast: true,
        defaultMessage: translate(L10N.NOTIFICATION_OFFER_NOT_FOUND),
      });
    }
    const { notification } = fetchUrlParams(location.search) || {};
    const validateError = notification && L10N.NOTIFICATION_VALIDATE[notification];
    if (validateError) {
      replaceUrlParams({ notification: undefined });
      Event.publish(EVENT.NOTIFICATION, { warning: true, defaultMessage: translate(validateError) });
    }
  };

  const fetch = async ({
    nextRoom,
    nextOccupation = occupation,
    nextPlace = place,
    nextPromocode = finder.promocode,
  } = {}) => {
    if (skeleton) return;

    const { id: [currentId] = [] } = place || {};
    const { id: [nextId] = [] } = nextPlace || {};
    let nextClubDiscount;
    if (nextPlace?.isHotel && currentId !== nextId) {
      const { variants = [] } = club;
      const currentVariant = variants.find(({ id = '' } = {}) => id.toString() === nextId.toString());
      nextClubDiscount = currentVariant?.discount;
      if (currentVariant && nextClubDiscount !== clubDiscount) {
        Event.publish(EVENT.RATES_FILTER_CLUB, nextClubDiscount);

        return;
      }
    }

    Event.publish(EVENT.FINDER, { calendar: undefined });

    setFetching(true);
    setResponseError();

    let urlParams = fetchUrlParams(location.search);
    if (!urlParams.checkin || !urlParams.nights || !urlParams.parties) {
      urlParams = { ...urlParams, ...getFinderParams({ ...finder, hotel }) };
      replaceUrlParams(urlParams);
    }

    const params = {
      currency,
      ...urlParams,
      ...(club.active ? { club, clubDiscount } : undefined),
      id,
      locale,
      maxRooms,
      device,
      occupation: nextOccupation,
      place,
      selectedIds: cart.map(({ roomId } = {}) => roomId).join(','),
      session,
      strictSearch,
    };
    if (nextRoom !== undefined) params.parties = parseToParties([nextOccupation[nextRoom || 0]]);

    const lastFetchCall = ++fetchCall;
    const nextDataSource = await ServiceRates.get(params).catch(setResponseError);
    if (lastFetchCall !== fetchCall) return;

    if (nextDataSource?.externalSignout) return signout({ club, language });

    if (nextDataSource) {
      checkNotifications(nextDataSource, nextPromocode);

      if (nextOccupation?.length > 1 && nextRoom === undefined) setRoom(0);
    }

    setDataSource(nextDataSource);
    setFetching(false);

    if (nextDataSource?.hotels) Event.publish(EVENT.METRICS, { id: 'HOTELS:RENDER' });
  };

  const debounceFetch = debounce(fetch, 60);

  return {
    actions: { setCart, setClubRate, setDataSource, setMetaBoard, setRoom, setStrictSearch },
    device,
    multiRoom,
    fetch: debounceFetch,
    refRoomSelector,
    responseError,
    state: { cart, clubDiscount, clubRate, dataSource, fetching, metaBoard, room, strictSearch },
  };
};
