// import { Event } from '@mirai/data-sources';
const Event = {
  publish: () => {},
  subscribe: () => {},
};
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { Button } from '../../components';
import { IS_JEST, styles } from '../../helpers';
import { useDevice } from '../../hooks';
import { Icon, ICON, Pressable, Text, View } from '../../primitives';
import { DIRECTION_TYPE, Theme } from '../../theme';
import { EVENT_VISIBLE, UNLOAD_DELAY } from './Modal.constants';
import style from './Modal.module.css';

const Modal = ({
  blur = false,
  children,
  displayName,
  fit = false,
  mobileBehavior = false,
  overflow = true,
  portal = false,
  preventDefault = true,
  title,
  visible,
  onBack,
  onClose,
  onOverflow,
  aria: { buttonBack: ariaBack = 'Back', buttonClose: ariaClose = 'Close' } = {},
  ...others
}) => {
  const { isDesktop, isMobile, height } = useDevice();
  const ref = useRef();

  const [dataset, setDataset] = useState();
  const [instance, setInstance] = useState(IS_JEST);

  useEffect(() => {
    if (IS_JEST) return;

    !instance && visible
      ? setInstance(visible)
      : instance && !visible
      ? setTimeout(() => setInstance(visible), UNLOAD_DELAY)
      : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!instance, visible]);

  useEffect(() => {
    if (!visible || !onClose) return;
    Event.publish(EVENT_VISIBLE, { visible, displayName });

    const handleKeydown = (event) => event.key === 'Escape' && handleClose();

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visible && !dataset) {
      setDataset(true);
      document.body.dataset.miraiModal = true;
    }

    return () => {
      if (visible && dataset) {
        setDataset(undefined);
        delete document.body.dataset.miraiModal;
      }
    };
  }, [dataset, visible]);

  const handleOverflow = (event) => {
    const { target } = event;

    if (onOverflow && !ref.current?.contains(target)) {
      Event.publish(EVENT_VISIBLE, { visible: false, displayName });
      onOverflow(event);
    }
  };

  const handleClose = (event) => {
    Event.publish(EVENT_VISIBLE, { visible: false, displayName });
    onClose(event);
  };

  const defaultDirection = Theme.getDirection() === DIRECTION_TYPE.LEFT;
  const renderer = IS_JEST || !portal ? (jsx) => jsx : ReactDOM.createPortal;
  const { testId } = others;

  return renderer(
    <Pressable
      preventDefault={preventDefault}
      tag="modal-overflow"
      onPress={visible && overflow ? handleOverflow : undefined}
      className={styles(
        style.container,
        isDesktop && mobileBehavior && style.mobileBehavior,
        overflow && style.overflow,
        overflow && blur && style.blur,
        visible && instance && style.visible,
        onOverflow && style.onOverflow,
      )}
      style={{ height }}
      testId={testId && overflow ? `${testId}-overflow` : undefined}
    >
      <View
        {...others}
        fit
        ref={ref}
        tag="modal"
        className={styles(style.modal, !fit && style.calcWidth, visible && style.visible, others.className)}
        aria-hidden={visible ? 'false' : 'true'}
      >
        {(title || onBack || onClose) && (
          <View
            row
            className={styles(
              style.header,
              !defaultDirection && style.reverse,
              !onBack && style.hideBack,
              !onClose && style.hideClose,
            )}
          >
            {onBack && (
              <Button
                small
                squared
                transparent
                onPress={onBack}
                aria-label={ariaBack}
                testId={testId ? `${testId}-button-back` : undefined}
              >
                <Icon value={ICON.LEFT} className={style.icon} />
              </Button>
            )}
            {title && (
              <Text headline={!isMobile} className={style.title}>
                {title}
              </Text>
            )}
            {onClose && (
              <Button
                small
                squared
                transparent
                onPress={handleClose}
                aria-label={ariaClose}
                testId={testId ? `${testId}-button-close` : undefined}
              >
                <Icon value={ICON.CLOSE} className={style.icon} />
              </Button>
            )}
          </View>
        )}

        {instance &&
          React.Children.map(children, (child, index) =>
            !child || child === null
              ? null
              : React.cloneElement(child, { key: child.key || index, ...child.props, role: 'modal-content' }),
          )}
      </View>
    </Pressable>,
    document.body,
  );
};

Modal.displayName = 'Component:Modal';

Modal.propTypes = {
  blur: PropTypes.bool,
  children: PropTypes.node,
  displayName: PropTypes.string,
  fit: PropTypes.bool,
  mobileBehavior: PropTypes.bool,
  overflow: PropTypes.bool,
  portal: PropTypes.bool,
  preventDefault: PropTypes.bool,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onOverflow: PropTypes.func,
  aria: PropTypes.shape({
    buttonBack: PropTypes.string,
    buttonClose: PropTypes.string,
  }),
};

export { Modal };
