import { parseCustomData, sendData } from './helpers';
import { getFirstOccupationOption } from '../helpers';
import { formatPrice } from '../helpers/legacy';

export const ratesAddToCart = async ({
  instances = [],
  response: { board: { id, rateId, roomId } = {}, tracking = {} } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { country, hotelInfo: { currency: hotelCurrency = {}, propertyName } = {}, rates = [] } = tracking;

  const { totalPrice } = getFirstOccupationOption({ rates, roomId })?.rates[rateId]?.boardOptions[id] || {};
  const contents = [{ id, itemPrice: formatPrice(totalPrice, hotelCurrency), quantity: 1 }];

  const commonData = {
    customData: parseCustomData({
      contents,
      country,
      hotelCurrency,
      numItems: 1,
      propertyName,
    }),
    eventName: 'AddToCart',
  };

  const responses = await Promise.all(
    instances.map(({ id, pixelId }) => sendData({ ...commonData, idTracking: id, pixelId })),
  ).catch(() => {});

  return responses;
};
