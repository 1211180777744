const DELAY_RESPONSE = 500;

const EVENT = {
  INPUT_COMPONENT: 'EVENT_INPUT_COMPONENT',
};

const INTENT = {
  BOOK: 'BOOK',
  BOOKING_CANCEL: 'BOOKING_CANCEL',
  BOOKING_QUERY: 'BOOKING_QUERY',
  BOOKING_VIEW: 'BOOKING_VIEW',
  BOOKING_SEND: 'BOOKING_SEND',
  OFFERS: 'OFFERS',
  HOTEL_INFO: 'HOTEL_INFO',
  GREETINGS: 'GREETINGS',
  FAREWELLS: 'FAREWELLS',
};

const NAME = 'Lisa';

const VERBOSE_DATE_FORMAT = { weekday: 'short', month: 'short', day: 'numeric' };

export { DELAY_RESPONSE, EVENT, INTENT, NAME, VERBOSE_DATE_FORMAT };
