import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceBooking } from '@mirai/services';
import { Action, Notification, Progress, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Checkout.l10n';
import * as style from './Checkout.module.css';
import Skeleton from './Checkout.skeleton';
import { getParamsFromExtras } from './helpers';
import { Confirmation, Extras, Form } from './partials';
import { ERROR } from '../../Core.constants';
import { fetchUrlParams } from '../../helpers';
import { BookingDetails, BookingSummary, BookingTerms, Footer, NotificationRequiredFields } from '../__shared__';
import { ModalAgency } from '../Booking/partials/Booking.ModalAgency';
import { Header } from '../Header';
import { EVENT, toUrlParams } from '../helpers';

const Checkout = ({ skeleton, ...others }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      checkout,
      currency,
      hotel,
      language,
      locale,
      session,
      urlParams: { posError: tpvError, checkoutStep = '1', ...urlParams } = {},
      variant,
    },
  } = useStore();

  const [busy, setBusy] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [error, setError] = useState();
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [requiredFields, setRequiredFields] = useState();
  const [modalAgency, setModalAgency] = useState(false);
  const [responseError, setResponseError] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => Event.publish(EVENT.METRICS, { id: 'CHECKOUT:RENDER' }), []);

  useEffect(() => {
    if (skeleton) return;

    (async () => {
      setBusy(true);
      const response = await ServiceBooking.checkout({
        currency,
        hotel,
        language,
        locale,
        session,
        ...urlParams,
        ...getParamsFromExtras({ selectedExtras, urlParams, variant }),
      }).catch((error) => {
        setResponseError(error);
      });
      setBusy(false);

      setDataSource(response);
      if (response) Event.publish(EVENT.CHECKOUT_RESPONSE, { event: EVENT.CHECKOUT_RESPONSE, response });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, session, selectedExtras]);

  const handleModalAgency = () => {
    setModalAgency(true);
  };

  const handleErrorConfirmation = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setRequiredFields(error);
  };

  const handleSubmit = () => {
    const { origin, pathname, search } = document.location;

    document.location.href = `${origin}${pathname}${toUrlParams({
      ...fetchUrlParams(search),
      ...getParamsFromExtras({ selectedExtras, urlParams, variant }),
      // ! TODO
      checkoutForm: JSON.stringify(checkout),
      checkoutStep: 2,
    })}`;
  };

  const { agency = {}, payment } = dataSource || {};
  const response = dataSource !== undefined;
  const hasRequiredFields = Object.keys(requiredFields || {}).length > 0;

  return (
    <>
      <Header />

      <View role="checkout" tag="checkout" testId={others.testId} className={styles(style.container, others.className)}>
        <Progress indeterminate visible={busy || (!response && !responseError)} className={style.progress} />
        {response ? (
          <View row={isDesktop} className={style.content}>
            <View wide className={style.main}>
              {hasRequiredFields && (
                <NotificationRequiredFields form="checkout" schema={dataSource?.form} values={requiredFields} />
              )}
              {(agency.name || !payment || tpvError) && (
                <Notification error={tpvError} small info wide className={styles(style.agency, others.className)}>
                  {!tpvError && !payment && `${translate(L10N.NOTIFICATION_NO_CARD)} `}
                  {!tpvError &&
                    agency.name &&
                    translate(L10N.NOTIFICATION_AGENCY, {
                      agency: agency.name,
                      link: (
                        <>
                          <Action underline small onPress={handleModalAgency} className={styles(style.action)}>
                            {translate(L10N.ACTION_MORE_INFO)}
                          </Action>
                        </>
                      ),
                    })}
                  {tpvError && translate(L10N.NOTIFICATION_UNKNOWN_POS_ERROR)}
                </Notification>
              )}

              {!isDesktop && (
                <BookingSummary box dataSource={dataSource}>
                  {(variant === 'A' || checkoutStep === '1') && <BookingDetails dataSource={dataSource} expanded />}
                </BookingSummary>
              )}

              <Form dataSource={dataSource} showErrors={hasRequiredFields} onError={setError} />

              {variant === 'B' && checkoutStep === '1' && (
                <Extras hotel={dataSource.hotel} onChange={setSelectedExtras} />
              )}

              {isDesktop && (variant === 'A' || checkoutStep === '1') && (
                <BookingDetails dataSource={dataSource} className={style.box} />
              )}

              <BookingTerms dataSource={dataSource} small />
            </View>

            <Confirmation
              busy={busy}
              dataSource={dataSource}
              error={Object.keys(error || {}).length > 0}
              onError={handleErrorConfirmation}
              onSubmit={handleSubmit}
              onValid={() => setRequiredFields()}
            />
          </View>
        ) : responseError ? (
          <Notification error large>
            {translate(L10N.NOTIFICATION_ERROR[responseError?.code || ERROR.UNKNOWN])}
          </Notification>
        ) : (
          <Skeleton />
        )}
      </View>
      {agency.name && <ModalAgency {...agency} visible={modalAgency} onClose={() => setModalAgency(false)} />}

      <Footer />
    </>
  );
};

Checkout.displayName = 'Mirai:Core:Checkout';

Checkout.propTypes = {
  skeleton: PropTypes.bool,
};

export { Checkout };
