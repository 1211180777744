import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, Pressable, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ENDPOINT } from './Session.constants';
import { L10N } from './Session.l10n';
import { Modal } from './Session.Login.Modal';
import * as style from './Session.module.css';
import { AUTH_TYPE } from '../../Core.constants';
import { EVENT, ICON, template, toUrlParams } from '../helpers';

const Login = ({ ghost, skeleton, ...others }) => {
  const { translate } = useLocale();
  const {
    value: { club = {}, id, language },
  } = useStore();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    const callback = ({ visible = true } = {}) => setModal(visible);

    Event.subscribe(EVENT.LOGIN, callback);
    return () => Event.unsubscribe(EVENT.LOGIN, callback);
  }, []);

  const handlePress = () => {
    const { authentication: { type = AUTH_TYPE.MIRAI } = {} } = club;
    if (type === AUTH_TYPE.MIRAI) return setModal(true);

    const url = `${process.env.SERVICE_USER}/${ENDPOINT.EXTERNAL_LOGIN}${toUrlParams({
      idtokenprovider: id,
      domain: document.location.href,
      lang: language,
    })}`;
    document.location.href = url;
  };

  return (
    <>
      <Pressable
        role="login"
        onPress={handlePress}
        dangerouslySetInnerHTML={ghost ? { __html: template(ghost.outerHTML, undefined, translate) } : undefined}
        testId="show-login"
      >
        {!ghost ? (
          <View row className={style.login}>
            <Icon role="content" value={ICON.ACCOUNT} />
            <Text medium role="content">
              {translate(L10N.ACTION_MODAL)}
            </Text>
          </View>
        ) : undefined}
      </Pressable>

      {!skeleton && (
        <Modal
          testId={others.testId}
          visible={modal}
          onClose={() => setModal(false)}
          onVisible={() => setModal(true)}
          className={others.className}
        />
      )}
    </>
  );
};

Login.displayName = 'Mirai:Core:Session:Login';

Login.propTypes = {
  ghost: PropTypes.any,
  skeleton: PropTypes.bool,
};

export { Login };
