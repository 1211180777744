export const L10N = {
  ACTION_MORE_INFO: { id: 'common.action.more_info' },
  ACTION_RESERVE: { id: 'common.action.reserve' },
  ACTION_VIEW_MORE: { id: 'common.action.view_more' },

  LABEL_ACCOMMODATION_PLAN: { id: 'finder.label.accommodation_plan' },
  LABEL_BASE_PRICE: { id: 'common.label.base_price' },
  LABEL_BED_DISPOSITION: { id: 'finder.label.bed_disposition' },
  LABEL_CANCELLATION_POLICY: { id: 'common.label.cancellation_policy' },
  LABEL_COUNTDOWN: { id: 'finder.label.countdown' },
  LABEL_COUNTDOWN_OFFER: { id: 'finder.label.countdown_offer' },
  LABEL_CLUB_MEMBERS: { id: 'finder.label.club_members' },
  LABEL_DESCRIPTION: { id: 'common.label.description' },
  LABEL_DESELECT: { id: 'common.label.deselect' },
  LABEL_DISCOUNT: { id: 'finder.label.discount' },
  LABEL_DISCOUNT_INCLUDED: { id: 'finder.label.discount_included' },
  LABEL_DISCOUNT_TYPE: {
    0: { id: 'finder.label.discount_club' },
    1: { id: 'finder.label.discount_deal' },
    2: { id: 'finder.label.discount_promotion' },
  },
  LABEL_DOUBLE_BED_WIDTH: { id: 'finder.label.double_bed_width' },
  LABEL_EXTRAS: { id: 'common.label.extras' },
  LABEL_FEES: { id: 'finder.label.fees' },
  LABEL_FOR_NIGHTS: { id: 'common.label.for_nights' },
  LABEL_INCLUDED: { id: 'common.label.included' },
  LABEL_LIMITED_OFFER: { id: 'finder.label.limited_offer' },
  LABEL_MAX_OCCUPATION: { id: 'common.label.max_occupation' },
  LABEL_MIRAI_TWIN: { id: 'finder.label.mirai_twin' },
  LABEL_MORE_SERVICES: { id: 'finder.label.more_services' },
  LABEL_NOT_AVAILABLE: { id: 'common.label.not_available' },
  LABEL_OFFER: { id: 'common.label.offer' },
  LABEL_ONLY_MEMBERS: { id: 'finder.label.only_members' },
  LABEL_OR: { id: 'common.label.or' },
  LABEL_PAYMENT_TERMS: { id: 'common.label.payment_terms' },
  LABEL_PER_NIGHT: { id: 'finder.label.per_night' },
  LABEL_PRICE_DETAILS: { id: 'finder.label.price_details' },
  LABEL_REMAINING_ITEMS: { id: 'finder.label.remaining_items' },
  LABEL_SELECT: { id: 'common.label.select' },
  LABEL_SELECTED_OCCUPATION: { id: 'common.label.selected_occupation' },
  LABEL_TAXES: { id: 'finder.label.taxes' },
  LABEL_TOP_AMENITIES: { id: 'finder.label.top_amenities' },
  LABEL_TOTAL: { id: 'common.label.total' },
  LABEL_WHATS_INCLUDED: { id: 'finder.label.whats_included' },
  LABEL_YEARS_OLD: { id: 'common.label.years_old' },
};
