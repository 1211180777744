import { useDevice } from '@mirai/ui';
import { View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../__shared__';

export default () => {
  const { isDesktop } = useDevice();

  const props = { color: 'base' };

  return (
    <View style={{ alignItems: 'start' }} wide>
      <View
        row
        wide
        style={{
          alignItems: 'start',
          alignSelf: 'center',
          gap: '1rem',
          maxWidth: 'var(--mirai-ui-breakpoint-content)',
        }}
      >
        <View style={{ flex: 1, gap: '1rem' }}>
          <Skeleton {...props} style={{ height: '32rem' }} />
          <Skeleton {...props} style={{ height: '16rem' }} />
          <Skeleton {...props} style={{ height: '16rem' }} />
          <Skeleton {...props} style={{ height: '12rem' }} />
        </View>
        {isDesktop && (
          <Skeleton
            {...props}
            style={{
              flex: 1,
              maxWidth: 'calc(var(--mirai-ui-breakpoint-S) - var(--mirai-ui-space-M) * 2)',
              height: '36rem',
            }}
          />
        )}
      </View>
    </View>
  );
};
