import { parseCustomData, sendData } from './helpers';
import { fetchUrlParams } from '../../../../helpers';
import { getRoomsPriceRange } from '../helpers';
import { formatPrice } from '../helpers/legacy';

export const ratesResponse = async ({ instances = [], response: { tracking = {} } = {} } = {}) => {
  // ! -- TODO: Try to get all data from store -------------------------------------------------------------------------
  const { idchain, idtokenprovider: hotelId } = fetchUrlParams(location.search);

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { country, hotelInfo: { currency: hotelCurrency = {}, propertyName } = {}, rates = {} } = tracking;

  let contents = [];
  let numItems = 0;
  Object.entries(getRoomsPriceRange(rates, hotelCurrency)).forEach(([key, { minRate = {}, maxRate = {} } = {}]) => {
    const quantity = rates[key].amountAvailable;

    contents = [
      ...contents,
      {
        id: minRate.id,
        quantity,
        itemPrice: formatPrice(minRate.totalPriceWithTaxes, hotelCurrency),
      },
      {
        id: maxRate.id,
        quantity,
        itemPrice: formatPrice(maxRate.totalPriceWithTaxes, hotelCurrency),
      },
    ];

    numItems += 2 * quantity;
  });

  const commonData = {
    customData: parseCustomData({
      contents,
      country,
      hotelCurrency,
      numItems,
      propertyName,
    }),
  };

  const events = ['Search', ...(idchain ? [`Search_${hotelId}`] : [])];

  const responses = await Promise.all(
    events.flatMap((eventName) =>
      instances.map(({ id, pixelId }) => sendData({ ...commonData, eventName, idTracking: id, pixelId })),
    ),
  ).catch(() => {});

  return responses;
};
