import { ServiceFeatures } from '@mirai/services';

import { FEATURE_CHECKOUT_EXPERIMENT, VARIANTS } from '../Checkout.constants';

const [DEFAULT_VARIANT] = VARIANTS || [];

export const getVariant = ({ id, isDesktop = true, urlParams: { variant: urlVariant } = {} } = {}) => {
  if (isDesktop) return;

  let value;

  if (VARIANTS.includes(urlVariant)) {
    value = urlVariant;
  } else if (ServiceFeatures.get(FEATURE_CHECKOUT_EXPERIMENT, id)) {
    value = VARIANTS[Math.floor(Math.random() * VARIANTS.length)];
  }

  return value || DEFAULT_VARIANT;
};
