import { useStore } from '@mirai/data-sources';
import { dateFormat, useLocale } from '@mirai/locale';
import { Button, Calendar as CalendarBase, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import { DEVICE_XS_HEIGHT } from './Deals.constants';
import { L10N } from './Deals.l10n';
import * as style from './Deals.module.css';
import { calcFromDate, calcToDate, calcTooltips } from './helpers';
import { BookingDates, Field } from '../__shared__';
import { getDataSource, getHotels } from '../Finder/helpers';
import { CALENDAR_FORMAT as format, MONTHS_IN_MOBILE, parseValue, valueFormat } from '../helpers';
import { getAccommodationType, getCalendarCaption, ICON } from '../helpers';

const Calendar = ({
  captions = {},
  highlights = [],
  minNightsWithDeals,
  pricesInfo: { currency, guests, nights, taxType } = {},
  rangeMaxDays,
  rangeMinDays,
  visible,
  visited,
  onPress,
  ...others
}) => {
  const { height, isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: { locale, ...store },
  } = useStore();

  const [focus, setFocus] = useState();
  const [value, setValue] = useState(valueFormat(others.value));

  useEffect(() => {
    setValue(valueFormat(others.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [others.value]);

  const [from, to] = parseValue(value);

  const { config: { hotels: dataSource, lodgingDenomination: individual } = {}, hotel: { id } = {} } = store || {};

  const tagId = getDataSource(store)?.places?.options?.find(
    ({ id: ids = [], isHotel } = {}) => isHotel && ids[0] === id,
  )?.value;

  const accommodationType = getAccommodationType({
    dataSource,
    hotels: getHotels(getDataSource(store)?.placesSource, tagId),
    individual: individual && typeof individual === 'object' ? individual[id] : individual,
  });

  return React.createElement(
    isMobile ? Field : Fragment,
    isMobile
      ? {
          caption: value.length ? getCalendarCaption(parseValue(value), translate, dateFormat, locale) : undefined,
          error: visited && (!from || !to),
          icon: ICON.CALENDAR,
          label: translate(L10N.LABEL_WHEN),
          placeholder: getCalendarCaption(undefined, translate, dateFormat, locale),
          visible,
          onPress,
          className: style.fieldCalendar,
          testId: `${others.testId}-field`,
        }
      : undefined,
    <>
      <CalendarBase
        {...{
          ...others,
          captions,
          format,
          from: calcFromDate(highlights),
          highlights,
          locale,
          months: isMobile ? MONTHS_IN_MOBILE : undefined,
          range: true,
          rangeMaxDays,
          rangeMinDays,
          to: calcToDate(highlights, rangeMaxDays),
          tooltips: calcTooltips({ focus, from, highlights, minNightsWithDeals, rangeMinDays, to, translate }),
          value,
          onFocus: setFocus,
          className: styles(height < DEVICE_XS_HEIGHT && style.calendarXS),
        }}
      />

      <View className={style.calendarInfo}>
        {!isMobile && <BookingDates {...{ focus, from, rangeMinDays, to }} />}

        <Text small className={style.notification}>
          {translate(L10N.LABEL_INFO_PRICES, {
            accommodation: translate(L10N.LABEL_ACCOMMODATION_TYPE, { type: accommodationType }),
            currency,
            guests,
            nights,
            taxType,
          })}
        </Text>
      </View>

      {isMobile && (
        <View row className={style.footer}>
          <BookingDates {...{ focus, from, rangeMinDays, to }} />

          <Button disabled={!from || !to} onPress={onPress} testId={`${others.testId}-button-next`}>
            {translate(L10N.ACTION_NEXT)}
          </Button>
        </View>
      )}
    </>,
  );
};

Calendar.displayName = 'Mirai:Core:Deals:Calendar';

Calendar.propTypes = {
  captions: PropTypes.shape({}),
  highlights: PropTypes.arrayOf(PropTypes.string),
  minNightsWithDeals: PropTypes.number,
  pricesInfo: PropTypes.shape({
    currency: PropTypes.string,
    guests: PropTypes.number,
    nights: PropTypes.number,
    taxType: PropTypes.number,
  }),
  rangeMaxDays: PropTypes.number,
  rangeMinDays: PropTypes.number,
  visible: PropTypes.bool,
  visited: PropTypes.bool,
  onChange: PropTypes.func,
  onPress: PropTypes.func,
};

export { Calendar };
