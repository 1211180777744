import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Primitive } from '../Primitive';
import style from './Switch.module.css';

const Switch = ({ checked = false, disabled, error, name, onChange, ...others }) => {
  const handleChange = (event) => {
    onChange && onChange(!checked, event);
  };

  return (
    <Primitive
      {...others}
      tag="switch"
      onClick={!disabled ? handleChange : undefined}
      className={styles(style.switch, others.className)}
    >
      <Primitive checked={checked} disabled={disabled} name={name} tag="input" type="checkbox" onChange={() => {}} />
      <Primitive className={styles(style.area, error && !checked && !disabled && style.error)}>
        <Primitive className={style.toggle} />
      </Primitive>
    </Primitive>
  );
};

Switch.displayName = 'Primitive:Switch';

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export { Switch };
