import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, Button, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Calendar, Places } from './components';
import { Compact } from './Finder.compact';
import { FIELD, TYPE, VIEW } from './Finder.constants';
import { L10N } from './Finder.l10n';
import * as style from './Finder.module.css';
import {
  getDataSource,
  getFieldPosition,
  getForwarderUrl,
  getHotels,
  getOccupationDataSource,
  getPlaceCaption,
  parseCalendarProps,
  parseValue,
} from './helpers';
import { setStorage } from './helpers';
import { getAvailableWidth } from './helpers/getAvailableWidth';
import { fetchCurrency, fetchUrlParams } from '../../helpers';
// ! TODO: Why we don't publish prepareRoom in ../../helpers
import { prepareRoom } from '../../helpers/prepareRoom';
import { Field, FieldOccupation, FieldPromocode } from '../__shared__';
import { DATASOURCE } from '../__shared__/Occupation/Occupation.constants';
import { EVENT, getCalendarCaption, ICON } from '../helpers';

const { HOTEL } = TYPE;
let timeoutMetricChange;

const Finder = ({
  compacted = false,
  icon = false,
  inline = true,
  layout,
  metrics = 'finder',
  skeleton,
  storage = true,
  text,
  onChange = () => {},
  onSubmit,
  ...others
}) => {
  const { isMobile, width: deviceWidth } = useDevice();
  const { dateFormat, translate } = useLocale();
  const ref = useRef(null);
  const {
    set,
    value: { components = {}, currency: propCurrency, finder: storeFinder, forwarder, locale, type = HOTEL, ...store },
  } = useStore();

  const [currency, setCurrency] = useState();
  const [error, setError] = useState({});
  const [focus, setFocus] = useState();
  const [unavailability, setUnavailability] = useState();
  const [value, setValue] = useState(parseValue(storeFinder));
  const [visited, setVisited] = useState({});
  const [warning, setWarning] = useState();
  const [width, setWidth] = useState();

  useEffect(() => {
    if (skeleton) return;

    if (!storeFinder?.occupation) set({ finder: { ...storeFinder, occupation: [prepareRoom(DATASOURCE)] } });

    window.addEventListener('pageshow', handlePageShow);
    !isMobile && document.addEventListener('click', handleClickOutside, true);

    if (metrics === 'finder') Event.publish(EVENT.METRICS, { id: 'FINDER:RENDER' });

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
      !isMobile && document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (layout === 'column' && ref.current) setWidth(getAvailableWidth(ref.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceWidth]);

  useEffect(() => {
    unavailability && setUnavailability({ disabledDates: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.occupation, value?.place]);

  useEffect(() => {
    const { place: { id, isHotel } = {} } = value || {};
    if (isHotel && !fetchUrlParams(window.location.search)?.currency) {
      // ! TODO: Same here, this should be responsibility of <Core>...
      (async () => {
        setCurrency(await fetchCurrency(id[0]));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.place]);

  useEffect(() => {
    !onSubmit && !skeleton && Event.publish(EVENT.FINDER_READY, { event: EVENT.FINDER_READY });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleFinder = ({ calendar, finder, focus } = {}) => {
      if (finder) return handleSubmit({ nextValue: finder });

      setUnavailability(calendar);
      if (focus) setFocus(focus);
    };
    Event.subscribe(EVENT.FINDER, handleFinder);

    return () => Event.unsubscribe(EVENT.FINDER, handleFinder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, propCurrency]);

  // ! TODO: Why this one? Is not responsibility of <Finder> change the currency when prop changes.
  useEffect(() => setCurrency(propCurrency), [propCurrency]);

  useEffect(
    () => {
      if (JSON.stringify(value) !== JSON.stringify(parseValue(storeFinder))) setValue(parseValue(storeFinder));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeFinder],
  );

  useEffect(() => {
    focus && setVisited({ ...visited, [`${focus}`]: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  const handleChange = (field, fieldValue) => {
    const next = { ...value, [field]: fieldValue };

    setValue(next);
    onChange(next, field, fieldValue);

    Event.publish(EVENT.FINDER_FIELD_CHANGE, { field, previousValue: value[field], value: fieldValue });
    if (metrics) {
      clearTimeout(timeoutMetricChange);
      timeoutMetricChange = setTimeout(() => Event.publish(EVENT.METRICS, { id: `${metrics}:CHANGE:${field}` }), 500);
    }
  };

  const handleClickOutside = ({ target } = {}) => !ref.current?.contains(target) && setFocus(undefined);

  const handlePageShow = async ({ persisted } = {}) => {
    if (!persisted) return;

    setFocus();
    setVisited();
    setValue(parseValue(storeFinder));
  };

  const handlePlaceChange = async (place) => {
    setWarning(false);
    handleChange(VIEW.PLACE, place);
    !is.rates && assistant();
  };

  const handleSubmit = ({ nextValue = value } = {}) => {
    if (dataSource.places && value.place === undefined) return setFocus(VIEW.PLACE);

    if (value.calendar?.length !== 2) return setFocus(VIEW.CALENDAR);

    const [firstError] = Object.keys(error).filter((key) => !!error[key]);
    if (firstError) return setFocus(firstError);

    setWarning(false);
    setFocus(undefined);
    set({ finder: nextValue });
    const forwarderUrl = getForwarderUrl({ ...nextValue, currency, forwarder, locale, store, type });

    storage && setStorage(nextValue, store?.id);
    if (onSubmit) onSubmit({ ...nextValue, forwarderUrl });
    else window.location.href = forwarderUrl;

    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:SUBMIT`, ...nextValue });
  };

  const assistant = () => {
    const { calendar = [] } = value;
    if (focus === VIEW.PLACE && !visited.calendar && calendar.length !== 2) setFocus(VIEW.CALENDAR);
    else if (!compacted || [VIEW.CALENDAR, VIEW.PLACE].includes(focus)) setFocus(undefined);
  };

  const { calendar = [], occupation, place = {}, promocode } = value;
  const { id: ids = [], isHotel } = place;
  const dataSource = getDataSource({ type, ...store });
  const { hotel: { id: idHotel } = {} } = store;
  const id = type !== HOTEL ? (isHotel && ids.length === 1 ? ids[0] : undefined) : idHotel;

  const is = {
    column: layout === 'column',
    compacted: layout !== 'column' && (isMobile || (compacted && !focus)),
    rates: components.rates !== undefined,
  };

  const fieldProps = {
    compacted: is.compacted,
    hasPlaces: !!dataSource.places,
    inline,
    ...(!is.compacted ? getFieldPosition(ref) : undefined),
    className: style.field,
    style: is.column && width ? { maxWidth: width, width } : undefined,
  };

  return (
    <>
      {is.compacted && (
        <Compact
          {...{ value, dataSource }}
          onExpand={setFocus}
          onSubmit={!is.rates || (is.rates && !isMobile) ? handleSubmit : undefined}
          {...others}
        />
      )}

      <View
        ref={ref}
        role="finder"
        row={!is.column}
        tag="finder"
        testId={others.testId}
        className={!is.compacted ? styles(style.finder, is.column && style.column, others.className) : undefined}
      >
        {dataSource.places && (
          <>
            <Field
              {...FIELD.PLACE}
              caption={getPlaceCaption(place.value, dataSource.places.options)}
              label={translate(L10N.LABEL_WHERE)}
              placeholder={dataSource.places.placeholder || translate(L10N.LABEL_WHERE_PLACEHOLDER)}
              visible={focus === VIEW.PLACE}
              onPress={() => setFocus(focus !== VIEW.PLACE ? VIEW.PLACE : undefined)}
              {...fieldProps}
            >
              <Places
                autoColumn={focus === VIEW.PLACE}
                dataSource={dataSource.places.options}
                text={compacted ? text : undefined}
                value={place.value}
                onChange={handlePlaceChange}
                onSubmit={is.rates ? handleSubmit : undefined}
                testId={others.testId ? `${others.testId}-places` : undefined}
              />
            </Field>

            {inline && <View className={style.separator} />}
          </>
        )}

        <Field
          {...FIELD.CALENDAR}
          caption={calendar.length ? getCalendarCaption(calendar, translate, dateFormat) : undefined}
          centered={type !== HOTEL}
          label={translate(L10N.LABEL_WHEN)}
          placeholder={getCalendarCaption(undefined, translate, dateFormat)}
          visible={focus === VIEW.CALENDAR}
          onPress={() => setFocus(focus !== VIEW.CALENDAR ? VIEW.CALENDAR : undefined)}
          testId={others.testId ? `${others.testId}-calendar-field` : undefined}
          {...fieldProps}
        >
          <Calendar
            {...parseCalendarProps(dataSource.calendar, id, occupation, type, unavailability, dataSource.variants)}
            id={id}
            currency={currency || propCurrency}
            locale={locale}
            rangeMinDays={1}
            text={compacted ? text : undefined}
            top={fieldProps?.top}
            skeleton={skeleton}
            unavailability={unavailability}
            value={calendar}
            onChange={(nextCalendar, event) => {
              handleChange(VIEW.CALENDAR, nextCalendar, event);
              if (nextCalendar.length === 2 && !is.rates) {
                setError({ ...error, calendar: undefined });
                assistant();
              }
            }}
            onError={(calendar) => setError({ ...error, calendar })}
            onSubmit={is.rates ? handleSubmit : undefined}
            onValid={() => setError({ ...error, calendar: undefined })}
            testId={others.testId ? `${others.testId}-calendar` : undefined}
          />
        </Field>

        {inline && <View className={style.separator} />}

        <FieldOccupation
          dataSource={getOccupationDataSource({
            ...dataSource,
            places: dataSource.places?.options,
            place: place?.value,
          })}
          hotels={getHotels(dataSource.placesSource, place.value)}
          maxRooms={dataSource.maxRooms}
          text={compacted ? text : undefined}
          value={occupation}
          visible={focus === VIEW.OCCUPATION}
          withWarning={warning}
          onChange={(occupation, event) => handleChange(VIEW.OCCUPATION, occupation, event)}
          onError={(occupation) => {
            if (!isMobile && focus !== VIEW.OCCUPATION) setFocus(VIEW.OCCUPATION);
            setError({ ...error, occupation });
          }}
          onPress={() => setFocus(focus !== VIEW.OCCUPATION ? VIEW.OCCUPATION : undefined)}
          onSubmit={is.rates ? handleSubmit : undefined}
          onValid={() => setError({ ...error, occupation: undefined })}
          onWarning={() => {
            if (!isMobile && focus !== VIEW.OCCUPATION) setFocus(VIEW.OCCUPATION);
          }}
          testId={others.testId ? `${others.testId}-occupation` : undefined}
          {...fieldProps}
        />

        {inline && <View className={style.separator} />}

        <FieldPromocode
          value={promocode}
          visible={focus === VIEW.PROMOCODE}
          onChange={(promocode, event) => handleChange(VIEW.PROMOCODE, promocode, event)}
          onEnter={!compacted ? () => setFocus(VIEW.PROMOCODE) : undefined}
          onLeave={!compacted ? () => setFocus(undefined) : undefined}
          onPress={
            isMobile && compacted ? () => setFocus(focus !== VIEW.PROMOCODE ? VIEW.PROMOCODE : undefined) : undefined
          }
          onSubmit={isMobile && compacted ? handleSubmit : undefined}
          testId={others.testId ? `${others.testId}-promocode` : undefined}
          {...fieldProps}
        />

        {!is.compacted && (
          <Button
            large
            tabindex={4}
            role="finder-button"
            wide={is.column}
            onPress={handleSubmit}
            className={styles(style.button, icon && !is.column && style.withIcon)}
            testId={others.testId ? `${others.testId}-submit` : undefined}
          >
            {icon && !is.column ? <Icon value={ICON.SEARCH} /> : text || translate(L10N.ACTION_SEARCH)}
          </Button>
        )}
      </View>
    </>
  );
};

Finder.displayName = 'Mirai:Core:Finder';

Finder.propTypes = {
  compacted: PropTypes.bool,
  icon: PropTypes.bool,
  inline: PropTypes.bool,
  layout: PropTypes.oneOf(['column']),
  metrics: PropTypes.string,
  skeleton: PropTypes.bool,
  storage: PropTypes.bool,
  text: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { Finder };
