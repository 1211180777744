import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Action, Notification, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { L10N } from './NotificationRequiredFields.l10n';
import * as style from './NotificationRequiredFields.module.css';
import { EVENT } from '../../helpers';

const OFFSET = 16;

const NotificationRequiredFields = ({ form = 'form', schema = {}, values = {}, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: { components = {} },
  } = useStore();

  useEffect(() => {
    if (metrics) {
      const keys = Object.keys(values);
      Event.publish(EVENT.METRICS, { id: `${metrics}:ERROR_REQUIRED_FIELDS`, fields: keys, total: keys.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handlePress = (id) => {
    const el = document.querySelector(id);
    el && window.scrollTo({ top: el.getBoundingClientRect().top + window.scrollY - OFFSET, behavior: 'smooth' });
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:ERROR_REQUIRED_FIELDS:VIEW_FIELD`, field: id });
  };

  const metrics = components.checkout
    ? 'CHECKOUT'
    : components.signup
    ? 'SIGNUP'
    : components.profile
    ? 'PROFILE'
    : undefined;

  return Object.keys(values).length ? (
    <Notification {...others} error small={isMobile} wide>
      {translate(L10N.NOTIFICATION_REQUIRED_FIELDS)}
      <View row wide className={style.fields}>
        {Object.keys(values).map((field) => (
          <Action
            key={field}
            markdown={false}
            underline
            small
            onPress={() => handlePress(`#${form}-${field.replace(/\./g, '-')}`)}
          >
            {L10N[`LABEL_${field.toUpperCase()}`]
              ? translate(L10N[`LABEL_${field.toUpperCase()}`])
              : schema[field]?.label || field}
          </Action>
        ))}
      </View>
    </Notification>
  ) : null;
};

NotificationRequiredFields.displayName = 'Mirai:Core:NotificationRequiredFields';

NotificationRequiredFields.propTypes = {
  form: PropTypes.string,
  schema: PropTypes.shape({}),
  values: PropTypes.shape({}),
};

export { NotificationRequiredFields };
