export const getLayerPosition = (
  componentLayout = {},
  contentLayout = {},
  device = {},
  modal,
  { centered, forceBottom, forceLeft, forceRight, forceTop } = {},
) => {
  const {
    left: componentLeft = 0,
    scrollTop = 0,
    top: componentTop = 0,
    width: componentWidth = 0,
    height: componentHeight = 0,
    screenLeft = componentLeft || 0,
    screenTop = componentTop || 0,
  } = componentLayout;
  const { width: contentWidth = 0, height: contentHeight = 0 } = contentLayout;
  const { height = 0, width = 0 } = modal || device;
  const aline = centered && (forceLeft || forceRight);
  const offsetLeft = modal ? componentLeft : screenLeft;
  const offsetTop = modal ? componentTop : screenTop;
  let orientation = { left: true };

  let top = componentTop + componentHeight - scrollTop;
  const gapBottom = height - (offsetTop + componentHeight);
  if ((contentHeight > gapBottom && offsetTop > gapBottom && !forceBottom) || forceTop) {
    top = componentTop - contentHeight - scrollTop;
    orientation = { ...orientation, top: true };
  }

  let left = componentLeft;
  const gapLeft = componentLeft + componentWidth;
  const gapRight = width - offsetLeft;
  if ((contentWidth > gapRight && gapLeft > gapRight && !forceLeft) || forceRight) {
    left = componentLeft - contentWidth + componentWidth;
    orientation = { ...orientation, left: undefined, right: true };
  }

  if (aline) {
    orientation = { ...orientation, left: forceLeft, right: forceRight, top: undefined };
    if (forceLeft && contentWidth > gapLeft && gapRight > gapLeft)
      orientation = { ...orientation, left: undefined, right: true };
    else if (forceRight && contentWidth > gapRight && gapLeft > gapRight)
      orientation = { ...orientation, left: true, right: undefined };

    left = orientation.left ? componentLeft - contentWidth : componentLeft + componentWidth;
    top = componentTop - (contentHeight - componentHeight) / 2 - scrollTop;
  } else if (centered) {
    const gapCenter = (componentWidth + contentWidth) / 2;
    const space = (contentWidth - componentWidth) / 2;
    orientation = { ...orientation, left: true, right: undefined };

    if (gapLeft > gapRight) {
      left = componentLeft - contentWidth + componentWidth;
      orientation = { ...orientation, left: undefined, right: true };
    }
    if (gapLeft > gapCenter && gapRight > gapCenter) {
      left += space * (gapLeft > gapRight ? 1 : -1);
      orientation = { ...orientation, left: undefined, right: undefined, center: true };
    }
  }

  return { left, top, orientation };
};
