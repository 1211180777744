import { useStore } from '@mirai/data-sources';
import { CURRENCIES, DEFAULT_CURRENCY, dateCalc } from '@mirai/locale';
import { useLocale } from '@mirai/locale';
import { Button, Calendar as CalendarBase, Notification, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ERROR, MONTHS, RANGE_MAX_DAYS, SCROLL_EVENT_MS, TAX, TAX_FREE, TAX_PARTIAL } from './Calendar.constants';
import { L10N } from './Calendar.l10n';
import * as style from './Calendar.module.css';
import { calcTooltips, fetchPrices, getVisibleDate } from './helpers';
import { BookingDates } from '../../../__shared__';
import {
  CALENDAR_FORMAT,
  getAccommodationType,
  IS_JEST,
  MONTHS_IN_MOBILE,
  parseValue,
  valueFormat,
} from '../../../helpers';
import { getDataSource, getHotels } from '../../helpers';

const Calendar = ({
  currency = DEFAULT_CURRENCY,
  id,
  locale,
  pricesLegend,
  rangeMaxDays = RANGE_MAX_DAYS,
  rangeMinDays,
  showPrices,
  skeleton,
  text,
  top = false,
  unavailability: { disabledDates = [], minStayDates = {}, minStayType } = {},
  onChange,
  onError,
  onSubmit,
  onValid,
  ...others
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const { value: store } = useStore();

  const [focus, setFocus] = useState();
  const [prices, setPrices] = useState();
  const [value, setValue] = useState(valueFormat(others.value));
  const [visibleDate, setVisibleDate] = useState();

  useEffect(() => {
    if (skeleton) return;

    setPrices();

    const nextValue = visibleDate || (value?.length && value[0] && parseValue(value)[0]);
    showPrices && id && getPrices((nextValue && dateCalc(nextValue, -1, 'months')) || new Date(), MONTHS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, id, showPrices]);

  useEffect(() => {
    const [from, to] = value || [];

    if (onValid && from && to) onValid();
    else if (onError && (!from || !to)) onError(ERROR.NOT_FILLED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    JSON.stringify(value) !== JSON.stringify(valueFormat(others.value)) && setValue(valueFormat(others.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [others.value]);

  const getPrices = async (from, rangeMonths = 2) => {
    if (IS_JEST || !from) return;

    const next = await fetchPrices({ currency, from, id, locale, rangeMonths });
    setPrices((current) => {
      return { currency: next.currency, values: { ...current?.values, ...next?.values } };
    });
  };

  const handleChange = (next = [], event) => {
    setFocus(undefined);
    setValue(valueFormat(next));
    onChange && onChange(next, event);
  };

  const handleNavigation = (value) => {
    setVisibleDate(value);

    return showPrices && id && getPrices(value);
  };

  const handleScroll = ({ percentY }) => {
    const date = getVisibleDate(percentY, others.to);
    setVisibleDate(date);

    return showPrices && id && getPrices(date, MONTHS);
  };

  const handleSubmit = (event) => onSubmit(value, event);

  const { testId } = others;
  const [from, to] = parseValue(value);
  const props = { ...others, locale, range: true, rangeMaxDays, rangeMinDays };

  const { config: { hotels: dataSource, lodgingDenomination: individual } = {} } = store || {};

  const tagId = getDataSource(store)?.places?.options?.find(
    ({ id: ids = [], isHotel } = {}) => isHotel && ids[0] === id,
  )?.value;

  const accommodationType = getAccommodationType({
    dataSource,
    hotels: getHotels(getDataSource(store)?.placesSource, tagId),
    individual: individual && typeof individual === 'object' ? individual[id] : individual,
  });

  return (
    <View role="calendar" className={styles(style.container, top && style.top)}>
      <CalendarBase
        {...props}
        captions={prices?.values}
        disabledDates={disabledDates}
        format={CALENDAR_FORMAT}
        months={isMobile ? MONTHS_IN_MOBILE : undefined}
        scrollEventThrottle={SCROLL_EVENT_MS}
        tooltips={calcTooltips({ from, minStayDates, minStayType, rangeMinDays, to, translate })}
        value={value}
        onChange={handleChange}
        onFocus={setFocus}
        onNavigation={handleNavigation}
        onScroll={handleScroll}
        className={styles(style.calendar, others.className)}
      />

      {prices?.currency && (
        <Notification inline small className={style.notification}>
          {translate(L10N.LABEL_INFO_PRICES, {
            accommodation: translate(L10N.LABEL_ACCOMMODATION_TYPE, { type: accommodationType }),
            currency,
            guests: 2,
            nights: 1,
            taxType: pricesLegend,
          })}
        </Notification>
      )}

      {onSubmit && (
        <View row className={style.footer}>
          <BookingDates {...{ from, focus, to, rangeMinDays }} />
          <Button testId={testId ? `${testId}-submit` : undefined} disabled={!from || !to} onPress={handleSubmit}>
            {text || translate(L10N.ACTION_SEARCH)}
          </Button>
        </View>
      )}
    </View>
  );
};

Calendar.displayName = 'Mirai:Core:Finder:Calendar';

Calendar.propTypes = {
  currency: PropTypes.oneOf(CURRENCIES),
  id: PropTypes.string,
  locale: PropTypes.string,
  rangeMaxDays: PropTypes.number,
  rangeMinDays: PropTypes.number,
  showPrices: PropTypes.bool,
  skeleton: PropTypes.bool,
  pricesLegend: PropTypes.oneOf([TAX, TAX_FREE, TAX_PARTIAL]),
  text: PropTypes.string,
  top: PropTypes.bool,
  unavailability: PropTypes.shape({}),
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onValid: PropTypes.func,
};

export { Calendar };
