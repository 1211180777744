import { MODAL_EVENT_VISIBLE } from '@mirai/ui';

const ACCOMMODATION_TYPE = {
  ROOM: 1,
  APARTMENT: 2,
  PITCH: 3,
  ACCOMMODATION: 4,
  VILLA: 5,
};

const CALENDAR_FORMAT = 'DD/MM/YYYY';

const EVENT = {
  // -- @mirai/core
  BOOKING_RESPONSE: 'bookingResponse',
  BOOKING_CANCEL: 'bookingCancel',
  CHECKOUT_RESPONSE: 'checkoutResponse',
  CHECKOUT_FORM: 'checkoutForm',
  FINDER: 'finder',
  FINDER_FIELD_CHANGE: 'finderFieldChange',
  FINDER_READY: 'finderReady',
  FOOTER_HEIGHT_CHANGE: 'footerHeightChenge',
  INTENT_CURRENCY: 'intentCurrency',
  INTENT_LOCALE: 'intentLocale',
  LOGGED: 'logged',
  LOGIN: 'login',
  METRICS: 'metrics',
  NOTIFICATION: 'notification',
  PAY_LATER: 'payLater',
  RATES_ADD_TO_CART: 'ratesAddToCart',
  RATES_RESPONSE: 'ratesResponse',
  RATES_FILTER_CLUB: 'ratesFilterClub',
  RATES_FILTER_PROMOCODE: 'ratesFilterPromocode',
  // -- @mirai/ui
  MODAL_VISIBLE: MODAL_EVENT_VISIBLE,
};

const LOCALES = [
  'en-GB',
  'es-ES',
  'fr-FR',
  'de-DE',
  'ja-JP',
  'it-IT',
  'pt-PT',
  'da-DK',
  'fi-FI',
  'nb-NO',
  'nl-NL',
  'pl-PL',
  'ru-RU',
  'sv-SE',
  'tr-TR',
  'zh-CN',
  'cs-CZ',
  'el-GR',
  'ar-DZ',
  'hu-HU',
  'ca-ES',
  'eu-ES',
  'vi-VN',
];

const MONTHS_IN_MOBILE = 12;

const OCCUPATION_TYPE = {
  GUEST: 1,
  ADULT: 2,
  CHILD: 3,
  BABY: 4,
};

const PAYMENT_CARD = {
  AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
  DINERS_CLUB: 'DINERS_CLUB',
  DISCOVER: 'DISCOVER',
  JCB: 'JCB',
  MAESTRO: 'MAESTRO', // low resolution logo
  MASTERCARD: 'MASTERCARD',
  UNIONPAY: 'UNIONPAY',
  VISA: 'VISA',
};

const PAYMENT_METHOD = {
  ALIPAY: 'ALIPAY',
  AMAZON_PAY: 'AMAZON_PAY',
  APLAZAME: 'APLAZAME',
  BIZUM: 'BIZUM',
  CARD: 'CARD',
  CARD_PREPAY: 'CARD_PREPAY',
  CRIPTAN: 'CRIPTAN',
  IDEAL: 'IDEAL',
  OXXO: 'OXXO',
  PAYLATER: 'PAYLATER',
  PAYPAL: 'PAYPAL',
  PCIPROXY: 'PCIPROXY',
  SOFORT: 'SOFORT',
  TPV: 'TPV',
  TRANSFER: 'TRANSFER',
  WECHATPAY: 'WECHATPAY',
};

const PAYMENT_PROVIDER = {
  ADDON: 'ADDON',
  ADYEN: 'ADYEN',
  CARDNET: 'CARDNET',
  CHECKOUT: 'CHECKOUT',
  HIPAY: 'HIPAY',
  MULTIPAGOS: 'MULTIPAGOS',
  REDSYS: 'REDSYS',
  REDUNIQ: 'REDUNIQ',
  PAYBOX: 'PAYBOX',
  PAYCOMET: 'PAYCOMET',
  PAYNOPAIN: 'PAYNOPAIN',
  SIPAY: 'SIPAY',
  STRIPE: 'STRIPE',
};

const STEP = {
  BOOKING_CANCELLATION: 'CANCELLATION',
  BOOKING_CONFIRMATION: 'CONFIRMATION',
  CHECKOUT: 'CLIENTFORM',
  FINDER: 'BOOKINGENTRANCE',
  HOTELS: 'CHAINRESULT',
  RATES: 'BOOKINGSTEP1',
};

const SUBMIT_DELAY = 800;

export {
  ACCOMMODATION_TYPE,
  CALENDAR_FORMAT,
  EVENT,
  LOCALES,
  MONTHS_IN_MOBILE,
  OCCUPATION_TYPE,
  PAYMENT_CARD,
  PAYMENT_METHOD,
  PAYMENT_PROVIDER,
  STEP,
  SUBMIT_DELAY,
};
