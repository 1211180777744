const CACHE_LIMIT = 1000 * 60 * 60 * 24 * 7; // 7 days

const ERROR = {
  AUTHENTICATION: { code: 10, message: 'The credentials are incorrect', payload: undefined },
  NOT_FILLED: { code: 0, message: 'Not all required fields have been filled out.', payload: undefined },
  NOT_SESSION: { code: 0, message: 'Not session' },
  UNKNOWN: { code: 0, message: 'Something wrong happened. Try again.', payload: undefined },
};

const USER_STORAGE_KEY = 'MIRAI:SERVICE:USER';

export { CACHE_LIMIT, ERROR, USER_STORAGE_KEY };
