import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceRates } from '@mirai/services';
import { Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Extras as ExtrasBase } from '../../Rates/components/ModalExtras/Extras';
import { L10N } from '../Checkout.l10n';
import * as style from '../Checkout.module.css';

const Extras = ({ hotel: { currency } = {}, onChange }) => {
  const { isDesktop, mobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: { locale, session, urlParams = {} },
  } = useStore();

  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    (async () => {
      const { extras = [] } =
        (await ServiceRates.extras({
          ...JSON.parse(urlParams.checkoutParams),
          ...JSON.parse(urlParams.occupationTotals),
          clubDiscount: !!session || urlParams.applyClubDiscount === 'true',
          device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
          hotelCurrency: currency,
          locale,
        }).catch(() => {})) || {};
      const selectableExtras = extras.some(({ included } = {}) => !included);

      if (selectableExtras) setDataSource(extras);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return dataSource?.length > 0 ? (
    <View className={style.box}>
      <Text bold headline={isDesktop}>
        {translate(L10N.LABEL_ENHANCED_YOUR_STAY)}
      </Text>
      <ExtrasBase extras={dataSource} horizontal onChange={onChange} />
    </View>
  ) : null;
};

Extras.displayName = 'Mirai:Core:Checkout:Extras';

Extras.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  hotel: PropTypes.shape({
    currency: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export { Extras };
