import { useDevice, View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../__shared__';

const BannerLoyaltySkeleton = ({ ...others }) => {
  const { isMobile } = useDevice();

  return (
    <Skeleton {...others} color="base" style={{ border: 'solid 1px var(--mirai-ui-content-border)' }}>
      <Skeleton
        color="accent"
        row
        style={{
          borderBottom: 'solid 1px var(--mirai-ui-content-border)',
          justifyContent: 'space-between',
          padding: '1rem',
        }}
      >
        <View style={{ gap: '0.25rem' }}>
          <Skeleton headline level={isMobile ? 2 : 1}>
            Banner Loyalty
          </Skeleton>
          <Skeleton action={isMobile}>
            Choose CLUB MEMBER rate, join and enjoy Web Bonus, special benefits for members!
          </Skeleton>
        </View>
        {!isMobile && <Skeleton>See loyalty benefits CHECK</Skeleton>}
      </Skeleton>

      <View row={!isMobile} style={{ gap: isMobile ? '1rem' : '4rem', padding: '1.5rem 1rem' }} wide>
        {Array.from({ length: 4 }).map((_, index) => (
          <View row key={index} style={{ gap: '1rem' }}>
            <Skeleton
              style={isMobile ? { minHeight: '1rem', minWidth: '1rem' } : { minHeight: '2rem', minWidth: '2rem' }}
            />
            <Skeleton action={!isMobile} small={isMobile}>
              5$ reward on your web bookings guaranteed
            </Skeleton>
          </View>
        ))}
      </View>

      {isMobile && (
        <View
          row
          style={{
            borderTop: 'solid 1px var(--mirai-ui-content-border)',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <Skeleton>See loyalty benefits CHECK</Skeleton>
          <Skeleton>CHECK</Skeleton>
        </View>
      )}
    </Skeleton>
  );
};

BannerLoyaltySkeleton.displayName = 'Mirai:Core:BannerLoyalty:Skeleton';

BannerLoyaltySkeleton.propTypes = {};

export default BannerLoyaltySkeleton;
