import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Calendar, Icon, InputText, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getVerboseList, getVerboseOccupation } from './helpers';
import { L10N } from './InputRich.l10n';
import { List } from './InputRich.List';
import * as style from './InputRich.module.css';
import { Occupation } from '../../../__shared__';
import { ICON } from '../../../helpers';
import { EVENT } from '../../Chat.constants';
import { getVerboseDate } from '../../helpers';
import { Avatar } from '../Avatar';

const CALENDAR_PROPS = { format: 'DD/MM/YYYY', months: 12, range: true, vertical: true };
const INPUT_TEXT_TYPES = ['text', 'password', 'email', 'number'];

const InputRich = ({ disabled = false, onValue = () => {}, ...others }) => {
  const { dateFormat, translate } = useLocale();
  const {
    value: { lisa, locale, ...store },
  } = useStore();

  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState();
  const [valid, setValid] = useState();
  const [visible, setVisible] = useState();

  useEffect(() => {
    const callback = (props = {}) => {
      const { response: { requirements = {} } = {} } = lisa;
      const [name] = Object.keys(requirements);
      if (!name) return;

      const next = { ...props, name, ...requirements[name] };
      setDataSource(next);

      setValid(); // ! TODO: In some cases (occupation & promocode) we should set "something"
      setValue();
      setVisible(true);
    };

    Event.subscribe(EVENT.INPUT_COMPONENT, callback);
    return () => Event.unsubscribe(EVENT.INPUT_COMPONENT, callback);
  }, [lisa, setDataSource]);

  const handleClose = () => {
    setVisible(false);
  };

  const handleChange = (value) => {
    const { type } = dataSource;
    let next = value;

    if (type === 'calendar') {
      const [from, to] = value || [];
      next = [from ? dateFormat(from, CALENDAR_PROPS) : undefined, to ? dateFormat(to, CALENDAR_PROPS) : undefined];
      setValid(!!from && !!to);
    }

    setValue(next);
  };

  const handleSubmit = () => {
    const { intent, response: { form = {}, value: context = {} } = {} } = lisa;
    const { name, required, onSubmit, ...component } = dataSource;

    if (onSubmit) {
      const text = is.calendar
        ? getVerboseDate(value, locale)
        : is.occupation
        ? getVerboseOccupation({ store, translate, value })
        : is.list
        ? getVerboseList(value, component)
        : value;
      onSubmit({ text, value });
    }
    onValue({
      intent,
      context,
      form: { ...form, [name]: is.text && !required && !value ? ' ' : value },
    });
    setVisible(false);
  };

  const { required, text, type, ...component } = dataSource || {};
  const is = {
    calendar: type === 'calendar',
    list: type === 'list',
    occupation: type === 'occupation',
    text: INPUT_TEXT_TYPES.includes(type),
  };

  return (
    <View {...others} className={[style.container, visible && style.visible]}>
      <View className={[style.content, visible && style.visible]}>
        <View row className={style.header}>
          <Avatar small />

          <Text bold wide>
            {text}
          </Text>

          <Button squared transparent onPress={handleClose}>
            <Icon headline value={ICON.CLOSE} />
          </Button>
        </View>

        {type &&
          React.createElement(is.calendar ? Calendar : is.occupation ? Occupation : is.list ? List : InputText, {
            ...component,
            ...(type === 'calendar' ? CALENDAR_PROPS : {}),
            locale,
            value,
            onChange: handleChange,
            onError: () => setValid(false),
            onValid: () => setValid(true),
            onSubmit: undefined,
            className: styles(style.component, is.text && style.inputText, component.className),
          })}

        <View className={style.footer}>
          <Button disabled={disabled || (required && !valid)} large wide onPress={handleSubmit}>
            {translate(L10N.ACTION_APPLY)}
          </Button>
        </View>
      </View>
    </View>
  );
};

InputRich.propTypes = {
  disabled: PropTypes.bool,
  onValue: PropTypes.func,
};

export { InputRich };
