.card .offset {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

.card .row {
  align-items: center;
  display: flex;
  flex-direction: var(--mirai-ui-row-direction);
  gap: var(--mirai-ui-space-XXS);
}

.card .spaceBetween {
  align-items: center;
  justify-content: space-between;
}

.card .slider {
  background-color: var(--mirai-ui-content-border);
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  height: 208px;
  position: relative;
  width: 100%;
}

.card > *:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.card > *:nth-last-child(2) {
  flex: 1;
}

.card .summary {
  margin-bottom: var(--mirai-ui-space-XXS);
}

.card .summary .content {
  align-items: flex-end;
  justify-content: space-between;
}

.card .summary .price {
  margin: var(--mirai-ui-space-XXS) 0;
}

.preview {
  position: relative;
}

.preview .tags {
  gap: var(--mirai-ui-space-XS);
  left: 0;
  margin: var(--mirai-ui-space-S);
  position: absolute;
  top: 0;
}

.preview .tags .tag,
.summary .tag {
  align-items: center;
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-border-radius);
  color: var(--mirai-ui-base);
  display: flex;
  gap: var(--mirai-ui-space-XXS);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
}

.summary .tag {
  display: inline-flex;
  gap: calc(var(--mirai-ui-space-XXS) / 2);
  padding: 0 var(--mirai-ui-space-XXS);
  margin-right: var(--mirai-ui-space-XXS);
  width: fit-content;
}

/* S */
@media only screen and (max-width: 480px) {
  .card .gap {
    gap: var(--mirai-ui-space-XXS);
  }

  .card .offset {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  .card > .offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-S);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .card .gap {
    gap: var(--mirai-ui-space-XS);
  }

  .card .offset {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .card > .offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-M);
  }
}
