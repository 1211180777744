import { IS_JEST } from '../components/helpers';
import { TRACKING } from '../Core.constants';

const MONALISA_ID = '100379008';

export const getPerformance = ({ tracking: { trackingChain, trackingHotels, ...others } = {} } = {}, hotelId) => {
  if (!IS_JEST && process.env.NODE_ENV !== 'production' && hotelId !== MONALISA_ID) return;

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  let tracking = others;

  if (trackingChain && trackingHotels) {
    tracking = { ...trackingChain };
    const hotelTracking = hotelId && trackingHotels.find(({ idTokenProvider: id } = {}) => id === parseInt(hotelId));

    hotelTracking &&
      Object.keys(tracking).forEach((key) => {
        tracking[key] = [...tracking[key], ...hotelTracking[key]];
      });
  }

  // ! -- TODO: Choose which one will load gtag
  return Object.keys(tracking).length > 0
    ? Object.keys(tracking)
        .filter((key) => !!TRACKING[key] && tracking[key].length > 0)
        .map((key) => ({
          type: TRACKING[key],
          instances: tracking[key].map(({ configuration = {}, id }) => ({
            ...(configuration !== null ? configuration : {}),
            id,
          })),
        }))
    : undefined;
};
