.face {
  width: 64px;
  height: 64px;
  background-color: var(--mirai-chat-avatar-background);
  border-radius: var(--mirai-chat-avatar-border-radius);
  position: relative;
}

.face.small {
  min-height: 36px;
  height: 36px;
  min-width: 36px;
  width: 36px;
}

.eyes {
  position: absolute;
  bottom: 27.5%;
  left: 0;
  right: 0;
  height: 17.5%;
  transition: transform 40ms;
}

.eye {
  animation: blink 8s infinite;
  background-color: var(--mirai-chat-avatar-background);
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 17.5%;
}

.face:not(.small) .eye {
  box-shadow: 0 0 0 6px var(--mirai-chat-avatar-color);
}

.face.small .eye {
  box-shadow: 0 0 0 3px var(--mirai-chat-avatar-color);
}

.eye.left {
  left: 27.5%;
}

.eye.right {
  right: 27.5%;
}

@keyframes blink {
  0%,
  100% {
    transform: scaleY(100%);
  }
  /* double-blink */
  31% {
    transform: scaleY(100%);
  }
  32% {
    transform: scaleY(15%);
  }
  33% {
    transform: scaleY(100%);
  }
  34% {
    transform: scaleY(15%);
  }
  35% {
    transform: scaleY(100%);
  }
  /* single-blink  */
  61% {
    transform: scaleY(100%);
  }
  62% {
    transform: scaleY(15%);
  }
  63% {
    transform: scaleY(100%);
  }
}
