import { FEATURES } from '../Rates.constants';

export const getFeatures = (ratesFeatures = {}, urlParams = {}, { isDesktop } = {}, multiRoom = false) => {
  const urlFeatures = urlParams.features ? urlParams.features.split(',') : [];
  let features = {};

  Object.values(FEATURES).forEach((feature) => {
    features[feature] = urlFeatures.includes(feature) || ratesFeatures[feature] || false;
  });

  if (features.columnMode) features.columnMode = isDesktop && features.columnMode;
  if (features.rowMode) features.rowMode = isDesktop && features.rowMode;
  if (features.showPreviewPrice) features.showPreviewPrice = !multiRoom && features.showPreviewPrice;

  return features;
};
