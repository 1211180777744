import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, Pressable, Text, Radio, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Option.module.css';

const Option = ({
  caption,
  checked = false,
  children,
  disabled,
  highlight,
  icon,
  inline = false,
  outlined = false,
  title,
  value,
  onPress,
  ...others
}) => {
  const { isMobile } = useDevice();
  const { currencyFormat } = useLocale();
  const { value: { currency } = {} } = useStore();

  return (
    <Pressable
      disabled={disabled}
      preventDefault={false}
      onPress={onPress}
      className={styles(
        style.option,
        checked && style.checked,
        disabled && style.disabled,
        outlined && style.outlined,
        outlined && inline && style.inline,
        others.className,
      )}
    >
      {
        onPress && !outlined && <Radio checked={checked} disabled={disabled} /> //! TODO: with name prop don't work
      }

      <View className={[style.texts, highlight && style.highlight]}>
        {icon && <Icon headline level={2} value={icon} />}

        <View row className={style.content}>
          {typeof title === 'string' ? (
            <Text action={!isMobile} medium={outlined || highlight} small={isMobile} className={style.title}>
              {`${title.charAt(0).toUpperCase()}${title.slice(1).toLowerCase()}`}
            </Text>
          ) : (
            title
          )}
          {children}
        </View>

        {typeof caption === 'string' ? (
          <Text light small={!isMobile} tiny={isMobile} className={style.caption}>
            {caption}
          </Text>
        ) : (
          caption
        )}
      </View>
      {onPress && value !== undefined && value !== 0 && (
        <Text action={!isMobile} small={isMobile} light={value === 0}>
          {`${value >= 0 ? '+' : '-'} ${currencyFormat({ currency, value: Math.abs(value) })}`}
        </Text>
      )}
    </Pressable>
  );
};

Option.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  highlight: PropTypes.bool,
  icon: PropTypes.any,
  inline: PropTypes.bool,
  outlined: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.number,
  onPress: PropTypes.func,
};

export { Option };
