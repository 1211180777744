import { Event, useStore } from '@mirai/data-sources';
import { CURRENCIES, useLocale } from '@mirai/locale';
import { Icon, Select, styles, Text, Tooltip, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { LANGUAGES } from './SelectEnvironment.constants';
import { L10N } from './SelectEnvironment.l10n';
import * as style from './SelectEnvironment.module.css';
import { getLocale } from '../../../helpers';
import { EVENT, ICON, LOCALES, replaceUrlParams } from '../../helpers';

const SelectEnvironment = ({ compacted = false, name, metrics = 'ENVIRONMENT', small = false, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: { currency, language, locale, locales = [] },
  } = useStore();

  const is = {
    currency: name === 'currency',
    locale: name === 'locale',
  };

  const handleChange = (next) => {
    if (is.currency) {
      replaceUrlParams({ currency: next });
      Event.publish(EVENT.INTENT_CURRENCY, { currency: next });
    } else if (is.locale) {
      const [lang] = next.split('-');
      const { locale: nextLocale } = getLocale(undefined, { lang });

      replaceUrlParams({ lang, locale: nextLocale });
      Event.publish(EVENT.INTENT_LOCALE, { locale: next });
    }

    Event.publish(EVENT.METRICS, { id: `${metrics}:${name}`, value: next });
  };

  const { className, testId } = others;
  const tooltipText = translate(is.currency ? L10N.LABEL_SELECT_CURRENCY : L10N.LABEL_SELECT_LANGUAGE);

  return (
    <Tooltip text={!isMobile ? tooltipText : undefined}>
      <View row className={styles(style.selectEnvironment, small && style.small, className)}>
        <View row className={style.caption}>
          <Icon headline level={2} value={is.currency ? ICON.CURRENCY : ICON.LANGUAGE} />
          {!compacted && (
            <Text action={!isMobile} medium small={isMobile}>
              {is.currency ? currency : LANGUAGES[LOCALES.findIndex((locale) => locale.includes(language))]}
            </Text>
          )}
        </View>
        <Select
          name={name}
          options={
            is.currency
              ? CURRENCIES.map(
                  (item) =>
                    `${item}${
                      Intl.DisplayNames ? ` - ${new Intl.DisplayNames(locale, { type: 'currency' }).of(item)}` : ''
                    }`,
                )
              : locales.length > 0
              ? LANGUAGES.filter((_, index) => locales.includes(LOCALES[index]))
              : LANGUAGES
          }
          value={is.currency ? currency : locale}
          values={
            is.currency
              ? CURRENCIES
              : locales.length > 0
              ? LOCALES.filter((locale) => locales.includes(locale))
              : LOCALES
          }
          onChange={handleChange}
          className={style.input}
          testId={testId}
          aria-label={tooltipText}
        />
      </View>
    </Tooltip>
  );
};

SelectEnvironment.displayName = 'Mirai:Core:SelectEnvironment';

SelectEnvironment.propTypes = {
  compacted: PropTypes.bool,
  metrics: PropTypes.string,
  name: PropTypes.oneOf(['currency', 'locale']).isRequired,
  small: PropTypes.bool,
};

export { SelectEnvironment };
