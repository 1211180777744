import { Event } from '@mirai/data-sources';
import { Text } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { EVENT } from '../../../Chat.constants';
import { Card } from '../components';
import * as style from '../Message.module.css';

const Unknown = ({ classifier, disabled, response: { text, requirements } = {}, status }) => {
  const [inputResponse, setInputResponse] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatchRequirement(), [requirements]);

  const dispatchRequirement = () => {
    requirements && Event.publish(EVENT.INPUT_COMPONENT, { onSubmit: setInputResponse });
  };

  const [{ text: requirementText } = {}] = Object.values(requirements || {});

  return text ? (
    <>
      <Text action className={style.text}>
        {text}
      </Text>

      {requirementText && (
        <Card
          disabled={disabled}
          option={false}
          text={inputResponse?.text || requirementText}
          onPress={dispatchRequirement}
        />
      )}
    </>
  ) : (
    <Text tag="pre" markdown={false} small className={style.text}>
      {JSON.stringify(classifier || status, null, 2)}
    </Text>
  );
};

Unknown.propTypes = {
  classifier: PropTypes.shape({
    classifications: PropTypes.arrayOf(PropTypes.shape({})),
    confidence: PropTypes.number,
    intent: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  response: PropTypes.shape({
    text: PropTypes.string,
  }),
  status: PropTypes.shape({}),
};

export { Unknown };
