import { FREE_CANCELLATION } from '../../../Rates.constants';
import { MAX_AMENITIES, MAX_BOARDS, MIN_BOARDS } from '../Filters.constants';

export const getAvailableFilters = (items = [], { priceFactor = 1 } = {}) => {
  const filters = {
    amenities: [],
    bedTypes: [],
    boards: [],
    freeCancellation: undefined,
    prices: [],
    sizes: [],
    sizeUnit: undefined,
  };

  // ! TODO: Should order by price asc?
  items.forEach(({ amenities = [], bedTypes = [], boards = [], cancellations = [], size: rawSize = '' } = {}) => {
    amenities.forEach(({ highlight, id, text } = {}) => {
      if (!filters.amenities.find((item = {}) => item.id === id)) filters.amenities.push({ highlight, id, text });
    });

    bedTypes.forEach((id = []) => {
      if (!filters.bedTypes.find((item) => item === id)) filters.bedTypes.push(id);
    });

    boards.forEach(({ id, name: text, type, price } = {}) => {
      if (!filters.boards.find((item = {}) => item.type === type)) filters.boards.push({ id, text, type });

      filters.prices.push(price / priceFactor);
    });

    const [firstFreeCancellation] = !filters.freeCancellation
      ? cancellations.filter(({ id }) => id.toLowerCase().includes(FREE_CANCELLATION))
      : [];

    if (firstFreeCancellation) {
      const { id, name: text, title: caption } = firstFreeCancellation;
      filters.freeCancellation = { id, text, caption };
    }

    const [size, unit] = rawSize.split(' ');
    if (size.length > 0 && !filters.sizes.find((item = {}) => item.size === parseInt(size))) {
      if (unit && !filters.sizeUnit) filters.sizeUnit = unit;
      filters.sizes.push(parseInt(size));
    }
  });

  // Clean filters
  if (!filters.amenities.some(({ highlight }) => highlight)) {
    filters.amenities.forEach((filter = {}, index) => {
      filters.amenities[index] = { ...filter, highlight: index < MAX_AMENITIES };
    });
  }

  filters.bedTypes = filters.bedTypes.sort((a, b) => a - b);

  if (filters.boards.length < MIN_BOARDS) filters.boards = [];
  else if (filters.boards.length > MAX_BOARDS) filters.boards = filters.boards.slice(0, MAX_BOARDS);

  return filters;
};
