import { toUrlParams } from './toUrlParams';
import { DEFAULT_HSRI, ENDPOINT } from '../ButtonPayment.constants';

export const getUrlBooking = ({
  code,
  environment,
  forwarder: { booking: url = `${process.env.SERVICE_BOOKING}/${ENDPOINT.BOOKING}` } = {},
  id,
  locale,
  reservationCode,
  tracking,
  url: home,
  urlParams: { hsri = DEFAULT_HSRI, miraiId, type, variant } = {},
}) => {
  if (!code || !id || !locale || !reservationCode) return;

  return `${url}${toUrlParams({
    code,
    env: environment,
    hsri,
    home,
    idHotel: id,
    lang: locale.split('-')[0],
    miraiId,
    reservationCode,
    type,
    tracking,
    variant,
    version: 2,
  })}`;
};
