import { LocalAdapter, Storage } from '@mirai/data-sources';
import { parseDate, useLocale } from '@mirai/locale';
import { ServiceUser } from '@mirai/services';
import { Action, Button, Notification, Text, Tooltip, useDevice, View } from '@mirai/ui';
import React, { useEffect, useState } from 'react';

import { CACHE_LIMIT, STORAGE_KEY } from './Bookings.constants';
import { L10N } from './Bookings.l10n';
import * as style from './Bookings.module.css';
import { Search } from './Bookings.Search';
import { groupDatasource } from './helpers';
import { Card, Cards } from '../../../__shared__';
import { getCalendarCaption } from '../../../helpers';

const Bookings = () => {
  const { isMobile } = useDevice();
  const { currencyFormat, locale, translate } = useLocale();

  const [dataSource, setDataSource] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    (async () => {
      // * TODO: We should cache as long as the service responds so slow
      const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
      const cache = storage.get(STORAGE_KEY);
      if (cache) setDataSource(cache);

      const response = await ServiceUser.bookings().catch(() => {});
      storage.set(STORAGE_KEY, response);

      setDataSource(response || []);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleManage = (url) => {
    document.location = url;
  };

  const bookings = groupDatasource(dataSource, search);
  const caption = { action: !isMobile, small: isMobile };
  const has = {
    bookings: Object.keys(bookings || {}).length > 0 || search,
    dataSource: dataSource !== undefined,
  };

  return (
    <View className={style.container}>
      <View row className={style.title}>
        <Text bold headline level={2}>
          {translate(L10N.ACTION_MY_BOOKINGS)}
        </Text>
        {has.bookings && <Search value={search} onChange={setSearch} />}
      </View>
      {has.bookings ? (
        Object.entries(bookings || {}).map(([id, items = []], index) => (
          <>
            <Text key={index} bold headline={!isMobile}>
              {translate({ id }, { num: items.length })}
            </Text>

            <Cards>
              {items.map(
                ({
                  booking,
                  checkIn,
                  checkOut,
                  currency,
                  hotel,
                  image,
                  location: { city } = {},
                  price: { base, total, text: taxesText } = {},
                  status,
                  url: { manage, site } = {},
                } = {}) => {
                  const is = { cancelled: status === 'CANCELLED', past: id === L10N.LABEL_PAST_BOOKINGS.id };

                  return (
                    <Card key={booking} className={style.card}>
                      {!is.past && <View className={style.image} style={{ backgroundImage: `url(${image})` }} />}

                      <View className={style.offset}>
                        <Text bold headline={!isMobile} level={3}>
                          {hotel}
                        </Text>
                        {checkIn && checkOut && (
                          <Text {...caption}>
                            {getCalendarCaption(
                              [parseDate(checkIn), parseDate(checkOut)],
                              translate,
                              undefined,
                              locale,
                            )}
                          </Text>
                        )}
                        {city && <Text {...caption}>{city}</Text>}

                        <Action {...caption} href={site}>
                          {translate(L10N.ACTION_VIEW_HOTEL)}
                        </Action>
                      </View>

                      <View row className={[style.offset, style.spaceBetween]}>
                        <View>
                          <Text bold headline level={isMobile || total >= 10000 ? 3 : 2}>
                            {currencyFormat({ currency, symbol: true, value: total })}
                          </Text>

                          <Tooltip
                            text={`${translate(L10N.LABEL_BASE_PRICE_VALUE, {
                              value: currencyFormat({ currency, symbol: true, value: base }),
                            })} ${taxesText}`}
                          >
                            <Text accent action={!isMobile} medium small={isMobile}>
                              {translate(L10N.LABEL_PRICE_DETAILS)}
                            </Text>
                          </Tooltip>
                        </View>

                        {!is.cancelled && !is.past && (
                          <Button onPress={() => handleManage(manage)}>{translate(L10N.ACTION_MANAGE_BOOKING)}</Button>
                        )}

                        {is.cancelled && <Button disabled>{translate(L10N.LABEL_CANCELLED)}</Button>}
                      </View>
                    </Card>
                  );
                },
              )}
            </Cards>
          </>
        ))
      ) : has.dataSource ? (
        <Notification info large className={style.notification}>
          {translate(L10N.LABEL_NO_BOOKINGS)}
        </Notification>
      ) : null}
    </View>
  );
};

Bookings.displayName = 'Mirai:Core:Profile:Bookings';

Bookings.propTypes = {};

export { Bookings };
