import { parseItems } from './helpers';

export const checkoutForm = ({
  instances = [],
  response: { tracking = {} } = {},
  store: { finder = {} } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { hotelInfo = {}, reservedExtras = {}, reservedRooms = [] } = tracking;
  const { promocode = '' } = finder;

  const value = {
    coupon: promocode,
    items: parseItems({ finder, hotelInfo, reservedExtras, reservedRooms }),
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
  };

  window.gtag('event', 'add_payment_info', value);

  return value;
};
