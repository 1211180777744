import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, InputSelect, Notification, Slider, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { getCaption } from './helpers';
import { L10N } from './ModalExtras.l10n';
import * as style from './ModalExtras.module.css';
import { getGuestLabel } from '../../../helpers';
import { EVENT } from '../../../helpers';

const Extras = ({ extras: propExtras = [], onChange = () => {} }) => {
  const { isMobile, isTablet, width } = useDevice();
  const { currencyFormat, translate } = useLocale();
  const {
    value: { currency, finder = {}, hotel: { accommodationType: hotelAccommodationType, variants = {} } = {} } = {},
  } = useStore();

  const [extras, setExtras] = useState(propExtras);

  const handleChange = ({ id, field, value } = {}) => {
    // ! Why parse Number()? should come from the service.
    setExtras(extras.map((extra) => ({ ...extra, [field]: extra.id === id ? parseInt(value) : extra[field] })));
  };

  const handleSelect = (id) => {
    const next = extras.map(({ selected = false, ...extra }) => ({
      ...extra,
      selected: extra.id === id ? !selected : selected,
    }));

    setExtras(next);

    onChange(next.filter(({ selected }) => !!selected));

    Event.publish(EVENT.METRICS, {
      id: `RATES:EXTRAS:${
        next.filter(({ selected }) => selected).length > extras.filter(({ selected }) => selected).length
          ? 'ADD'
          : 'REMOVE'
      }`,
    });
  };

  const hasSelected = extras.filter((item) => !!item.selected).length > 0;
  const { place: { id: [id] = [] } = {} } = finder;
  const { accommodationType = hotelAccommodationType } = variants[id] || {};
  const standaloneCard = extras.length === 1;

  const spaceM = 16;
  const cardWidth = isMobile ? width - spaceM * 4 : isTablet ? width / 2 - spaceM * 2 : 320;

  return (
    <Slider visibleItems={isMobile ? 1 : isTablet ? 2 : 2.5} width={cardWidth} className={style.slider}>
      {extras.map(
        ({
          accommodationThreshold = 0,
          amount = 0,
          conditions,
          frequency,
          guestThreshold = 0,
          guestType,
          id,
          image,
          included = false,
          inputGuests = false,
          inputNights = false,
          nights = 0,
          selected = false,
          text,
          title,
          type,
          value = {},
          valueAmount = amount,
          valueNights = nights,
        } = {}) => {
          const { price: total } = value[valueAmount][valueNights] || {};

          return (
            <View
              key={id}
              wide
              className={styles(
                style.card,
                standaloneCard && style.standalone,
                hasSelected && selected ? style.selected : undefined,
              )}
              style={{ maxWidth: cardWidth, minWidth: cardWidth }}
            >
              <View className={style.image} style={{ backgroundImage: `url(${image})` }} />

              <View wide className={[style.gap, style.offset, style.anchor]}>
                <Text bold>{title}</Text>
                {text && <Text small>{text}</Text>}

                {accommodationThreshold > 0 && (
                  <Notification inline small warning>
                    {translate(L10N.LABEL_APPLIES_SELECTED_ROOMS, { value: accommodationThreshold })}
                  </Notification>
                )}

                {guestThreshold > 0 && (
                  <Notification inline small warning>
                    {translate(L10N.LABEL_APPLIES_INDIVIDUALS_RESERVATION, { value: guestThreshold })}
                  </Notification>
                )}

                {conditions && (
                  <Text light small={!isMobile} tiny={isMobile}>
                    {conditions}
                  </Text>
                )}

                {(inputGuests || inputNights) && (
                  <View row className={styles(style.gap, style.inputs)}>
                    {inputGuests && (
                      <InputSelect
                        capitalize
                        name="guests"
                        label={getGuestLabel({ plural: true, type: guestType }, translate)}
                        options={Array.from({ length: amount }).map((_, index) => index + 1)}
                        value={valueAmount?.toString()}
                        onChange={(value) => handleChange({ id, field: 'valueAmount', value })}
                      />
                    )}
                    {inputNights && (
                      <InputSelect
                        name="nights"
                        label={translate(L10N.LABEL_NIGHTS)}
                        options={Array.from({ length: nights }).map((_, index) => index + 1)}
                        value={valueNights?.toString()}
                        onChange={(value) => handleChange({ id, field: 'valueNights', value })}
                      />
                    )}
                  </View>
                )}
              </View>

              <View row className={[style.offset, style.spaceBetween]}>
                <View>
                  <Text bold headline>
                    {total
                      ? currencyFormat({ currency, maximumFractionDigits: 0, value: total })
                      : translate(L10N.LABEL_FREE)}
                  </Text>
                  <Text light small>
                    {getCaption(
                      {
                        accommodationType,
                        amount,
                        frequency,
                        guestType,
                        inputGuests,
                        type,
                        valueAmount,
                        valueNights,
                      },
                      translate,
                    )}
                  </Text>
                </View>

                <Button
                  small
                  secondary={!selected && !included}
                  onPress={!included ? () => handleSelect(id) : undefined}
                >
                  {translate(included ? L10N.LABEL_INCLUDED : selected ? L10N.ACTION_REMOVE : L10N.ACTION_ADD)}
                </Button>
              </View>
            </View>
          );
        },
      )}
    </Slider>
  );
};

Extras.propTypes = {
  extras: PropTypes.any,
  onChange: PropTypes.func,
};

Extras.displayName = 'Mirai:Core:Rates:Extras';

export { Extras };
