import { Event, useStore } from '@mirai/data-sources';
import { parseDate, useLocale } from '@mirai/locale';
import { ServiceFeatures } from '@mirai/services';
import { Action, Icon, Pressable, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { Amenities, Countdown, Features, Slider, TooltipDates } from './components';
import { L10N } from './Item.l10n';
import * as style from './Item.module.css';
import { EVENT, ICON } from '../../../helpers';
import { FEATURES } from '../../Rates.constants';

const GAP_TABLET = 7;
const GAP_NOT_TABLET = 1;
let memoizedWidth = 374;

const Preview = ({
  availability = 0,
  availableBoards = [],
  availableRates = [],
  boards = [],
  cancellations = [],
  features: {
    columnMode = false,
    hideStandaloneBoard = false,
    highlightStandaloneOption = false,
    rowMode = false,
    ...features
  } = {},
  images = [],
  isSoldOut = false,
  media,
  name: title,
  promocodeForAll,
  rates = [],
  selectedBoard = {},
  onCancellationInfo,
  onMoreInfo,
  onOfferInfo = () => {},
  onViewUrl = () => {},
  ...others
}) => {
  const { height, isMobile, isPortrait, isTablet, width } = useDevice();
  const { translate } = useLocale();
  const ref = useRef(null);
  const {
    value: {
      finder: { place: { id: [hotelId] = [], isHotel } = {}, promocode } = {},
      hotel: { warningAvailability = 9999, ...hotel } = {},
      language,
    } = {},
  } = useStore();

  useEffect(() => {
    const { clientWidth } = ref.current || {};
    if (clientWidth) memoizedWidth = clientWidth - (isTablet ? GAP_TABLET : GAP_NOT_TABLET);
  }, [isTablet, width]);

  const handleSliderCounter = () => {
    onMoreInfo && onMoreInfo();
    Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:SLIDER:COUNTER' });
  };

  const countdownRates = availableRates.filter(({ countdown } = {}) => countdown > 0);
  const [firstRate = {}] = availableRates;

  const [uniqueRate] =
    cancellations.length === availableRates.length &&
    (!countdownRates.length ||
      availableRates.filter(({ countdown } = {}) => countdown > 0).length === availableRates.length) &&
    availableRates.filter(
      ({ description, included, name } = {}) =>
        firstRate.description === description &&
        firstRate.name === name &&
        JSON.stringify(firstRate.included) === JSON.stringify(included),
    ).length === availableRates.length &&
    !highlightStandaloneOption
      ? rates
      : [];

  const [uniqueBoard] =
    boards.length === 1 &&
    availableBoards.filter(({ name } = {}) => name === availableBoards[0].name).length === availableBoards.length
      ? boards
      : [];

  const caption = { capitalize: true, action: !isMobile, small: isMobile };
  const { offerDates = [], variants = [] } = selectedBoard;
  const hasTwin = others.id && ServiceFeatures.get(FEATURES.TWIN, isHotel ? hotelId : hotel.id);

  return (
    <View ref={ref} className={style.preview}>
      <Slider
        height={columnMode || rowMode ? height / (isPortrait ? 5 : 3) : 208}
        images={images.map(({ large }) => large)}
        media={media}
        title={title}
        width={memoizedWidth}
        onCounter={!isMobile ? handleSliderCounter : undefined}
        onViewUrl={onViewUrl}
        className={style.slider}
      />

      <View row className={style.tags}>
        {promocode && promocodeForAll && (
          <Text bold small>
            <Icon action value={ICON.DISCOUNT} />
            {promocode.toUpperCase()}
          </Text>
        )}
      </View>

      <View row className={[style.offset, style.spaceBetween, style.details]}>
        <Features {...{ features, ...others }} />

        {isSoldOut === false && availability <= warningAvailability && (
          <Text bold error small>
            {translate(L10N.LABEL_REMAINING_ITEMS, { amount: availability })}
          </Text>
        )}
      </View>

      <View className={[style.offset, style.gap]} style={{ flex: 1 }}>
        <Text bold brand headline={!isMobile} level={3}>
          {title}
        </Text>

        {hasTwin && (
          <Action
            action={!isMobile}
            small={isMobile}
            onPress={() => {
              Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:TWIN' });
              onViewUrl({
                title: others.title,
                url: `https://twin.mirai.com/rates/index.html?id=${isHotel ? hotelId : hotel.id}&room=${
                  others.id
                }&lang=${language}`,
              });
            }}
          >
            <Text small={!isMobile} tiny={isMobile} bold className={style.tag3D}>
              3D
            </Text>
            {translate(L10N.LABEL_MIRAI_TWIN)}
          </Action>
        )}

        {!isMobile && <Amenities highlight preview {...others} />}

        {uniqueRate && (
          <>
            {uniqueRate.description && (
              <Text action={!isMobile} small={isMobile} className={[style.optionText, style.standalone]}>
                {uniqueRate.name}
                <Pressable onPress={() => onOfferInfo({ ...uniqueRate, availableBoards })}>
                  <Icon paragraph value={ICON.INFO} />
                </Pressable>
              </Text>
            )}
            {uniqueRate.countdown && <Countdown offer timestamp={uniqueRate.countdown} />}
            {uniqueRate.included.map((item, index) => (
              <View key={index} row className={style.row}>
                <Icon value={ICON.AMENITY} />
                <Text {...caption}>{item}</Text>
              </View>
            ))}
          </>
        )}

        {uniqueBoard && !hideStandaloneBoard && !highlightStandaloneOption && (
          <View row className={style.row}>
            <Text {...caption}>{boards[0].name}</Text>
            {variants.length > 0 && (
              <TooltipDates
                captions={variants.map(({ name } = {}) => name)}
                values={variants.map(({ value } = {}) => parseDate(value))}
              />
            )}
          </View>
        )}

        {cancellations.length === 1 && !highlightStandaloneOption && (
          <View>
            <View row>
              <Pressable
                onPress={() => onCancellationInfo({ ...selectedBoard, cancellation: cancellations[0] })}
                className={style.row}
              >
                <Text {...caption}>{cancellations[0].name}</Text>
                <Icon paragraph value={ICON.INFO} />
              </Pressable>
            </View>
            {cancellations[0].caption && (
              <Text light small>
                {cancellations[0].caption}
              </Text>
            )}
          </View>
        )}

        {uniqueRate?.restrictions.map(({ variableOffer, value } = {}, index) => (
          <View key={index} row className={style.row}>
            <Icon warning value={ICON.WARNING} />
            <Text warning {...caption}>
              {value}
            </Text>
            {variableOffer && offerDates.length > 0 && (
              <TooltipDates
                captions={offerDates.map(() => translate(L10N.LABEL_INCLUDED))}
                values={offerDates.map((date) => parseDate(date))}
                warning
              />
            )}
          </View>
        ))}

        <View row>
          <Action small={isMobile} onPress={onMoreInfo}>
            {translate(L10N.ACTION_MORE_INFO)}
          </Action>
        </View>
      </View>
    </View>
  );
};

Preview.displayName = 'Mirai:Core:Rates:Item:Preview';

Preview.propTypes = {
  availability: PropTypes.number,
  availableBoards: PropTypes.arrayOf(PropTypes.any),
  availableRates: PropTypes.arrayOf(PropTypes.any),
  boards: PropTypes.arrayOf(PropTypes.any),
  cancellations: PropTypes.arrayOf(PropTypes.any),
  features: PropTypes.shape({
    columnMode: PropTypes.bool,
    hideStandaloneBoard: PropTypes.bool,
    highlightStandaloneOption: PropTypes.bool,
    rowMode: PropTypes.bool,
  }),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      large: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
  ),
  isSoldOut: PropTypes.bool,
  media: PropTypes.shape({
    url3D: PropTypes.string,
    urlVideo: PropTypes.string,
  }),
  name: PropTypes.string,
  promocodeForAll: PropTypes.bool,
  rates: PropTypes.arrayOf(PropTypes.any),
  selectedBoard: PropTypes.shape({}),
  onCancellationInfo: PropTypes.func,
  onMoreInfo: PropTypes.func,
  onOfferInfo: PropTypes.func,
  onViewUrl: PropTypes.func,
};

export { Preview };
