import { styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import * as style from './Avatar.module.css';

const Avatar = ({ disabled, small, ...others }) => {
  const el = useRef();

  useEffect(() => {
    const handleMouseMove = ({ clientX = 0, clientY = 0 }) => {
      const { current: eyes } = el;
      let X = 0;
      let Y = 0;

      if (eyes && !disabled) {
        const { height, left, top, width } = eyes.getBoundingClientRect();
        const centerX = left + width / 2;
        const centerY = top + height / 2;
        const angle = Math.atan2(clientY - centerY, clientX - centerX);
        const sensitivity = small ? 2 : 4;

        X = sensitivity * Math.cos(angle);
        Y = sensitivity * Math.sin(angle);
      }

      eyes.style.transform = `translate(${X}px, ${Y}px)`;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [disabled, el, small]);

  return (
    <View {...others} className={styles(style.face, small && style.small, others.className)}>
      <View ref={el} className={style.eyes}>
        <View className={[style.eye, style.left]} />
        <View className={[style.eye, style.right]} />
      </View>
    </View>
  );
};

Avatar.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
};

export { Avatar };
