import { useDevice } from '@mirai/ui';
import { View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../__shared__';

export default () => {
  const { isDesktop } = useDevice();

  const props = { color: 'base' };

  return (
    <View wide style={{ alignItems: 'center', gap: '2rem' }}>
      <View
        row
        wide
        style={{
          alignItems: 'start',
          alignSelf: 'center',
          gap: '1rem',
          maxWidth: 'var(--mirai-ui-breakpoint-content)',
        }}
      >
        <View style={{ flex: 1, gap: '1rem' }}>
          <Skeleton {...props} style={{ height: '3rem' }} />
          <Skeleton {...props} style={{ height: '7rem' }} />
          <Skeleton {...props} style={{ height: '12rem' }} />
          <Skeleton {...props} style={{ height: '10rem' }} />
          <Skeleton {...props} style={{ height: '28rem' }} />
          <Skeleton {...props} style={{ height: '28rem' }} />
        </View>

        {isDesktop && (
          <View
            style={{
              flex: 1,
              gap: '1rem',
              maxWidth: 'calc(var(--mirai-ui-breakpoint-S) - var(--mirai-ui-space-M) * 2)',
            }}
          >
            <Skeleton {...props} style={{ height: '16rem' }} />
            <Skeleton {...props} style={{ height: '8rem' }} />
          </View>
        )}
      </View>
    </View>
  );
};
