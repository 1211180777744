import { styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Card.module.css';

const Cards = ({ ...others }) => <View {...others} row className={styles(style.cards, others.className)} />;

Cards.displayName = 'Mirai:Core:Cards';

Cards.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  rowMode: PropTypes.bool,
};

export { Cards };
