const ACCOMMODATION_TYPE = {
  DEFAULT: 0,
  ROOM: 1,
  APARTMENT: 2,
  PITCH: 3,
  ACCOMMODATION: 4,
  VILLA: 5,
};

const AUTH_TYPE = {
  MIRAI: 1,
  PESTANA: 3,
};

const CALENDAR_FORMAT = 'DD/MM/YYYY';

const COOKIE = {
  CART: 'CART_COOKIE',
  PESTANA: '_jwt',
  VALUES: 'BOOKING_VALUES',
};

const ERROR = {
  NOT_AVAILABLE: 4,
  PARAMETER_ERROR: 3,
  UNKNOWN: 1,
};

const EVENT = {
  CORE_READY: 'core:ready',
};

const TYPE = {
  CHAIN: 'chain',
  HOTEL: 'hotel',
  GUEST: 1,
  ADULT: 2,
  CHILD: 3,
};

const TYPE_COMMISSION = {
  [TYPE.HOTEL]: '02040',
  [TYPE.CHAIN]: '02080',
};

const FINDER_STORAGE_KEY = 'MIRAI:FINDER';

const MIN_STAY_TYPE = {
  CHECK_IN: 1,
  STAY: 2,
};

const PERFORMANCE = {
  ANALYTICS: 1,
  GOOGLE_ADS: 2,
  GTM: 3,
  MIRAI: 4,
  FACEBOOK: 5,
  THE_HOTEL_NETWORK: 6,
  AFFILIRED: 7,
  BING: 8,
  COMPARE: 9,
};

const TAX_DEFAULT = 1;

const TAX_FREE = 2;

const TAX_PARTIAL = 3;

const TAXES = [TAX_DEFAULT, TAX_FREE, TAX_PARTIAL];

const TAXES_TYPE = {
  ALL_EXCLUDED: 1,
  ALL_INCLUDED: 2,
  FEES_INCLUDED: 3,
  FEES_EXCLUDED: 4,
  FEES_INCLUDED_TAXES_EXCLUDED: 5,
  TAXES_INCLUDED: 6,
  TAXES_EXCLUDED: 7,
  TAXES_INCLUDED_FEES_EXCLUDED: 8,
};

const TRACKING = {
  adwordsTracking: PERFORMANCE.GOOGLE_ADS,
  affiliredTracking: PERFORMANCE.AFFILIRED,
  analyticsTracking: PERFORMANCE.ANALYTICS,
  bingTracking: PERFORMANCE.BING,
  compareTracking: PERFORMANCE.COMPARE,
  facebookTracking: PERFORMANCE.FACEBOOK,
  gtmTracking: PERFORMANCE.GTM,
  miraiTracking: PERFORMANCE.MIRAI,
  thnTracking: PERFORMANCE.THE_HOTEL_NETWORK,
};

export {
  ACCOMMODATION_TYPE,
  AUTH_TYPE,
  CALENDAR_FORMAT,
  COOKIE,
  ERROR,
  EVENT,
  FINDER_STORAGE_KEY,
  MIN_STAY_TYPE,
  PERFORMANCE,
  TAX_DEFAULT,
  TAXES_TYPE,
  TAXES,
  TRACKING,
  TYPE_COMMISSION,
  TYPE,
};
