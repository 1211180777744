.environment {
  background-color: var(--mirai-chat-input-background);
  gap: calc(var(--mirai-chat-space) / 2);
  padding: calc(var(--mirai-chat-space) / 3) calc(var(--mirai-chat-space) / 1.5);
}

.environment .intent {
  flex: 1;
}

.environment .variable {
  color: var(--mirai-ui-base);
  display: block;
}

.environment.error {
  background-color: var(--mirai-ui-error-background);
}

.environment.error .intent,
.environment.error ui-tooltip * {
  color: var(--mirai-ui-error);
}
