import { Icon, Text, Pressable, styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as style from './Card.module.css';

const Card = ({
  children,
  disabled = false,
  href,
  icon,
  offset = true,
  option = true,
  text,
  onPress,
  ...others
} = {}) => {
  const [checked, setChecked] = useState(false);

  const handlePress = () => {
    if (href) {
      const page = window.open(href, '_blank');
      page.document.title = 'Loading...';
    } else {
      if (option) setChecked(true);
      onPress();
    }
  };

  const pressable = !!onPress || !!href;

  return (
    <Pressable
      {...others}
      disabled={disabled || !pressable}
      onPress={pressable ? handlePress : undefined}
      className={styles(
        style.card,
        checked && style.checked,
        disabled && !checked && style.disabled,
        offset && style.offset,
        pressable && !disabled && style.pressable,
        others.className,
      )}
    >
      {onPress && option && (
        <Text action className={style.text}>
          {checked ? '●' : '○'}
        </Text>
      )}
      {icon && <Icon value={icon} />}
      {text && (
        <Text action bold wide className={styles(style.text, onPress && !option && style.button)}>
          {text}
        </Text>
      )}

      {children && <View wide>{children}</View>}
    </Pressable>
  );
};

Card.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  offset: PropTypes.bool,
  option: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.string,
  onPress: PropTypes.func,
};

export { Card };
