import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Action, Pressable, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Footer.l10n';
import * as style from './Footer.module.css';
import { EVENT } from '../../helpers';

const Footer = ({ logo: showLogo = false, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      club: { url: { privacy } = {} } = {},
      finder: { place: { id: [placeId] = [], isHotel, title: placeName } = {} } = {},
      hotel: { features = {}, id, url } = {},
      id: instanceId,
    } = {},
  } = useStore();

  const [paddingBottom, setPaddingBottom] = useState();

  useEffect(() => {
    const handleHeightChange = ({ height } = {}) => setPaddingBottom(height);

    Event.subscribe(EVENT.FOOTER_HEIGHT_CHANGE, handleHeightChange);

    return () => Event.unsubscribe(EVENT.FOOTER_HEIGHT_CHANGE, handleHeightChange);
  }, []);

  const handleUrl = () => {
    window.location = url;
  };

  const { logo, name } =
    (placeId ? (isHotel ? features[placeId] : { name: placeName, ...features[instanceId] }) : features[id]) || {};
  const linkProps = { target: '_blank', small: true, className: style.link };

  return (
    <View
      row={!isMobile}
      {...others}
      className={[style.container, others.className]}
      style={paddingBottom ? { paddingBottom } : undefined}
    >
      <View row={!isMobile} className={style.content}>
        <View row={!isMobile} className={style.info}>
          {showLogo && logo && !isMobile && (
            <Pressable onPress={url ? handleUrl : undefined}>
              <View tag="img" src={logo} className={style.logo} />
            </Pressable>
          )}

          <Text light small className={style.copyright}>
            © {new Date().getFullYear()}
            <Action href={url} {...linkProps}>
              {name}
            </Action>
            .
          </Text>

          <Text light small>
            {translate(L10N.LABEL_ALL_RIGHTS_RESERVED)}
          </Text>

          {privacy && (
            <Action href={privacy} {...linkProps}>
              {translate(L10N.ACTION_PRIVACY_POLICY)}
            </Action>
          )}
        </View>

        <Action href="https://www.mirai.com/" small target="_blank" className={style.powered}>
          Powered by{' '}
          <Text bold light className={style.mirai}>
            mirai
          </Text>
        </Action>
      </View>
    </View>
  );
};

Footer.displayName = 'Mirai:Core:Footer';

Footer.propTypes = {
  logo: PropTypes.bool,
};

export { Footer };
