import { Theme } from '@mirai/ui';

import { findDangerousCSSRules } from './findDangerousCSSRules';
import { IS_JEST } from '../components/helpers';

let cssTheme;
if (!IS_JEST) cssTheme = require('bundle-text:../theme.css');

const BROWSER_FONTS = ['"system-ui"', 'serif', 'Times', '"Times New Roman"', '-webkit-standard'];
const FONT_FAMILY = 'font-family';
const LEGACY_ACCENT_VARIABLE = '--corporative-color';
const VARIABLES = {};

export const theme = ({ accent = '', findDangerousCSS = false } = {}) => {
  const processed = [];

  const setVariable = (key, value) => {
    if (!value || processed.includes(key)) return;

    document.documentElement.style.setProperty(key, value.trim());
    processed.push(key);
  };

  // Import css variables from theme.css
  if (cssTheme) {
    const style = document.createElement('style');
    const head = document.head;
    style.textContent = cssTheme;
    head.insertBefore(style, head.firstChild);
  }

  // CSS variables
  Object.entries(VARIABLES).forEach(([variable, legacy]) => {
    if (getComputedStyle(document.documentElement).getPropertyValue(legacy)) setVariable(variable, `var(${legacy})`);
  });

  // Generate accent palette using legacy
  let legacyAccent = getComputedStyle(document.documentElement).getPropertyValue(LEGACY_ACCENT_VARIABLE);
  if (legacyAccent) {
    legacyAccent = legacyAccent.replace(/^"|"$/g, '').trim();
    Theme.generatePalette('accent', legacyAccent);
  }

  // CSS Selectors
  let font;

  const bodyFont = window.getComputedStyle(document.body).getPropertyValue(FONT_FAMILY);
  if (bodyFont && !BROWSER_FONTS.includes(bodyFont)) {
    font = bodyFont;
  } else {
    const miBe = document.querySelector('.mi-be');
    const miBeFont = miBe ? window.getComputedStyle(miBe).getPropertyValue(FONT_FAMILY) : undefined;
    if (miBeFont && !BROWSER_FONTS.includes(miBeFont)) font = miBeFont;
  }

  if (font) setVariable('--mirai-ui-font', font);

  if (accent.length === 6) Theme.generatePalette('accent', `#${accent}`);

  // Dangerous CSS rules
  if (findDangerousCSS) {
    const cssRules = findDangerousCSSRules();
    // eslint-disable-next-line no-console
    if (cssRules.length) console.warn('WARNING: css rules ending with html tags', cssRules);
  }
};
