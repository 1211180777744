import { Event } from '@mirai/data-sources';
import { Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { EVENT, INTENT } from '../../../Chat.constants';
import { Card } from '../components';
import * as style from '../Message.module.css';

const Offers = ({ disabled, response: { text, requirements, value = [] } = {}, onAction }) => {
  const [inputResponse, setInputResponse] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatchRequirement(), [requirements]);

  const dispatchRequirement = () => {
    setInputResponse();

    requirements && Event.publish(EVENT.INPUT_COMPONENT, { onSubmit: setInputResponse });
  };

  const [{ text: requirementText } = {}] = Object.values(requirements || {});

  return (
    <>
      {text && (
        <Text action className={style.text}>
          {text}
        </Text>
      )}

      <>
        {value.map((offer, index) =>
          React.createElement(
            Card,
            {
              key: index,
              disabled,
              option: false,
              onPress: () => onAction({ intent: INTENT.OFFERS, form: { offerId: offer.id } }),
            },

            <View row>
              <View className={style.thumbnail} style={{ backgroundImage: `url(${offer.image})` }} />
              <View>
                <Text action bold>
                  {offer.name}
                </Text>
                <Text small>{offer.description}</Text>
              </View>
            </View>,
          ),
        )}
      </>

      {requirementText && (
        <Card
          disabled={disabled}
          option={false}
          text={inputResponse?.text || requirementText}
          onPress={dispatchRequirement}
        />
      )}
    </>
  );
};

Offers.propTypes = {
  disabled: PropTypes.bool,
  response: PropTypes.shape({
    text: PropTypes.string,
    requirements: PropTypes.shape({}),
  }),
  status: PropTypes.shape({}),
  onAction: PropTypes.func,
};

export { Offers };
