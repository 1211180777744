import { useStore } from '@mirai/data-sources';
import { parseDate, useLocale } from '@mirai/locale';
import { Icon, Pressable, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Option, RateDetails, TooltipDates } from './components';
import { L10N } from './Item.l10n';
import * as style from './Item.module.css';
import { ICON } from '../../../helpers';
import { DISCOUNT_TYPE } from '../../Rates.constants';

const Rates = ({
  availableBoards = [],
  availableRates = [],
  boards = [],
  cancellations = [],
  features: {
    columnMode,
    hideRadio = false,
    highlightStandaloneOption = false,
    rowMode,
    showOffersFirst = false,
    showRateIncluded = false,
  } = {},
  priceFactor = 1,
  promocodeForAll,
  rates = [],
  selectedBoard = {},
  onBoard,
  onCancellation,
  onCancellationInfo,
  onRate,
  onOfferInfo,
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  const { value: { finder: { promocode } = {} } = {} } = useStore();

  const { cancellationId, rateId, id: boardId } = selectedBoard;

  const countdownRates = availableRates.filter(({ countdown } = {}) => countdown > 0);
  const [firstRate = {}] = availableRates;
  const has = {
    cancellations: cancellations.length > 1 || highlightStandaloneOption,
    boards:
      boards.length > 1 ||
      availableBoards.filter(({ name } = {}) => name === availableBoards[0].name).length !== availableBoards.length ||
      highlightStandaloneOption,
    inlineBoards: hideRadio && boards.length > 1 && boards.length <= (columnMode || rowMode ? 3 : 2),
    inlineCancellations:
      hideRadio && cancellations.length > 1 && cancellations.length <= (columnMode || rowMode ? 3 : 2),
    rates:
      cancellations.length !== availableRates.length ||
      (countdownRates.length > 0 && countdownRates.length !== availableRates.length) ||
      availableRates.filter(
        ({ description, included, name } = {}) =>
          !description ||
          (firstRate.description === description &&
            firstRate.name === name &&
            JSON.stringify(firstRate.included) === JSON.stringify(included)),
      ).length !== availableRates.length ||
      highlightStandaloneOption,
  };

  const renderCancellations = () =>
    has.cancellations && (
      <View className={[style.offset, style.gap]}>
        <Text action={isMobile} bold>
          {translate(L10N.LABEL_CANCELLATION_POLICY)}
        </Text>

        {React.createElement(
          has.inlineCancellations ? View : React.Fragment,
          has.inlineCancellations ? { row: true, className: style.inlineOptions } : undefined,
          cancellations
            .filter(({ id }) => !showOffersFirst || (showOffersFirst && (id === cancellationId || !has.rates)))
            .map(({ highlight, id, name, caption, price, ...cancellation }) => (
              <Option
                key={id}
                caption={caption}
                checked={id === cancellationId}
                inline={has.inlineCancellations}
                outlined={hideRadio}
                value={!showOffersFirst ? price / priceFactor : undefined}
                onPress={() => onCancellation(id)}
              >
                <Text action={!isMobile} medium={highlight} small={isMobile} className={style.optionText}>
                  {highlight ? (
                    <Text
                      action={!isMobile}
                      medium={highlight}
                      small={isMobile}
                      className={styles(highlight && style.tag)}
                    >
                      {name}
                    </Text>
                  ) : (
                    name
                  )}

                  <Pressable onPress={() => onCancellationInfo({ ...selectedBoard, cancellation })}>
                    <Icon paragraph value={ICON.INFO} />
                  </Pressable>
                </Text>
              </Option>
            )),
        )}
      </View>
    );

  return (
    <>
      {!showOffersFirst && renderCancellations()}

      {has.rates && (
        <View className={[style.offset, style.gap]}>
          <Text action={isMobile} bold>
            {translate(L10N.LABEL_OFFER)}
          </Text>
          {rates.map(
            ({ id, cancellationId, countdown, description, disabled, included, name, price, restrictions }) => {
              const rate = rates.find((rate) => rate.id === id);
              const board = availableBoards.filter(({ rateId } = {}) => rateId === id)[0];
              const { confidential, discount: { breakdown = [], percentage } = {} } = board || {};
              const isPromocode =
                promocode &&
                !promocodeForAll &&
                breakdown.find(
                  ({ type } = {}) => type === DISCOUNT_TYPE.PROMOTION || (type === DISCOUNT_TYPE.DEAL && confidential),
                );
              const discountPercentage = (isPromocode && percentage) || undefined;

              return (
                <Option
                  key={id}
                  caption={
                    <RateDetails
                      {...{
                        countdown,
                        discountPercentage,
                        included: showRateIncluded ? included : undefined,
                        restrictions,
                        selectedBoard:
                          selectedBoard.rateId === id
                            ? selectedBoard
                            : availableBoards.filter(({ rateId } = {}) => rateId === id)[0],
                        onOfferInfo: () => onOfferInfo({ ...rate, availableBoards }),
                      }}
                    />
                  }
                  checked={id === rateId}
                  disabled={disabled}
                  outlined={hideRadio}
                  value={price / priceFactor}
                  onPress={rates.length > 0 ? () => onRate({ id, cancellationId }) : undefined}
                >
                  {rates.length > 0 && (
                    <Text medium={hideRadio} action={!isMobile} small={isMobile} className={style.optionText}>
                      <View row className={style.tags}>
                        {isPromocode && (
                          <Text action={!isMobile} medium small={isMobile} className={[style.tag, style.highlight]}>
                            {promocode.toUpperCase()}
                          </Text>
                        )}
                        {discountPercentage && (
                          <Text action={!isMobile} medium small={isMobile} className={style.tag}>
                            {translate(L10N.LABEL_DISCOUNT, { value: `${discountPercentage}%` })}
                          </Text>
                        )}
                      </View>

                      {name}

                      {(description || included.length > 0) && (
                        <Pressable onPress={() => onOfferInfo({ ...rate, availableBoards })}>
                          <Icon paragraph value={ICON.INFO} />
                        </Pressable>
                      )}
                    </Text>
                  )}
                </Option>
              );
            },
          )}
        </View>
      )}

      {showOffersFirst && renderCancellations()}

      {has.boards && (
        <View className={styles(style.offset, style.gap)}>
          <Text action={isMobile} bold>
            {translate(L10N.LABEL_ACCOMMODATION_PLAN)}
          </Text>

          {React.createElement(
            has.inlineBoards ? View : React.Fragment,
            has.inlineBoards ? { row: true, className: style.inlineOptions } : undefined,
            boards.map((board = {}) => {
              const { disabled, id, increment, name, variants = [] } = board;

              return (
                <Option
                  key={id}
                  checked={id === boardId}
                  disabled={disabled}
                  inline={has.inlineBoards}
                  outlined={hideRadio}
                  title={name}
                  value={has.boards ? increment / priceFactor : undefined}
                  onPress={() => onBoard(board)}
                >
                  {variants.length > 0 && (
                    <TooltipDates
                      captions={variants.map(({ name } = {}) => name)}
                      values={variants.map(({ value } = {}) => parseDate(value))}
                    />
                  )}
                </Option>
              );
            }),
          )}
        </View>
      )}

      <View />
    </>
  );
};

Rates.propTypes = {
  availableBoards: PropTypes.arrayOf(PropTypes.any),
  availableRates: PropTypes.arrayOf(PropTypes.any),
  boards: PropTypes.arrayOf(PropTypes.any),
  cancellations: PropTypes.arrayOf(PropTypes.any),
  features: PropTypes.shape({
    columnMode: PropTypes.bool,
    hideStandaloneBoard: PropTypes.bool,
    highlightStandaloneOption: PropTypes.bool,
    rowMode: PropTypes.bool,
    showOffersFirst: PropTypes.bool,
    showRateIncluded: PropTypes.bool,
  }),
  priceFactor: PropTypes.number,
  promocodeForAll: PropTypes.bool,
  rates: PropTypes.arrayOf(PropTypes.any),
  selectedBoard: PropTypes.shape({}),
  onBoard: PropTypes.func,
  onCancellation: PropTypes.func,
  onCancellationInfo: PropTypes.func,
  onChange: PropTypes.func,
  onOfferInfo: PropTypes.func,
  onRate: PropTypes.func,
};

Rates.displayName = 'Mirai:Core:Rates:Item:Rates';

export { Rates };
