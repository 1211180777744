import { useStore } from '@mirai/data-sources';
import { styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Card, Environment } from './components';
import { getActionIcon, getTime } from './helpers';
import { INTENT, INTENT_UNKNOWN } from './Message.constants';
import * as style from './Message.module.css';

const Message = ({ auto, busy = false, intent, response = {}, text, timestamp, ...others }) => {
  const {
    value: { urlParams: { lisa } = {} },
  } = useStore();

  const { actions = [], value } = response;
  const { disabled, testId, onAction } = others;

  return (
    <View testId={testId} wide>
      {timestamp && (
        <Text light small className={style.timestamp}>
          {getTime(timestamp)}
        </Text>
      )}
      <View row wide className={styles(style.message, auto && style.auto)}>
        <View className={styles(style.content, auto && style.auto, busy && style.busy)}>
          {!busy && auto && intent && lisa === 'environment' && <Environment {...{ intent, ...others, ...response }} />}

          {busy ? (
            <View className={style.spinner} />
          ) : text ? (
            <Text action className={style.text}>
              {text}
            </Text>
          ) : (
            React.createElement(INTENT[intent] || INTENT_UNKNOWN, { intent, response, ...others })
          )}

          {actions.map(({ intent, text, url }, index) => (
            <Card
              key={`chat.message:${index}`}
              disabled={intent && disabled}
              href={url}
              icon={getActionIcon(url)}
              text={text}
              onPress={intent ? () => onAction({ intent, context: value }) : undefined}
            />
          ))}
        </View>
      </View>
    </View>
  );
};

Message.propTypes = {
  auto: PropTypes.bool,
  busy: PropTypes.bool,
  disabled: PropTypes.bool,
  intent: PropTypes.string,
  response: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    requirements: PropTypes.shape({}),
    text: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.array, PropTypes.shape({})]),
  }),
  text: PropTypes.string,
  timestamp: PropTypes.number,
  onAction: PropTypes.func,
};

export { Message };
