import { IS_JEST } from '../../helpers';

export const L10N = {
  LABEL_CHECK_IN: { id: 'common.label.check_in', defaultMessage: !IS_JEST ? '　　　　' : undefined },
  LABEL_CHECK_OUT: { id: 'common.label.check_out', defaultMessage: !IS_JEST ? '　　　　　' : undefined },
  LABEL_NIGHTS: { id: 'finder.label.nights_stay' },
  LABEL_MIN_NIGHTS: { id: 'finder.label.min_nights' },
  LABEL_MIN_NIGHTS_DEALS: { id: 'finder.label.min_nights_deals' },
  LABEL_MIN_NIGHTS_REQUIRED: { id: 'finder.label.min_nights_required' },
};
