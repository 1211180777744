import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceBooking } from '@mirai/services';
import { Action, Notification, Progress, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACTION, ERROR, STATE } from './Booking.constants';
import { L10N } from './Booking.l10n';
import * as style from './Booking.module.css';
import Skeleton from './Booking.skeleton';
import { Hotelverse } from './components';
import { Information, Menu, Modal } from './partials';
import { BookingPayment } from './partials/Booking.Payment';
import { BookingDetails, BookingSummary, BookingTerms, Footer } from '../__shared__';
import { Header } from '../Header';
import { EVENT } from '../helpers';

const Booking = ({ skeleton, ...others }) => {
  const { isDesktop, isMobile } = useDevice();
  const { locale, translate } = useLocale();
  const {
    value: { hotel: { url } = {}, urlParams: { code: token, reservationCode: id, hasToTracking: tracking } = {} },
  } = useStore();

  const [action, setAction] = useState();
  const [dataSource, setDataSource] = useState();
  const [expanded, setExpanded] = useState(false);
  const [responseError, setResponseError] = useState();

  useEffect(() => Event.publish(EVENT.METRICS, { id: 'BOOKING:RENDER' }), []);

  useEffect(() => {
    if (skeleton || !id || !token) return;

    (async () => {
      setDataSource(
        await ServiceBooking.get({ id, locale, token, tracking, url }).catch((error) => setResponseError(error)),
      );
      setAction();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, locale]);

  useEffect(() => {
    if (!dataSource || !dataSource.tracking) return;

    const event = dataSource.state === STATE.CANCELLED ? EVENT.BOOKING_CANCEL : EVENT.BOOKING_RESPONSE;
    Event.publish(event, { event, response: { ...dataSource } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource?.state]);

  const handleMenuPress = (action) => {
    if (action === ACTION.PRINT) {
      setExpanded(true);
      setTimeout(window.print, 10);
    } else {
      setAction(action);
    }
  };

  const handleBackHome = () => {
    const { payload } = responseError;

    window.location.href = payload?.hotelUrl || window.location.origin;
  };

  const { payment, state } = dataSource || {};
  const response = dataSource !== undefined;
  const isCancelled = state === STATE.CANCELLED;

  return (
    <>
      <Header />

      <View role="booking" testId={others.testId} className={styles(style.container, others.className)}>
        <Progress indeterminate visible={!response && !responseError} className={style.progress} />
        {response ? (
          <>
            <View row={isDesktop} className={style.content}>
              <View>
                {state !== STATE.POS_ERROR && (
                  <Notification
                    error={isCancelled}
                    info={state === STATE.COMPLETED}
                    small={isMobile}
                    success={state === STATE.CONFIRMED}
                    warning={state === STATE.POSTPONED}
                    wide
                  >
                    {translate(L10N.NOTIFICATION[state])}
                    <Hotelverse {...dataSource} />
                  </Notification>
                )}

                {payment && !isCancelled && <BookingPayment {...{ dataSource, onError: setResponseError }} />}

                <BookingSummary
                  box={!isDesktop}
                  dataSource={{ ...dataSource, showBasePrice: true }}
                  className={styles(isDesktop && style.print)}
                />

                <Information dataSource={dataSource} />
                <BookingDetails {...{ dataSource, expanded }} expanded={isMobile} className={style.box} />
                <BookingTerms dataSource={dataSource} small />
              </View>

              <View className={style.side}>
                {isDesktop && (
                  <BookingSummary box dataSource={{ ...dataSource, showBasePrice: true }} className={style.noPrint} />
                )}

                <Menu dataSource={dataSource} onPress={handleMenuPress} />
              </View>
            </View>

            <Modal
              {...{ action, dataSource: { ...dataSource, token } }}
              onChange={({ response: { tracking } = {}, state }) =>
                state && setDataSource({ ...dataSource, tracking, state })
              }
              onClose={() => setAction()}
            />
          </>
        ) : responseError ? (
          <Notification error large className={style.error}>
            {translate(L10N.NOTIFICATION_ERROR[responseError.code] || L10N.NOTIFICATION_ERROR[ERROR.GENERIC])}
            <Action paragraph underline wide onPress={handleBackHome}>
              {translate(L10N.ACTION_BACK_HOME)}
            </Action>
          </Notification>
        ) : (
          <Skeleton />
        )}
      </View>

      <Footer />
    </>
  );
};

Booking.displayName = 'Mirai:Core:Booking';

Booking.propTypes = {
  skeleton: PropTypes.bool,
};

export { Booking };
