import { translate as translateBase } from '@mirai/locale';

import { IS_JEST } from './isJest';

const defaultMessage = !IS_JEST ? '　　　' : undefined;

const L10N = {
  LABEL_ACCOMMODATION_TYPE: { id: 'common.label.accommodation_type', defaultMessage },
  LABEL_ACCOMMODATION_TYPE_ABBREVIATION: { id: 'common.label.accomodation_type_abbreviation', defaultMessage },
  LABEL_ACCOMMODATION_TYPE_PLURAL: { id: 'common.label.accomodation_type_plural', defaultMessage },
};

export const getAccommodationLabel = ({ abbreviation = false, plural = false, type } = {}, translate = translateBase) =>
  translate(
    abbreviation
      ? L10N.LABEL_ACCOMMODATION_TYPE_ABBREVIATION
      : plural
      ? L10N.LABEL_ACCOMMODATION_TYPE_PLURAL
      : L10N.LABEL_ACCOMMODATION_TYPE,
    { type },
  )?.toLowerCase();
