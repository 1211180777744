export const L10N = {
  ACTION_CHANGE_SELECTION: { id: 'user.action.change_selection' },
  ACTION_SEND_EMAIL: { id: 'common.action.send_email' },
  ACTION_VIEW_HOTEL: { id: 'common.action.view_hotel' },
  ACTION_VIEW_MAP: { id: 'common.action.view_map' },

  LABEL_ACCOMMODATION: { id: 'common.label.accommodation' },
  LABEL_BOOKING_SUMMARY: { id: 'user.label.booking_summary' },
  LABEL_CHECK_IN: { id: 'common.label.check_in' },
  LABEL_CHECKIN_TIME: { id: 'common.label.checkin_time' },
  LABEL_CHECK_OUT: { id: 'common.label.check_out' },
  LABEL_CHECKOUT_TIME: { id: 'common.label.checkout_time' },
  LABEL_NIGHT_COUNT: { id: 'common.label.night_count' },
  LABEL_PROPERTY_TYPE: { id: 'common.label.property_type' },
  LABEL_ROOM_COUNT: { id: 'common.label.room_count' },
  LABEL_ROOM_NUMBER: { id: 'common.label.room_number' },
  LABEL_SUBJECT_TO_AVAILABILITY: { id: 'user.label.subject_to_availability' },
  LABEL_YOUR_RESERVATION: { id: 'user.label.your_reservation' },
};
