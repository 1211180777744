/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from '@mirai/data-sources';
import { dateFormat, dateDiff, useLocale } from '@mirai/locale';
import { ServiceDeals, ServiceRooms } from '@mirai/services';
import { Button, Icon, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getCurrency, validate } from './helpers';
import { Calendar } from './RatesExplorer.Calendar';
import { FIELD } from './RatesExplorer.constants';
import { L10N } from './RatesExplorer.l10n';
import * as style from './RatesExplorer.module.css';
import Skeleton from './RatesExplorer.skeleton';
import { FieldOccupation, FieldPromocode } from '../';
import { REDIRECT_PATH } from '../../../Core.constants';
import { prepareRoom } from '../../../helpers/prepareRoom';
import { ICON, toQueryString } from '../../helpers';
import { toParties } from '../Occupation/helpers/toParties';

const RatesExplorer = ({ offerId, roomId, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    set,
    value: {
      cookies = {},
      currency,
      deals,
      hotel,
      language,
      locale,
      origin,
      rooms,
      skeleton = false,
      urlParams = {},
      ...store
    },
  } = useStore();
  const ref = useRef(null);

  const [busy, setBusy] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [error, setError] = useState({});
  const [visible, setVisible] = useState();
  const [visited, setVisited] = useState({});

  useEffect(() => {
    !isMobile && document.addEventListener('click', handleClickOutside, true);

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      !isMobile && document.removeEventListener('click', handleClickOutside, true);
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    if (skeleton) return;

    (async () => setDataSource(await getDataSource()))();
  }, [currency, locale]);

  useEffect(() => {
    const { occupation: { minRooms = 1, dataSource: occupation = [] } = {} } = dataSource || {};

    !value?.occupation &&
      occupation.length > 0 &&
      handleChange(
        'occupation',
        [...Array(minRooms)].map(() => prepareRoom(occupation)),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    visible && setVisited({ ...visited, [`${visible}`]: true });
  }, [visible]);

  const handleChange = (field, fieldValue) => {
    if (!dataSource) return;

    const nextValue = { ...value, [field]: fieldValue };
    set(offerId ? { deals: nextValue } : { rooms: nextValue });
  };

  const handleClear = () => {
    set(offerId ? { deals: undefined } : { rooms: undefined });
    setError({});
    setVisible();
    setVisited(false);
  };

  const handleClickOutside = ({ target } = {}) =>
    !ref.current?.contains(target) && target.type !== 'text' && setVisible();

  const handlePageShow = async ({ persisted } = {}) => {
    if (!persisted) return;

    setBusy(false);
    handleClear();
    setDataSource(await getDataSource());
  };

  const handleSubmit = () => {
    setBusy(true);
    const { calendar, occupation, promoCode } = value;

    const params = {
      checkin: dateFormat(calendar[0], { format: 'DD/MM/YYYY' }),
      clientCode: promoCode || '',
      currency: getCurrency({ urlParams, cookies, currency }) || '',
      hsri: origin.commissionCode,
      ...(offerId ? { idoffers: offerId } : undefined),
      idtokenprovider: hotel.id,
      lang: language,
      nights: dateDiff(calendar[0], calendar[1]).days,
      parties: window.btoa(JSON.stringify(toParties(occupation))),
      ...(roomId ? { roomTypeId: roomId } : undefined),
    };
    window.location.href = `${process.env.SERVICE_BOOKING}/${REDIRECT_PATH}${toQueryString(params)}`;

    setBusy(true);
  };

  const getDataSource = () =>
    (offerId ? ServiceDeals : ServiceRooms)
      .get({
        ...store,
        currency: getCurrency({ cookies, currency, urlParams }),
        hotel,
        locale,
        offerId,
        roomId,
      })
      .catch(() => {});

  const value = deals || rooms || {};
  const { calendar = [], occupation, promoCode = '' } = value;

  const isValid = validate({ dataSource, error, value });

  const role = offerId ? 'deals' : 'rooms';

  return !skeleton ? (
    <View {...others} role={role} className={styles(style.ratesExplorer, others.className)}>
      <View row className={style.header}>
        <Text bold headline level={2} markdown={false} className={style.text}>
          {translate(L10N.LABEL_SELECT_DATES_PERSONS)}
        </Text>

        <Button transparent onPress={handleClear} testId={`${role}-button-clear`}>
          <Icon value={ICON.CLEAR} />
          {translate(L10N.ACTION_CLEAR)}
        </Button>
      </View>

      <Calendar
        {...dataSource?.calendar}
        value={calendar}
        visible={visible === FIELD.CALENDAR}
        visited={visited.calendar}
        onChange={(calendar, event) => handleChange('calendar', calendar, event)}
        onPress={() => setVisible(visible !== FIELD.CALENDAR ? FIELD.CALENDAR : undefined)}
        testId={`${role}-calendar`}
      />

      <View row={!isMobile} className={style.extras}>
        <FieldOccupation
          {...dataSource?.occupation}
          error={!!error.occupation}
          hideLabel={!isMobile}
          hotels={[hotel?.id]}
          ref={ref}
          value={occupation}
          visible={visible === FIELD.OCCUPATION}
          onChange={(occupation) => handleChange('occupation', occupation)}
          onError={(occupation) => {
            if (!isMobile && visible !== FIELD.OCCUPATION) setVisible(FIELD.OCCUPATION);
            setError({ ...error, occupation });
          }}
          onPress={() => setVisible(visible !== FIELD.OCCUPATION ? FIELD.OCCUPATION : undefined)}
          onValid={() => setError({ ...error, occupation: undefined })}
          testId={`${role}-occupation`}
        />

        <FieldPromocode
          value={promoCode}
          visible={visible === FIELD.PROMOCODE}
          onChange={(promoCode) => handleChange('promoCode', promoCode)}
          onEnter={() => setVisible(FIELD.PROMOCODE)}
          onLeave={() => setVisible(undefined)}
          testId={`${role}-promocode`}
        />

        {!isMobile && <View className={style.footer} />}

        <Button
          busy={busy}
          disabled={!isValid}
          large
          wide={isMobile}
          onPress={handleSubmit}
          className={style.cta}
          testId={`${role}-button`}
        >
          {translate(L10N.ACTION_SEARCH)}
        </Button>
      </View>
    </View>
  ) : (
    <Skeleton />
  );
};

RatesExplorer.displayName = 'Mirai:Core:RatesExplorer';

RatesExplorer.propTypes = {
  offerId: PropTypes.string,
  roomId: PropTypes.string,
};

export { RatesExplorer };
