import { Event, useStore } from '@mirai/data-sources';
import { dateDiff, useLocale } from '@mirai/locale';
import { ServiceFeatures, ServiceRates } from '@mirai/services';
import { Notification, Progress, styles, Theme, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  CardHotel,
  Filters,
  Item,
  ModalCancellationInfo,
  ModalClubRate,
  ModalExtras,
  ModalItemInfo,
  ModalItemUrl,
  ModalMapView,
  ModalOfferInfo,
  ModalTwin,
} from './components';
import {
  filterItem,
  getCheckoutParams,
  getFeatures,
  getFinderParams,
  getForwarderUrl,
  select,
  showClubRate,
} from './helpers';
import { useRates } from './hooks';
import { FEATURES } from './Rates.constants';
import { Content } from './Rates.Content';
import { Footer as RatesFooter } from './Rates.Footer';
import { Header as RatesHeader } from './Rates.Header';
import { L10N } from './Rates.l10n';
import * as style from './Rates.module.css';
import { RoomSelector } from './Rates.RoomSelector';
import Skeleton from './Rates.Skeleton';
import { Unavailability } from './Rates.Unavailability';
import { ERROR } from '../../Core.constants';
import { fetchUrlParams } from '../../helpers';
import { Cards, Footer } from '../__shared__';
import { Finder } from '../Finder';
import { Header } from '../Header';
import { EVENT, getOccupationTotals, opacity, replaceUrlParams, SUBMIT_DELAY } from '../helpers';

const Rates = ({ testId, ...others }) => {
  const { isDesktop, isMobile, width } = useDevice();
  const { actions, device, fetch, multiRoom, refRoomSelector, responseError, state } = useRates();
  const {
    set,
    value: {
      club = {},
      currency,
      finder = {},
      forwarder,
      hotel = {},
      id,
      locale,
      session,
      skeleton = false,
      urlParams,
      variant,
    },
  } = useStore();

  const { translate } = useLocale();
  const refItems = useRef({});

  const [busy, setBusy] = useState(false);
  const [cancellationInfo, setCancellationInfo] = useState();
  const [extras, setExtras] = useState();
  const [filterBoard, setFilterBoard] = useState();
  const [filters, setFilters] = useState({});
  const [itemInfo, setItemInfo] = useState();
  const [itemUrl, setItemUrl] = useState();
  const [mapView, setMapView] = useState();
  const [offerInfo, setOfferInfo] = useState();
  const [twin, setTwin] = useState();

  const { calendar = [], occupation = [] } = finder;
  const { cart, clubDiscount, clubRate, dataSource, fetching, metaBoard, room, strictSearch } = state;
  const { setCart, setClubRate, setDataSource, setMetaBoard, setRoom, setStrictSearch } = actions;

  useEffect(
    () =>
      Event.publish(EVENT.METRICS, {
        id: 'RATES:RENDER',
        ...getFeatures(hotel.features, urlParams, { isDesktop }, multiRoom),
        multiRoom,
        variant,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!filterBoard) return;

    setMetaBoard(filterBoard);
    scrollToRoom(filterBoard.roomId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBoard]);

  // -- handlers -------------------------------------------------------------------------------------------------------
  const handleFilters = (next = {}) => {
    const { strictSearch: nextStrictSearch } = next;

    setCart([]);
    setRoom(undefined);
    setFilters(next);

    if (strictSearch !== nextStrictSearch) setStrictSearch(nextStrictSearch);
  };

  const handleFinder = (values = {}) => {
    setCart([]);
    setDataSource(undefined);
    setRoom();
    setMetaBoard();
    scrollTo(0);

    replaceUrlParams(getFinderParams({ ...values, hotel }));
    const { occupation: nextOccupation, place: nextPlace, promocode: nextPromocode } = values;
    fetch({ nextOccupation, nextPlace, nextPromocode });
  };

  const handleSelect = (board, others) => {
    const nextCart = [...cart];
    nextCart[room || 0] = board ? { ...board, ...others } : undefined;

    if (board) {
      scrollToRoom(board.roomId);

      Event.publish(EVENT.RATES_ADD_TO_CART, {
        event: EVENT.RATES_ADD_TO_CART,
        response: { board, cart: nextCart, ...dataSource },
      });
    }

    setCart(nextCart);
    setExtras();
    setMetaBoard(undefined);
    setTwin();
    clubRate && setClubRate(false);

    if (!multiRoom && !!board) handleSubmit(nextCart);

    if (multiRoom) {
      Event.publish(EVENT.METRICS, { id: 'RATES:SELECT_ROOM' });
      if (board && room < occupation.length - 1) return setRoom(room + 1);
      if (!board) set({ rates: undefined });
    }
  };

  const handleSubmit = async (cart, sessionProp = session) => {
    const isPestana = ServiceFeatures.get(FEATURES.PESTANA, id) || false;
    setClubRate(false);

    if (showClubRate({ cart, clubDiscount, dataSource, isPestana, session: sessionProp })) return setClubRate(true);

    const { extras = [] } =
      (await ServiceRates.extras({
        ...getCheckoutParams({
          cart,
          clubDiscount,
          currency,
          dataSource,
          forwarder,
          urlParams: fetchUrlParams(location.search),
        }),
        ...getOccupationTotals({ infantAge: dataSource.infantAge, occupation }),
        clubDiscount: !!session || clubDiscount,
        device,
        hotelCurrency: dataSource.currency,
        locale,
        session,
      }).catch(() => {})) || {};

    const selectableExtras = extras.some(({ included } = {}) => !included);

    if (selectableExtras) setExtras(extras);
    else handleCheckout(cart, extras);
  };

  const handleCheckout = (cart = [], extras = [], variant = 'A') => {
    setBusy(true);

    const { calendar = [], place, promocode } = finder;
    const { days: nights } = dateDiff(calendar[0], calendar[1]);

    const metrics = {
      // -- type
      meta: !!metaBoard,
      availableRooms: dataSource.items?.filter(({ availability }) => availability > 0).length,
      unavailableRooms: dataSource.items?.filter(({ availability }) => !availability).length,
      // -- selection
      chain: !!place,
      nights,
      rooms: cart?.length || 1,
      loyalty: clubDiscount,
      extras: extras?.length,
      promocode: !!promocode,
      session: !!session,
    };

    Event.publish(EVENT.METRICS, { id: 'RATES:SUBMIT', ...metrics });

    setTimeout(() => {
      setBusy(false);
      document.location.href = getForwarderUrl({
        cart,
        clubDiscount,
        currency,
        dataSource,
        extras,
        forwarder,
        meta: !!metaBoard,
        session,
        urlParams: { ...fetchUrlParams(location.search), device },
        variant,
        checkoutParams: JSON.stringify(
          getCheckoutParams({
            cart,
            clubDiscount,
            currency,
            dataSource,
            forwarder,
            urlParams: fetchUrlParams(location.search),
          }),
        ),
        occupationTotals: JSON.stringify(getOccupationTotals({ infantAge: dataSource.infantAge, occupation })),
      });
    }, SUBMIT_DELAY);
  };

  const handleCloseLogin = () => (multiRoom ? setClubRate(false) : handleSelect());

  const scrollTo = (value = 0) => window.scrollTo({ top: value, behavior: 'smooth' });

  const scrollToRoom = (roomId) => {
    const { top } = refItems?.current[roomId]?.getBoundingClientRect() || {};
    if (top) scrollTo(top + window.scrollY - (isMobile ? 65 : 16 * 5));
  };

  const { contentBackground } = Theme.get();
  const { days: nights } = dateDiff(calendar[0], calendar[1]);
  const response = dataSource !== undefined;
  const roomSelectorProps = { cart, occupation, room, onPress: setRoom };
  const features = getFeatures(hotel.features, urlParams, { isDesktop });
  const priceFactor = features.showPricePerNight && nights > 1 ? nights : 1;
  const uniqueOption = (dataSource?.items || [])
    .filter(({ soldOut } = {}) => !soldOut)
    .every(
      ({ boards = [], cancellations = [], rates = [] }) =>
        boards.length === 1 && cancellations.length === 1 && rates.length === 1,
    );

  return (
    <>
      <Header currency login={club.active} name={features.showNameInHeader} skeleton={skeleton} />

      <View
        ref={refRoomSelector}
        className={styles(style.finder, style.blur, (!multiRoom || responseError) && style.sticky)}
        style={!isMobile ? { backgroundColor: opacity(contentBackground, 0.66) } : undefined}
      >
        <View row={isMobile} className={style.wrapper}>
          <Finder
            compacted={!isDesktop}
            icon
            metrics={`${dataSource?.hotels ? 'HOTELS' : responseError ? 'UNAVAILAVILITY' : 'RATES'}:FINDER`}
            onSubmit={handleFinder}
            testId={testId ? `${testId}-finder` : undefined}
          />

          {!responseError && dataSource?.items && (
            <Filters {...dataSource} {...{ values: filters, priceFactor }} onSubmit={handleFilters} />
          )}
        </View>
      </View>

      <Progress indeterminate visible={fetching && !responseError} className={style.progress} />

      {isMobile && !responseError && (
        <RoomSelector {...roomSelectorProps} testId={testId ? `${testId}-multiroom` : undefined} />
      )}

      <View role="rates" testId={testId} className={styles(style.container, others.className)}>
        <View className={[style.wrapper, style.content]}>
          {!responseError && <Content {...dataSource} {...others} skeleton={skeleton} />}

          {(response || (multiRoom && !responseError)) && (
            <RatesHeader
              {...{
                dataSource,
                filters,
                multiRoom,
                priceFactor,
                onMapView: setMapView,
                onSelect: handleSelect,
                onTwin: () => {
                  setFilterBoard();
                  setMetaBoard();
                  setTwin(true);
                },
              }}
            >
              {!isMobile ? (
                <RoomSelector {...roomSelectorProps} testId={testId ? `${testId}-multiroom` : undefined} />
              ) : undefined}
            </RatesHeader>
          )}

          {response ? (
            <Cards>
              {select(dataSource.items, filters, { priceFactor })?.map(({ id, ...item }, index) => (
                <Item
                  {...{
                    ...filterItem(item, filters, priceFactor),
                    cart,
                    features,
                    filters,
                    metaBoard,
                    id,
                    multiRoom,
                    nights,
                    priceFactor,
                    room,
                    taxesType: dataSource.taxesType,
                    uniqueOption,
                  }}
                  key={`${id || index}:${fetching}:${width}`}
                  ref={(el) => (refItems.current[id] = el)}
                  selected={cart[room || 0]}
                  onCancellationInfo={(rate) => setCancellationInfo({ ...rate, name: item.name })}
                  onMoreInfo={() => setItemInfo(item)}
                  onOfferInfo={(rate) => setOfferInfo(rate)}
                  onSelect={handleSelect}
                  onViewUrl={(info) => setItemUrl(info)}
                  testId={testId ? `${testId}-item-${index}` : undefined}
                />
              ))}

              {dataSource.hotels?.map((item = {}) => (
                <CardHotel key={item.id} {...{ ...item, nights, priceFactor }} />
              ))}
            </Cards>
          ) : responseError ? (
            responseError.hotel || responseError.tracking?.hotelInfoBAR?.length === 0 ? (
              <Unavailability {...{ ...responseError, nights, priceFactor }} onMapView={setMapView} />
            ) : (
              <Notification error large className={style.error}>
                {translate(L10N.NOTIFICATION_ERROR[responseError.code || ERROR.UNKNOWN])}
              </Notification>
            )
          ) : (
            <Skeleton multiRoom={multiRoom} />
          )}
        </View>

        <RatesFooter
          {...{ cart: multiRoom ? cart : [], clubRate, dataSource, extras, multiRoom, occupation }}
          onSubmit={() => (multiRoom ? handleSubmit(cart) : undefined)}
          testId={testId ? `${testId}-footer` : undefined}
        />
      </View>

      {response && (
        <>
          <ModalItemInfo
            {...{ ...itemInfo, features, room }}
            visible={!!itemInfo}
            onClose={() => setItemInfo()}
            onViewUrl={(info) => setItemUrl(info)}
          />
          <ModalCancellationInfo
            {...cancellationInfo}
            visible={!!cancellationInfo}
            onClose={() => setCancellationInfo()}
          />
          <ModalItemUrl {...itemUrl} visible={!!itemUrl} onClose={() => setItemUrl()} />
          <ModalOfferInfo {...{ ...offerInfo }} visible={!!offerInfo} onClose={() => setOfferInfo()} />
          <ModalTwin
            {...{
              ...itemInfo,
              dataSource,
              cart,
              features,
              filters,
              nights,
              priceFactor,
              room,
            }}
            visible={twin}
            onBook={handleSelect}
            onClose={() => setTwin()}
            onFilter={handleFilters}
            onSelect={setFilterBoard}
          />
          <ModalExtras
            {...{ ...dataSource, busy, cart, extras }}
            visible={!!extras}
            onCancel={() => (multiRoom ? setExtras() : handleSelect())}
            onSubmit={(extras) => handleCheckout(cart, extras)}
            testId={testId ? `${testId}-modalExtras` : undefined}
          />
          {club.active && dataSource.items?.length > 0 && (
            <ModalClubRate
              dataSource={dataSource}
              cart={cart}
              visible={clubRate}
              onClose={handleCloseLogin}
              onSubmit={(session) => handleSubmit(cart, session)}
            />
          )}
        </>
      )}

      <ModalMapView items={mapView} nights={nights} visible={!!mapView} onClose={() => setMapView()} />

      <Footer />
    </>
  );
};

Rates.displayName = 'Mirai:Core:Rates';

Rates.propTypes = {
  testId: PropTypes.string,
};

export { Rates };
