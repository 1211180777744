import { BookingQuery, HotelInfo, Offers, Unknown } from './intents';

const INTENT = {
  BOOKING_CREATE: undefined,
  BOOKING_CANCEL: undefined,
  BOOKING_UPDATE: undefined,
  BOOKING_QUERY: BookingQuery,
  FAREWELLS: undefined,
  GREETINGS: undefined,
  HOTEL_INFO: HotelInfo,
  OFFERS: Offers,
};

const INTENT_UNKNOWN = Unknown;

export { INTENT, INTENT_UNKNOWN };
