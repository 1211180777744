import { toUrlParams } from './toUrlParams';
import { DEFAULT_HSRI, ENDPOINT } from '../ButtonPayment.constants';

export const getUrlCriptan = ({ criptanNumOrder, environment, id, locale, tracking, urlParams: { hsri = DEFAULT_HSRI, variant } = {} }) => {
  if (!criptanNumOrder || !id || !locale) return;

  return `${process.env.SERVICE_BOOKING}/${ENDPOINT.CRIPTAN}${toUrlParams({
    idTokenProvider: id,
    env: environment,
    hsri,
    lang: locale.split('-')[0],
    numOrder: criptanNumOrder,
    variant,
    version: 2,
    tracking,
  })}`;
};
