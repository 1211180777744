.table {
  width: 100%;
}

.table.collapsed {
  padding-bottom: var(--mirai-ui-space-XS);
}

.buttonMore {
  box-shadow: 0 var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  padding: var(--mirai-ui-space-XS);
  top: 0;
  z-index: 1;
}

.row {
  position: relative;
  width: 100%;
}

.row:not(:last-child) {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
}

.lineThrough {
  text-decoration: line-through;
}

.separator {
  margin: 0 var(--mirai-ui-space-XXS);
}

.caption {
  color: var(--mirai-ui-content-light);
}

.action {
  color: var(--mirai-ui-accent);
  text-decoration: underline;
}

.action:hover,
.action:active {
  color: var(--mirai-ui-content);
}

/* S */
@media only screen and (max-width: 480px) {
  .row {
    padding-bottom: var(--mirai-ui-space-XS);
    padding-top: var(--mirai-ui-space-S);
  }

  .title .subtitle {
    margin-top: var(--mirai-ui-space-XXS);
  }

  .wide {
    flex: 1;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .row {
    align-items: flex-start;
  }

  .row > * {
    padding: var(--mirai-ui-table-padding-y) 0;
  }

  .title {
    width: 40%;
  }

  .summary {
    width: 45%;
  }

  .price {
    width: 15%;
    align-items: flex-end;
  }
}

@media print {
  .noPrint {
    display: none;
  }

  .row {
    align-items: flex-start;
    border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  }

  .row > * {
    padding: calc(var(--mirai-ui-table-padding-y) / 2) 0;
  }

  .title {
    width: 40%;
  }

  .summary {
    width: 45%;
  }

  .price {
    width: 15%;
    align-items: flex-end;
  }
}
