.caption {
  flex: 1;
  overflow: hidden;
  text-align: var(--mirai-ui-text-align);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.caption.placeholder {
  color: var(--mirai-ui-input-disabled);
}

.field {
  align-items: center;
  align-content: center;
  background-color: var(--mirai-ui-input-background);
  border-radius: var(--mirai-ui-input-text-radius);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: var(--mirai-ui-row-direction);
  gap: calc(var(--mirai-ui-input-text-padding-x) / 2);
  height: var(--mirai-field-height);
  justify-content: flex-start;
  padding: 0 var(--mirai-ui-input-text-padding-x);
  z-index: 0;
}

.field:not(.inline) {
  border: var(--mirai-ui-input-text-border);
  transition: border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.field:not(.inline).focus {
  border-color: var(--mirai-ui-focus-color);
  box-shadow: var(--mirai-ui-focus-shadow);
}

.field:not(.inline).hover {
  border-color: var(--mirai-ui-focus-color);
}

.field.inline.focus,
.field.inline.hover {
  background-color: var(--mirai-ui-content-background);
}

.icon {
  font-size: var(--mirai-ui-input-text-icon);
  height: var(--mirai-ui-input-text-icon);
  line-height: var(--mirai-ui-input-text-icon);
  width: var(--mirai-ui-input-text-icon);
}

.icon.context {
  color: var(--mirai-field-icon-color);
}

.icon.error {
  color: var(--mirai-ui-error);
}

.icon.warning {
  color: var(--mirai-ui-warning);
}

.label {
  color: var(--mirai-field-label-color);
}

.input {
  flex: 1;
}

.layerContent {
  margin: var(--mirai-ui-input-text-padding-y) 0;
  background-color: var(--mirai-ui-input-background);
  border: var(--mirai-ui-input-text-border);
  border-radius: var(--mirai-ui-input-text-radius);
  box-shadow: var(--mirai-ui-shadow);
}

/* S */
@media only screen and (max-width: 480px) {
  .modal {
    max-height: var(--mirai-modal-max-height);
  }

  .field {
    width: auto;
  }

  .input {
    max-width: 90%;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .caption {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .field:not(.hasPlaces) .caption {
    max-width: 25vw;
  }

  .field.hasPlaces .caption {
    max-width: 15vw;
  }
}
