import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Action, Button, Icon, ScrollView, Switch, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { DEFAULT_LOCALE, BENEFIT_MIN_WIDTH } from './BannerLoyalty.constants';
import { L10N } from './BannerLoyalty.l10n';
import * as style from './BannerLoyalty.module.css';
import Skeleton from './BannerLoyalty.skeleton';
import { EVENT, getNormalizedLocale, ICON } from '../helpers';

const BannerLoyalty = ({ clubConditions, skeleton, ...others }) => {
  const { isDesktop, isMobile } = useDevice();
  const { locale: stateLocale, translate } = useLocale();
  const {
    value: { club: { banner: { benefits = [], description = {}, title, url } = {}, discount } = {}, session },
  } = useStore();

  const [expanded, setExpanded] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [clubDiscount, setClubDiscount] = useState(discount);

  useEffect(() => {
    const callback = (nextClubDiscount) => setClubDiscount(nextClubDiscount);

    Event.subscribe(EVENT.RATES_FILTER_CLUB, callback);

    return () => Event.unsubscribe(EVENT.RATES_FILTER_CLUB, callback);
  }, []);

  useEffect(() => {
    if (!session && title && benefits.length > 0) Event.publish(EVENT.METRICS, { id: 'BANNER_LOYALTY:RENDER' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const handleExpanded = () => {
    if (!expanded) Event.publish(EVENT.METRICS, { id: 'BANNER_LOYALTY:EXPAND_BENEFITS' });
    setExpanded(!expanded);
  };

  const handlePressBenefit = (index) => {
    setScrollIndex(index);
    Event.publish(EVENT.METRICS, { id: 'BANNER_LOYALTY:BUTTON_BENEFITS' });
  };

  const handlePressFilter = (value) => {
    Event.publish(EVENT.RATES_FILTER_CLUB, value);
    Event.publish(EVENT.METRICS, { id: 'BANNER_LOYALTY:CLUBDISCOUNT', value });
  };

  const Filter = () => (
    <View row className={style.filter}>
      <Text action medium>
        {translate(L10N.LABEL_ONLY_MEMBERS)}
      </Text>
      <Switch checked={clubDiscount} name={`switch`} onChange={handlePressFilter} />
    </View>
  );

  const buttonProps = { secondary: true, squared: true, rounded: true };
  const lastBenefit = scrollIndex === benefits.length - 4;
  const locale = getNormalizedLocale(stateLocale);

  return skeleton && !session ? (
    <Skeleton />
  ) : !session && title && benefits.length > 0 ? (
    <View testId={others.testId} className={[style.container, others.className]}>
      <View row className={style.highlight}>
        <View>
          <Text bold brand headline level={isDesktop ? 2 : 3} className={style.title}>
            {title[locale] || title[DEFAULT_LOCALE]}
          </Text>
          <Text action={isDesktop} small={!isDesktop}>
            {description[locale] || description[DEFAULT_LOCALE]}
            {url && (
              <>
                {' '}
                <Action
                  action={isDesktop}
                  bold
                  small={!isDesktop}
                  href={url}
                  target="_blank"
                  className={style.textInline}
                >
                  {translate(L10N.ACTION_MORE_INFO)}
                </Action>
              </>
            )}
          </Text>
          {clubConditions && (
            <Text action={isDesktop} small={!isDesktop}>
              {translate(L10N.LABEL_CLUB_CONDITIONS, {
                conditions: (
                  <Action
                    action={isDesktop}
                    small={!isDesktop}
                    href={clubConditions}
                    target="_blank"
                    className={style.textInline}
                  >
                    {translate(L10N.ACTION_CLUB_VIEW_CONDITIONS)}
                  </Action>
                ),
              })}
            </Text>
          )}
        </View>

        {isMobile ? (
          <Button small squared transparent onPress={handleExpanded}>
            <Icon value={expanded ? ICON.EXPAND_LESS : ICON.EXPAND_MORE} />
          </Button>
        ) : (
          <Filter />
        )}
      </View>

      {(!isMobile || expanded) && (
        <View row wide>
          {!isMobile && scrollIndex !== 0 && (
            <Button {...buttonProps} onPress={() => handlePressBenefit(scrollIndex - 1)}>
              <Icon value={ICON.LEFT} />
            </Button>
          )}

          {React.createElement(
            isMobile ? View : ScrollView,
            {
              ...(!isMobile ? { horizontal: !isMobile, scrollTo: scrollIndex * BENEFIT_MIN_WIDTH } : {}),
              className: style.list,
            },
            benefits.map(({ description = {}, icon } = {}, index) => (
              <View row key={index}>
                <Icon accent headline level={!isDesktop ? 3 : 2} value={ICON[icon] || ICON.AMENITY} />
                <Text action={isDesktop} small={!isDesktop}>
                  {description[locale] || description[DEFAULT_LOCALE]}
                </Text>
              </View>
            )),
          )}

          {!isMobile && benefits.length > 4 && (
            <Button {...buttonProps} onPress={() => handlePressBenefit(lastBenefit ? 0 : scrollIndex + 1)}>
              <Icon value={lastBenefit ? ICON.REPLAY : ICON.RIGHT} />
            </Button>
          )}
        </View>
      )}

      {isMobile && <Filter />}
    </View>
  ) : null;
};

BannerLoyalty.displayName = 'Mirai:Core:BannerLoyalty';

BannerLoyalty.propTypes = {
  clubConditions: PropTypes.string,
  skeleton: PropTypes.bool,
};

export { BannerLoyalty };
