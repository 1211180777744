import { Icon, Layer, LayerContent, Modal, Pressable, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';

import * as style from './Field.module.css';
import { ICON } from '../../helpers';

const Field = ({
  caption,
  children,
  compacted = false,
  error = false,
  hasPlaces = false,
  icon,
  inline = false,
  inputMode = false,
  label,
  placeholder,
  visible,
  warning = false,
  onPress = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();

  const [hover, setHover] = useState(false);

  const Wrapper = !isMobile ? Layer : Fragment;
  const { bottom, centered, testId, top } = others;

  return (
    <Wrapper
      {...(!isMobile
        ? {
            bottom,
            centered,
            top,
            visible,
            className: style.layerContent,
          }
        : undefined)}
    >
      {!compacted && (
        <Pressable
          {...others}
          role="field"
          onPress={onPress}
          onEnter={() => setHover(true)}
          onLeave={() => setHover(false)}
          className={styles(
            style.field,
            hasPlaces && style.hasPlaces,
            inline && style.inline,
            visible && style.focus,
            hover && style.hover,
            others.className,
          )}
        >
          {icon && <Icon value={icon} className={[style.icon, style.context]} />}

          <View className={style.input}>
            {label && (
              <Text small className={style.label}>
                {label}
              </Text>
            )}
            {inputMode ? (
              children
            ) : (
              <Text action className={styles(style.caption, !caption && placeholder && style.placeholder)}>
                {caption || placeholder}
              </Text>
            )}
          </View>

          {(error || warning) && (
            <Icon
              value={error ? ICON.ERROR : ICON.WARNING}
              className={styles(style.icon, error ? style.error : style.warning)}
            />
          )}
        </Pressable>
      )}

      {children &&
        !inputMode &&
        React.createElement(
          isMobile ? Modal : LayerContent,
          isMobile
            ? {
                portal: true,
                title: label,
                visible,
                onClose: onPress,
                onOverflow: onPress,
                className: style.modal,
                ['testId']: testId ? `${testId}-modal` : undefined,
              }
            : undefined,
          children,
        )}
    </Wrapper>
  );
};

Field.displayName = 'Mirai:Core:Field';

Field.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
  compacted: PropTypes.bool,
  error: PropTypes.bool,
  hasPlaces: PropTypes.bool,
  icon: PropTypes.func,
  inline: PropTypes.bool,
  inputMode: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
  visible: PropTypes.bool,
  warning: PropTypes.bool,
  onPress: PropTypes.func,
};

export { Field };
