import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceUser } from '@mirai/services';
import { Button, Form, InputText, Notification, Text } from '@mirai/ui';
import React, { useEffect, useState } from 'react';

import { L10N } from './Settings.l10n';
import * as style from './Settings.module.css';
import { TextRequiredFields } from '../../../__shared__';
import { EVENT, testPassword } from '../../../helpers';

const Password = (others) => {
  const { translate } = useLocale();
  const { value: { session: { club: { id: idClub } = {}, email } = {} } = {}, set } = useStore();

  const [busy, setBusy] = useState(false);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    setResponseError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleSubmit = async (form) => {
    setBusy(true);
    setResponseError(undefined);

    const { password } = form;
    const response = await ServiceUser.update({ password }).catch((error) => {
      setResponseError(error);
    });

    if (response) {
      Event.publish(EVENT.NOTIFICATION, { success: true, ...L10N.SUCCESS_PASSWORD });

      const session = await ServiceUser.login({ email, idClub, password }).catch((error) => {
        setResponseError(error);
      });
      set({ session });
    }

    setBusy(false);
  };

  const has = {
    errors: Object.keys(formError).length !== 0 || form.password !== form.rePassword,
    responseError,
  };

  return (
    <Form
      {...others}
      validateOnMount
      onChange={setForm}
      onError={setFormError}
      onSubmit={handleSubmit}
      className={style.form}
    >
      <Text bold headline wide>
        {translate(L10N.DESCRIPTION_PASSWORD)}
      </Text>

      <TextRequiredFields />

      <InputText
        name="password"
        error={form.password?.length && formError.password?.test}
        hint={translate(L10N.PASSWORD_HINT)}
        label={translate(L10N.PASSWORD_NEW_LABEL)}
        required
        showRequired
        success={form.password?.length && !formError.password}
        test={testPassword}
        type="password"
        value={form.password}
      />

      <InputText
        name="rePassword"
        error={form.rePassword?.length && (formError.rePassword?.test || form.rePassword !== form.password)}
        hint={translate(L10N.PASSWORD_HINT)}
        label={translate(L10N.PASSWORD_NEW_CONFIRM_LABEL)}
        required
        showRequired
        success={form.rePassword?.length && form.rePassword === form.password}
        test={testPassword}
        type="password"
        value={form.rePassword}
      />

      {has.responseError && (
        <Notification error wide className={style.notification}>
          {translate(L10N.ERROR)}
        </Notification>
      )}

      <Button busy={busy} disabled={busy || has.errors} wide type="submit" testId="button-password">
        {translate(L10N.CTA_PASSWORD)}
      </Button>
    </Form>
  );
};

Password.propTypes = {};

export { Password };
