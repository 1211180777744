import { styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Skeleton.module.css';

const Skeleton = ({ children, color = 'background', ...others }) => {
  const isText = typeof children === 'string';

  return React.createElement(
    isText ? Text : View,
    { ...others, className: styles(style.skeleton, color && style[color], isText && style.text, others.className) },
    children,
  );
};

Skeleton.displayName = 'Mirai:Core:Skeleton';

Skeleton.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
};

export { Skeleton };
