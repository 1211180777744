import { Event, useStore } from '@mirai/data-sources';
import { currencySymbol, useLocale } from '@mirai/locale';
import { Action, Button, Modal, ScrollView, styles, useDevice, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { InputRange } from './components';
import { Amenity } from './Filters.Amenity';
import { BookingOption } from './Filters.BookingOption';
import { Button as ButtonFilters } from './Filters.Button';
import { BOOKING_OPTIONS, DEFAULT_FORM, MAX_BOARDS } from './Filters.constants';
import { L10N } from './Filters.l10n';
import * as style from './Filters.module.css';
import { countFilters, getAvailableFilters, getStrictSearch } from './helpers';
import { EVENT, ICON, SUBMIT_DELAY } from '../../../helpers';
import { fetchRates, select } from '../../helpers';
import { Option } from '../Item/components';

let memoizeForm;
let timeoutRange;

const Filters = ({ clubConditions, items: propItems = [], priceFactor = 1, onSubmit = () => {}, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      currency,
      club = {},
      finder = {},
      hotel,
      id,
      locale,
      occupation: { maxRooms } = {},
      session,
      urlParams: { idoffers, roomTypeId } = {},
    } = {},
  } = useStore();

  const { discount } = club;
  const { promocode } = finder;

  const [allAmenities, setAllAmenities] = useState(false);
  const [busy, setBusy] = useState(false);
  const [counter, setCounter] = useState(0);
  const [filters, setFilters] = useState(getAvailableFilters(propItems, { priceFactor }));
  const [form, setForm] = useState(DEFAULT_FORM);
  const [items, setItems] = useState(propItems);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const nextForm = memoizeForm || {
      ...DEFAULT_FORM,
      clubDiscount: !session && discount !== undefined ? !!discount : undefined,
      offer: idoffers ? !!idoffers : undefined,
      promocode: promocode ? !!promocode : undefined,
      roomType: roomTypeId ? !!roomTypeId : undefined,
    };

    setForm(nextForm);
    setCounter(countFilters(nextForm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!form.priceRange) return;
    const nextForm = { ...form, priceRange: undefined };

    setForm(nextForm);
    setCounter(countFilters(nextForm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  useEffect(() => {
    const callback = (clubDiscount) => {
      const nextForm = { ...form, clubDiscount };
      setForm(nextForm);
      setCounter(countFilters(nextForm));
    };

    Event.subscribe(EVENT.RATES_FILTER_CLUB, callback);

    return () => Event.unsubscribe(EVENT.RATES_FILTER_CLUB, callback);
  }, [form]);

  useEffect(() => {
    if (JSON.stringify(propItems) !== JSON.stringify(items)) setItems(propItems);

    const nextFilters = getAvailableFilters(propItems, { priceFactor });
    if (JSON.stringify(nextFilters) !== JSON.stringify(filters)) setFilters(nextFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propItems]);

  const handleModal = () => {
    let next = !visible;

    setVisible(next);
    if (next) Event.publish(EVENT.METRICS, { id: 'RATES:FILTERS', counter });
  };

  const handleAmenity = (id, value) => {
    setForm({ ...form, amenities: value ? [...form.amenities, id] : form.amenities.filter((item) => item !== id) });
    Event.publish(EVENT.METRICS, { id: 'RATES:FILTERS:AMENITY', amenity: id, value });
  };

  const handleOption = (field, id) => {
    setForm({ ...form, [field]: form[field] === id ? undefined : id });
    Event.publish(EVENT.METRICS, { id: `RATES:FILTERS:${field}` });
  };

  const handleRange = (field, value) => {
    setForm({ ...form, [field]: value });

    clearTimeout(timeoutRange);
    timeoutRange = setTimeout(
      () => Event.publish(EVENT.METRICS, { id: `RATES:FILTERS:${field}`, value }),
      SUBMIT_DELAY / 2,
    );
  };

  const handleBookingOption = async (field, value) => {
    const next = { ...form, [field]: value };
    setForm(next);

    Event.publish(EVENT.METRICS, { id: `RATES:FILTERS:${field}`, value });
    if (field !== 'freeCancellation') await fetch(next);
  };

  const handleClearAll = () => {
    const { clubDiscount, offer, promocode, roomType } = form || {};
    setAllAmenities(false);
    setForm(DEFAULT_FORM);

    (clubDiscount || offer || promocode || roomType) && fetch(DEFAULT_FORM);
    Event.publish(EVENT.METRICS, { id: 'RATES:FILTERS:CLEAR_ALL' });
  };

  const handleSubmit = () => {
    memoizeForm = form;
    setVisible(false);
    const nextCounter = countFilters(form);
    setCounter(nextCounter);
    onSubmit({ ...form, strictSearch: getStrictSearch(form) });
    Event.publish(EVENT.RATES_FILTER_CLUB, form.clubDiscount);
    Event.publish(EVENT.METRICS, { id: 'RATES:FILTERS:SUBMIT', counter: nextCounter });
  };

  const fetch = async (next) => {
    setBusy(true);

    const { clubDiscount } = next;
    const strictSearch = getStrictSearch(next);
    const cart = []; // ! TODO: [MF-2006] Store should get the current cart of <Rates>
    const params = { cart, club, clubDiscount, finder, hotel, id, locale, maxRooms, session, strictSearch };
    const { items: nextItems } = (await fetchRates(params).catch(() => {})) || {};
    setItems(nextItems);
    if (nextItems) setFilters(getAvailableFilters(nextItems, { priceFactor }));

    setBusy(false);
  };

  const has = {
    clubDiscount: (discount !== undefined || clubConditions !== undefined) && !session,
    freeCancellation: true || !!filters.freeCancellation, // * We'll activate freeCancellation filter always.
    offer: !!idoffers,
    promocode: !!promocode,
    roomType: !!roomTypeId,
  };

  const filteredItems = select(items, form, { priceFactor }).filter(({ availability = 0 } = {}) => availability > 0);
  const { length: boardsLength } = filters.boards;
  const inlineBoards = !isMobile || (isMobile && boardsLength <= MAX_BOARDS - 1);
  const { name: roomTypeName } = items?.find(({ id } = {}) => id === parseInt(roomTypeId)) || {};

  return (
    <>
      <ButtonFilters {...{ counter }} onPress={handleModal} />

      <Modal
        fit
        portal
        title={translate(L10N.LABEL_FILTERS)}
        visible={visible}
        onClose={handleModal}
        className={[style.modal, others.className]}
      >
        <ScrollView scrollIndicator scrollTo={visible ? 0.5 : undefined} snap={false} className={style.scrollView}>
          <View className={style.section}>
            <Text bold headline>
              {translate(L10N.LABEL_BOOKING_OPTIONS)}
            </Text>

            {has.clubDiscount && (
              <BookingOption
                caption={
                  clubConditions
                    ? translate(L10N.LABEL_CLUB_CONDITIONS, {
                        conditions: (
                          <Action small href={clubConditions} target="_blank" className={style.textInline}>
                            {translate(L10N.ACTION_CLUB_VIEW_CONDITIONS)}
                          </Action>
                        ),
                      })
                    : translate(L10N.LABEL_CLUB_BENEFITS)
                }
                checked={form.clubDiscount}
                club={club.name}
                field="clubDiscount"
                title={L10N.LABEL_CLUB_MEMBERS_EXCLUSIVE}
                onChange={(field, value) => handleBookingOption(field, value)}
              />
            )}

            {BOOKING_OPTIONS.filter(({ field } = {}) => has[field]).map(({ field, title } = {}) => (
              <BookingOption
                key={field}
                checked={form[field]}
                {...{ field, title, roomName: roomTypeName, promocode: promocode?.toUpperCase() }}
                onChange={handleBookingOption}
              />
            ))}
          </View>

          {boardsLength > 1 && (
            <View className={style.section}>
              <Text bold headline>
                {translate(L10N.LABEL_ACCOMMODATION_PLAN)}
              </Text>

              <View row={inlineBoards} className={styles(style.boards, inlineBoards && style.inline)}>
                {filters.boards.map(({ id, text, type }) => (
                  <Option
                    checked={form.board === type}
                    key={`board:${id}`}
                    inline={inlineBoards}
                    outlined
                    title={text}
                    onPress={() => handleOption('board', type)}
                    className={style.option}
                  />
                ))}
              </View>
            </View>
          )}

          {filters.prices.length > 1 && (
            <InputRange
              caption={translate(priceFactor === 1 ? L10N.LABEL_SET_PRICE_RANGE : L10N.LABEL_NIGHTLY_PRICES)}
              dataSource={filters.prices}
              resolution={isMobile ? 12 : 24}
              title={translate(L10N.LABEL_PRICE_RANGE)}
              unit={currencySymbol(currency, { locale })}
              value={form.priceRange}
              onChange={(value) => handleRange('priceRange', value)}
              className={style.section}
            />
          )}

          <View className={style.section}>
            <Text bold headline wide>
              {translate(L10N.LABEL_AMENITIES)}
            </Text>

            <View>
              <View row className={style.amenities}>
                {filters.amenities
                  .filter((item) => item.highlight)
                  .map(({ id, text }) => (
                    <Amenity
                      checked={form.amenities.includes(id)}
                      id={id}
                      key={id}
                      label={text}
                      onChange={handleAmenity}
                    />
                  ))}
              </View>

              {allAmenities ? (
                <>
                  <Text action bold wide className={style.textOthers}>
                    {translate(L10N.LABEL_OTHERS)}
                  </Text>

                  <View row className={style.amenities}>
                    {filters.amenities
                      .filter((item) => !item.highlight)
                      .map(({ id, text }) => (
                        <Amenity
                          checked={form.amenities.includes(id)}
                          id={id}
                          key={id}
                          label={text}
                          onChange={handleAmenity}
                        />
                      ))}
                  </View>
                </>
              ) : (
                <Action underline onPress={() => setAllAmenities(true)}>
                  {translate(L10N.ACTION_VIEW_MORE)}
                </Action>
              )}
            </View>
          </View>

          {filters.bedTypes.length > 1 && (
            <View className={style.section}>
              <Text bold headline wide>
                {translate(L10N.LABEL_BED_TYPE)}
              </Text>

              <View row>
                {filters.bedTypes.map((bedType) => (
                  <Option
                    key={`bedType:${bedType}`}
                    checked={form.bedType === bedType}
                    icon={bedType === 1 ? ICON.BED_SINGLE : ICON.BED_DOUBLE}
                    inline
                    outlined
                    title={translate(bedType === 1 ? L10N.LABEL_BED_TYPE_SINGLE : L10N.LABEL_BED_TYPE_DOUBLE)}
                    onPress={() => handleOption('bedType', bedType)}
                    className={style.option}
                  />
                ))}
              </View>
            </View>
          )}

          {filters.sizes.length > 1 && (
            <InputRange
              dataSource={filters.sizes}
              resolution={isMobile ? 4 : 6}
              title={translate(L10N.LABEL_ROOM_SIZE)}
              unit={filters.sizeUnit}
              value={form.sizeRange}
              onChange={(value) => handleRange('sizeRange', value)}
              className={style.section}
            />
          )}
        </ScrollView>

        <View row className={[style.footer, style.spaceBetween]}>
          <Button transparent onPress={handleClearAll}>
            {translate(L10N.ACTION_CLEAR_ALL)}
          </Button>
          <Button busy={busy} disabled={!filteredItems.length} onPress={handleSubmit}>
            {translate(filteredItems.length ? L10N.LABEL_SHOW_COUNT_RESULTS : L10N.LABEL_NO_EXACT_MATCHES, {
              count: filteredItems.length,
            })}
          </Button>
        </View>
      </Modal>
    </>
  );
};

Filters.displayName = 'Mirai:Core:Rates:Filters';

Filters.propTypes = {
  clubDiscount: PropTypes.bool,
  clubConditions: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  priceFactor: PropTypes.number,
  onSubmit: PropTypes.func,
};

export { Filters };
