import { useStore } from '@mirai/data-sources';
import { View } from '@mirai/ui';
import React, { useEffect, useState } from 'react';

import { Bookings, Settings } from './components';
import { CONTEXT, KEY } from './Profile.constants';
import * as style from './Profile.module.css';

const Profile = ({ ...others }) => {
  const {
    value: { urlParams = {} },
  } = useStore();

  const [context, setContext] = useState();

  useEffect(() => {
    const key = urlParams.context || urlParams.tab;
    if (!key) return;

    setContext(KEY[key]);
  }, [urlParams]);

  return (
    <View testId={others.testId} className={[style.container, others.className]}>
      {context === CONTEXT.SETTINGS && <Settings />}
      {context === CONTEXT.BOOKINGS && <Bookings />}
    </View>
  );
};

Profile.displayName = 'Mirai:Core:Profile';

Profile.propTypes = {};

export { Profile };
