import { Theme } from '@mirai/ui';

const ITEMS_PER_COLUMN = 18;
const ITEM_WIDTH = '300px';
const MAX_COLUMNS = 3;
const THEME_KEY = '--mirai-places';

const sanitizePx = (value = '') => parseInt(value.replace('px', ''), 10);

export const calcColumns = ({ dataSource: { length: items } = [], device: { width: deviceWidth } = {} } = {}) => {
  const { ItemsPerColumn: itemsPerColumn = ITEMS_PER_COLUMN, ItemWidth: itemWidth = ITEM_WIDTH } = Theme.get(THEME_KEY);

  let columns = items > itemsPerColumn ? Math.floor(items / itemsPerColumn) : undefined;
  if (columns * sanitizePx(itemWidth) > deviceWidth * 0.8) columns = Math.floor(deviceWidth / sanitizePx(itemWidth));
  if (columns >= MAX_COLUMNS) columns = MAX_COLUMNS;

  return columns;
};
