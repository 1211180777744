import { Event, useStore } from '@mirai/data-sources';
import { ServiceLisa } from '@mirai/services';
import { Modal, ScrollView, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ButtonAvatar } from './Chat.ButtonAvatar';
import { DELAY_RESPONSE } from './Chat.constants';
import { Header } from './Chat.Header';
import * as style from './Chat.module.css';
import { Input, InputRich, Message } from './components';
import { audioNotification } from './helpers';
import { EVENT } from '../helpers';

let connectionInterval;

const Chat = ({ skeleton, ...others }) => {
  const { isMobile } = useDevice();
  const {
    set,
    value: { calendar, components, currency, hotel, locale, occupation, origin, session, tags, type },
  } = useStore();

  const [busy, setBusy] = useState(false);
  const [messages, setMessages] = useState([]);
  const [online, setOnline] = useState(false);
  const [scrollTo, setScrollTo] = useState();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (skeleton) return;

    (async () => {
      if (!(await handleOnline())) return;
      const response = await ServiceLisa.welcome({ context: { hotel }, locale, session }).catch(handleError);
      if (response) addMessage(response, messages);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skeleton]);

  useEffect(() => {
    if (visible) setScrollTo(new Date().getTime());
  }, [busy, messages, visible]);

  useEffect(() => {
    // // ! TODO: It's just a demo
    // if (visible && !messages.length) handleInput('I would like to see all my reservations.');
    if (!visible) return clearInterval(connectionInterval);

    connectionInterval = setInterval(handleOnline, online ? 60000 : 10000);
    return () => clearInterval(connectionInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, visible]);

  const storeContext = {
    calendar,
    currency,
    hotel,
    messages: messages.filter(({ intent, text }) => !!intent || !!text).map(({ intent, text }) => ({ intent, text })),
    occupation,
    origin,
    tags,
    type,
  };

  const handleOnline = async () => {
    if (!(components.finder || components.rates)) return;

    let next = false;
    const response = await ServiceLisa.status().catch(() => setOnline(next));
    if (response && !online) {
      next = true;
      setOnline(next);
    }

    return next;
  };

  const handleInput = (input) => {
    const nextMessages = [...messages, { text: input, timestamp: new Date().getTime() }];
    setMessages(nextMessages);

    setTimeout(async () => {
      setBusy(true);
      const response = await ServiceLisa.message({ context: storeContext, input, locale, session }).catch(handleError);
      if (response) {
        addMessage(response, nextMessages);
        audioNotification();
      }
      setBusy(false);
    }, DELAY_RESPONSE);
  };

  const handleAction = ({ context, form, intent } = {}) => {
    if (intent === 'ACTION_LOGIN') return Event.publish(EVENT.LOGIN, { visible: true });
    setBusy(true);

    setTimeout(async () => {
      const response = await ServiceLisa.action({
        context: { ...storeContext, ...context, form },
        intent,
        locale,
        session,
      }).catch(handleError);

      if (response) {
        set({ lisa: response });
        setMessages([...messages, { auto: true, ...response }]);
        audioNotification();
      }

      setBusy(false);
    }, DELAY_RESPONSE);
  };

  const handleError = (error) => {
    // eslint-disable-next-line no-console
    console.error('::handleError::', error);
    setOnline(false);
  };

  const addMessage = (response = {}, messages = []) => {
    set({ lisa: response });
    setMessages([...messages, { auto: true, ...response }]);
    if (response.locale !== locale) Event.publish(EVENT.INTENT_LOCALE, { locale: response.locale });
  };

  return !skeleton ? (
    <>
      {React.createElement(
        isMobile ? Modal : View,
        isMobile
          ? { ...others, visible, className: styles(style.container, others.className) }
          : {
              ...others,
              className: styles(style.container, visible && style.visible, others.className),
              'aria-hidden': visible ? 'false' : 'true',
            },
        <>
          <Header {...{ online }} onClose={() => setVisible(false)} />

          <ScrollView scrollTo={scrollTo} snap={false} className={style.messages}>
            {messages.map((message, index) => (
              <Message
                {...message}
                key={`message:${index}`}
                disabled={busy || index < messages.length - 1}
                onAction={handleAction}
              />
            ))}
            {busy && <Message auto busy />}
          </ScrollView>

          {online && (
            <>
              <Input disabled={busy} onValue={handleInput} />
              <InputRich onValue={handleAction} />
            </>
          )}
        </>,
      )}

      <ButtonAvatar message={messages[0]} visible={visible} onPress={() => setVisible(true)} />
    </>
  ) : null;
};

Chat.displayName = 'Mirai:Core:Chat';

Chat.propTypes = {
  name: PropTypes.string,
  skeleton: PropTypes.bool,
};

export { Chat };
