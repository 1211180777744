.tooltip {
  max-width: calc(var(--mirai-ui-breakpoint-S) * 0.8);
  gap: var(--mirai-ui-space-XS);
}

.tooltip .included {
  gap: var(--mirai-ui-space-XXS);
}

.tooltip .included ui-text {
  color: var(--mirai-ui-base);
}

.tooltip .row {
  align-items: flex-start;
}

.rate > * {
  padding: 0 var(--mirai-ui-space-XXS);
}

.row {
  align-items: flex-start;
  gap: var(--mirai-ui-space-XXS);
}

.row.centered {
  align-items: center;
}

/* S */
@media only screen and (max-width: 480px) {
  .content {
    gap: calc(var(--mirai-ui-space-XXS) / 2);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .content {
    margin-top: var(--mirai-ui-space-XXS);
    gap: var(--mirai-ui-space-XXS);
  }
}
