import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getInputErrors, styles } from '../../helpers';
import { Primitive } from '../Primitive';
import { parseValue } from './helpers';
import style from './Input.module.css';

const Input = ({ autoResize, disabled, multiLine, type = 'text', onChange, onEnter, onError, onLeave, ...others }) => {
  const ref = useRef(null);

  const [height, setHeight] = useState();

  useEffect(() => {
    const errors = getInputErrors({ ...others, type });
    if (errors && onError) onError(errors);

    if (autoResize && multiLine) {
      setHeight(ref.current.clientHeight);
      resize(others.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (next = '', event) => {
    const { max, min } = others;
    if (type === 'number' && isNaN(next)) return;
    const value = parseValue(next, type, others);

    onError && onError(getInputErrors({ ...others, type, value }));
    onChange &&
      onChange(
        value !== undefined
          ? type === 'number' && (max || min)
            ? Math.min(Math.max(value, min ? min : value), max ? max : value)
            : value
          : '',
        event,
      );
    if (autoResize && multiLine) resize(value);
  };

  const resize = (value) => {
    if (!ref?.current) return;
    ref.current.style.height = `${value ? ref.current.scrollHeight : height}px`;
  };

  return React.createElement(Primitive, {
    ...others,
    disabled,
    ref: autoResize ? ref : undefined,
    tag: multiLine ? 'textarea' : 'input',
    test: undefined,
    type: !multiLine ? type : undefined,
    value: others.value !== undefined ? others.value : '',
    ...(!disabled
      ? {
          onChange: (event) => handleChange(event.target.value, event),
          onFocus: onEnter,
          onBlur: onLeave,
        }
      : {}),
    className: styles(style.input, autoResize && multiLine && style.autoResize, others.className),
  });
};

Input.displayName = 'Primitive:Input';

Input.propTypes = {
  autoResize: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  multiLine: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onError: PropTypes.func,
  onLeave: PropTypes.func,
};

export { Input };
