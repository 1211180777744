import { CARD } from '../../../Payment/Payment.constants';

const CARDS = {
  amex: CARD.AMERICAN_EXPRESS,
  cup: CARD.UNIONPAY,
  diners: CARD.DINERS_CLUB,
  discover: CARD.DISCOVER,
  jcb: CARD.JCB,
  maestro: CARD.MAESTRO,
  mc: CARD.MASTERCARD,
  visa: CARD.VISA,
};

const URL = 'https://checkoutshopper-test.cdn.adyen.com/checkoutshopper/sdk/6.3.0/';

export { CARDS, URL };
