import { Event, useStore } from '@mirai/data-sources';
import { currencyFormat, useLocale } from '@mirai/locale';
import { ServiceBooking } from '@mirai/services';
import { Button, Notification, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { ERROR } from '../../../Core.constants';
import { ButtonPayment } from '../../__shared__';
import { BookingSummary } from '../../__shared__/BookingSummary';
import { EVENT } from '../../helpers';
import { L10N } from '../Checkout.l10n';
import * as style from '../Checkout.module.css';
import { getButtonPaymentKey } from '../helpers';

const Confirmation = ({
  busy: propBusy = false,
  dataSource = {},
  error = false,
  onError,
  onSubmit = () => {},
  onValid,
  ...others
}) => {
  const { isDesktop, isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      checkout,
      hotel = {},
      language,
      locale,
      payment,
      session = {},
      urlParams: { checkoutStep = '1', ...urlParams } = {},
      variant,
    } = {},
  } = useStore();
  const refContainer = useRef();

  const [busy, setBusy] = useState(false);
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    !isDesktop &&
      refContainer?.current &&
      Event.publish(EVENT.FOOTER_HEIGHT_CHANGE, { height: refContainer?.current.offsetHeight });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => setResponseError(), [checkout, payment]);

  const { currency, price: { prepayment = {} } = {} } = dataSource;
  const { currency: hotelCurrency, value } = prepayment[payment?.method] || {};

  const handleError = () => setBusy(false);

  const handlePress = () => {
    if (error) {
      Event.publish(EVENT.METRICS, { id: 'CHECKOUT:SUBMIT' });
      onError();
      return false;
    }
    setBusy(true);
  };

  const handleSubmit = async (value) => {
    if (error) return onError();
    Event.publish(EVENT.METRICS, { id: 'CHECKOUT:SUBMIT' });

    onValid();
    setResponseError();

    const response = await ServiceBooking.confirm({
      form: checkout,
      dataSource,
      language,
      payment: { ...payment, ...value },
      session: urlParams.applyClubDiscount === 'true' ? session : undefined,
      ...urlParams,
    }).catch((error) => {
      setBusy(false);
      setResponseError(error);

      Event.publish(EVENT.NOTIFICATION, {
        error: true,
        small: true,
        defaultMessage: translate(L10N.NOTIFICATION_ERROR[error?.code || ERROR.UNKNOWN]),
      });
    });

    return response;
  };

  const { price: { total = 0 } = {} } = dataSource;

  const CTA = () => {
    const { payment: { config = {} } = {} } = dataSource;
    const { idtokenprovider: id } = urlParams;
    const noCard = !dataSource.payment;

    return variant === 'A' || noCard || checkoutStep === '2' ? (
      <ButtonPayment
        {...{ ...dataSource?.payment, config: { ...config, publicKey: config.publicKey?.[payment?.method] } }}
        busy={busy}
        hotel={{ ...hotel, id }}
        large
        promise={handleSubmit}
        wide
        onError={handleError}
        onPress={handlePress}
        testId="checkout-button"
      >
        {translate(getButtonPaymentKey({ ...config, ...dataSource?.payment?.info, method: payment?.method }), {
          amount: currencyFormat({ currency: hotelCurrency, locale, value }),
        })}
      </ButtonPayment>
    ) : (
      <View row={total > 0 && !isDesktop} wide>
        {total > 0 && !isDesktop && (
          <View className={style.totalPrice}>
            <Text light small={!isMobile} tiny={isMobile}>
              {translate(L10N.LABEL_TOTAL)}
            </Text>
            <Text bold headline={!isMobile || total < 1000} level={isMobile ? 3 : 2}>
              {currencyFormat({ currency, locale, value: total })}
            </Text>
          </View>
        )}
        <Button
          busy={propBusy}
          large
          wide
          onPress={() => {
            if (error) handlePress();
            else onSubmit();
          }}
        >
          {translate(L10N.ACTION_FINAL_DETAILS)}
        </Button>
      </View>
    );
  };

  const content = () => (
    <>
      <BookingSummary dataSource={dataSource} />

      <View className={style.section}>
        {responseError && (
          <Notification error small wide>
            {translate(L10N.NOTIFICATION_ERROR[responseError?.code || ERROR.UNKNOWN])}
          </Notification>
        )}

        {CTA()}

        <Text light small wide className={style.footer}>
          {translate(L10N.NOTIFICATION_SSL_ENCRYPTION)}
        </Text>
      </View>
    </>
  );

  return (
    <View ref={refContainer} {...others} className={style.confirmation}>
      {isDesktop ? content() : CTA()}
    </View>
  );
};

Confirmation.displayName = 'Mirai:Core:Checkout:Confirmation';

Confirmation.propTypes = {
  busy: PropTypes.bool,
  dataSource: PropTypes.shape({}),
  error: PropTypes.bool,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onValid: PropTypes.func,
};

export { Confirmation };
