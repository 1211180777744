import { L10N as L10N_BOOKING_DATES } from '../BookingDates/BookingDates.l10n';

const { LABEL_MIN_NIGHTS, LABEL_MIN_NIGHTS_DEALS, LABEL_MIN_NIGHTS_REQUIRED, LABEL_NIGHTS } = L10N_BOOKING_DATES;

export const L10N = {
  ACTION_CLEAR: { id: 'finder.action.clear', defaultMessage: '　' },
  ACTION_NEXT: { id: 'finder.action.next' },
  ACTION_SEARCH: { id: 'common.action.search', defaultMessage: '　　　　' },

  LABEL_ACCOMMODATION_TYPE: { id: 'common.label.accommodation_type' },
  LABEL_INFO_PRICES: { id: 'finder.label.info_prices', defaultMessage: '　' },
  LABEL_MIN_NIGHTS,
  LABEL_MIN_NIGHTS_DEALS,
  LABEL_MIN_NIGHTS_REQUIRED,
  LABEL_NIGHTS,
  LABEL_SELECT_DATES_PERSONS: { id: 'finder.label.select_dates_persons', defaultMessage: '　　　　　　' },
  LABEL_WHEN: { id: 'finder.label.when', defaultMessage: '　　　　' },
};
