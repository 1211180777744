.skeleton {
  border-radius: 4px;
}

.content {
  background-color: var(--mirai-ui-content);
}

.background {
  background-color: var(--mirai-ui-content-background);
}

.base {
  background-color: var(--mirai-ui-base);
}

.border {
  background-color: var(--mirai-ui-content-border);
}

.light {
  background-color: var(--mirai-ui-content-light);
}

.dark {
  background-color: var(--mirai-ui-content-dark);
}

.skeleton:not(.text) {
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.skeleton.text {
  color: transparent !important;
  width: fit-content;
}
