.card {
  align-items: center;
  background-color: var(--mirai-chat-message-background);
  border-top: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  display: flex;
  flex-direction: row;
  gap: calc(var(--mirai-chat-space) / 2);
  width: auto;
}

.card.offset {
  padding: var(--mirai-chat-message-offset);
}

.card.pressable:not(.checked):not(.disabled):hover {
  background-color: var(--mirai-chat-text-color);
  cursor: pointer;
}

.card [data-role='icon'],
.card [data-role='text'] {
  color: var(--mirai-chat-text-color);
}

.card.pressable:not(.checked):not(.disabled):hover [data-role='icon'],
.card.pressable:not(.checked):not(.disabled):hover [data-role='text'] {
  color: var(--mirai-chat-text-accent-color);
}

.card.disabled [data-role='icon'],
.card.disabled [data-role='text'] {
  color: var(--mirai-chat-text-color-light);
}

.card .text {
  font-weight: 600;
}

.card .text.button {
  text-align: center;
}
