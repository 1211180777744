import { useLocale } from '@mirai/locale';
import { Button, Icon, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './Chat.l10n';
import * as style from './Chat.module.css';
import { Avatar } from './components';
import { ICON } from '../helpers';

const Header = ({ online, onClose }) => {
  const { translate } = useLocale();

  return (
    <View row className={style.header}>
      <Avatar small />
      <View wide>
        <Text bold>{translate(L10N.LABEL_ASSISTANT)}</Text>
        <View row>
          <View className={styles(style.bullet, online ? style.online : style.offline)} />
          <Text small light>
            {translate(online ? L10N.LABEL_ONLINE : L10N.LABEL_OFFLINE)}
          </Text>
        </View>
      </View>

      <View row>
        <Button small squared tooltip={translate(L10N.ACTION_CLOSE)} transparent onPress={onClose}>
          <Icon value={ICON.CLOSE} />
        </Button>
      </View>
    </View>
  );
};

Header.displayName = 'Mirai:Core:Chat.Header';

Header.propTypes = {
  online: PropTypes.bool,
  onClose: PropTypes.func,
};

export { Header };
