import { FREE_CANCELLATION } from '../Rates.constants';

export const filterItem = (
  item = {},
  { board, freeCancellation = false, priceRange: { max: maxPrice, min: minPrice } = {} } = {},
) => {
  let { boards = [], cancellations = [], rates = [] } = item;

  if (freeCancellation) {
    const validate = (id = '') => id.toLowerCase().includes(FREE_CANCELLATION);

    cancellations = cancellations.filter(({ id } = {}) => validate(id));
    rates = rates.filter(({ cancellationId } = {}) => validate(cancellationId));
    boards = boards.filter(({ cancellationId } = {}) => validate(cancellationId));
  }

  if (board !== undefined || maxPrice || minPrice) {
    boards = boards.filter(
      ({ price, type } = {}) =>
        (board === undefined || type === board) && (!minPrice || price >= minPrice) && (!maxPrice || price <= maxPrice),
    );

    const cancellationIds = boards.map(({ cancellationId } = {}) => cancellationId);
    const rateIds = boards.map(({ rateId } = {}) => rateId);
    cancellations = cancellations.filter(({ id } = {}) => cancellationIds.includes(id));
    rates = rates.filter(({ id } = {}) => rateIds.includes(id));
  }

  return { ...item, boards, cancellations, rates };
};
